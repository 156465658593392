import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { GenCodeMasterComponent } from './gen-code-master.component';
import { genCodeRoutingModule } from './gen-code-routing.module';
import { GenCodeTypesComponent } from './gen-code-types/gen-code-types.component';
import {RippleModule} from "primeng/ripple";

@NgModule({
  declarations: [GenCodeMasterComponent, GenCodeTypesComponent],
    imports: [
        genCodeRoutingModule,
        DropdownModule,
        ButtonModule,
        ToastModule,
        TableModule,
        ToolbarModule,
        DialogModule,
        RadioButtonModule,
        ConfirmDialogModule,
        SelectButtonModule,
        ReactiveFormsModule,
        CommonModule,
        TranslateModule,
        RippleModule,
    ],
  exports: [GenCodeMasterComponent],
  providers: [],
})
export class GenCodeModule {}
