import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UploadDocumentComponent } from './upload-document.component';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '../../../../directives/directives.module';
import { DownloadFileModule } from '../../download-file/download-file.module';
import { PipeModule } from 'src/app/shared/pipes/pipe.module';

@NgModule({
  declarations: [UploadDocumentComponent],
  exports: [UploadDocumentComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    PipeModule,
    TranslateModule,
    DirectivesModule,
    DownloadFileModule,
  ],
})
export class UploadDocumentModule {}
