import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {ProjectDetails, ProjectDetailsDashboardComparer} from "../models/Projects.model";
import {createReducer, on} from "@ngrx/store";
import {DashboardActions} from "../dashboard-action-types";

export interface PPDashboardState extends EntityState<ProjectDetails> {
  isLoaded: boolean;
}

const adapter: EntityAdapter<ProjectDetails> = createEntityAdapter<ProjectDetails>({
  sortComparer: ProjectDetailsDashboardComparer,
});

const initialState: EntityState<ProjectDetails> & {isLoaded: boolean} = adapter.getInitialState({
  isLoaded: false
});

export const ppDashboardReducer = createReducer<PPDashboardState>(
  initialState,
  on(DashboardActions.loadAllProjectsSuccess, (state, action) => adapter.addMany(action.projects, {...state, isLoaded: true})),
  on(DashboardActions.addProject, (state, action) => adapter.addOne(action.project, state))
);
