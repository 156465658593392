import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { AppState } from '../app-state/app-reducer';
import { SharedState } from './shared.reducer';

const sharedFeatureSelector: MemoizedSelector<AppState, SharedState> =
  createFeatureSelector('shared');

export const selectActions = createSelector(sharedFeatureSelector, (state) => state.actions);

export const selectState = createSelector(
  sharedFeatureSelector,
  (sharedState) => ({ data: sharedState.states })
);

export const isStateLoaded = createSelector(
  sharedFeatureSelector,
  (state) => !!state.states.length
);

export const isDistrictLoaded = createSelector(
  sharedFeatureSelector,
  (state) => !!state.district.length
);

export const selectDistrict = createSelector(
  sharedFeatureSelector,
  (sharedState) => ({ data: sharedState.district })
);

export const selectAllProposalByProposalId = createSelector(
  sharedFeatureSelector,
  state => state.getCafDetailsByProposalIdNumber
);
