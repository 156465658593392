<div class="d-flex justify-content-center align-items-center" id="main">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12 text-center">
        <span class="display-1 d-block">404</span>
        <div class="mb-4 lead">
          Sorry, The page you are looking for was not found.
        </div>
        <a routerLink="/home" class="btn btn-link">&lt; &nbsp;Back to Home</a>
      </div>
    </div>
  </div>
</div>
