import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeLegacyComponent } from './home-legacy.component';
import { HomeLegacyRoutingModule } from './home-legacy-routing.module';
import { MaterialModule } from '../user-registration/user-registration/material.module';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { PipeModule } from 'src/app/shared/pipes/pipe.module';

@NgModule({
  declarations: [HomeLegacyComponent],
  imports: [
    CommonModule,
    HomeLegacyRoutingModule,
    MaterialModule,
    ButtonModule,
    ToastModule,
    TableModule,
    ToolbarModule,
    DialogModule,
    FileUploadModule,
    RadioButtonModule,
    ConfirmDialogModule,
    SelectButtonModule,
    ReactiveFormsModule,
    NgbCarouselModule,
    PipeModule,
  ],
  exports: [HomeLegacyComponent],
})
export class HomeLegacyModule {}
