import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RouterUtility {
  constructor() {}

  /**
   * Method for getting basepath
   * @param value - string router value.
   */
  getRoutePath(value: string) {
    let _value = value.split('/');
    return _value.slice(0, -1).join('/');
  }
}
