import {Directive, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appOnlyText]'
})
export class OnlyTextDirective {

  @Input('appOnlyText') onlyText!: boolean;

  constructor() {
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: any) {
    return (event.keyCode >= 65 && event.keyCode <= 90) || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 32 || event.keyCode == 37 || event.keyCode == 38 || event.keyCode == 39 || event.keyCode == 40 || event.keyCode == 46;
  }
}
