import { CanActivate, Router } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { MessageService } from '../services/message.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private router: Router
  ) {}

  canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate([`${environment.baseURLLogin}`]);
      this.messageService.showError(
        this.translateService.instant('login.must_logged_in')
      );
      return false;
    }
    return true;
  }
}
