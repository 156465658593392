import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GenCode } from 'src/app/models/master/gen-code.model';
import { GenCodeServiceService } from 'src/app/services/gen-code.service';

@Component({
  selector: 'app-gen-code-types',
  templateUrl: './gen-code-types.component.html',
  styleUrls: ['./gen-code-types.component.scss']
})
export class GenCodeTypesComponent implements OnInit {
  genCodeList: GenCode[] = [];
  parentList: String[] = [];
  genCodeForm: FormGroup = new FormGroup({});
  constructor(private service: GenCodeServiceService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.getDistinctGenCode();
    this.createForm();
  }

  getDistinctGenCode() {
    this.service.getDistinctGenCode().subscribe((res) => {
      this.parentList=res.data;
    });
  }

  getParent(list: GenCode[]) {
    for (let i of list) {
      let grp = i.parent_grp;

      if (!this.parentList.includes(grp)) {
        this.parentList.push(grp);
      }
    }
  }

  createForm() {
    this.genCodeForm = this.fb.group({
      id: [null, []],
      name: [null, []],
      abbr: [null, []],
      val: [null, []],
      parent_grp: [null, []],
      uuid: [null, []],
      created_on: [null, []],
      created_by: [null, []],
      updated_on: [null, []],
      updated_by: [null, []],
      is_active: [null,[]],
      is_deleted: [null,[]],
    });
  }

}
