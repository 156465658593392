import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import {PanServiceService} from '../../../services/user-registration/pan-service.service';
import {SharedServiceService} from "../../../services/shared-service.service";
import {MessageService} from "../../../services/message.service";

interface DialogData {
  panNumber: string;
  fullName: string;
  entType: string;
  panName: string;
  fathername: string;
  dob: string;
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
})
export class ModalComponent implements OnInit {
  statusCode: string = 'intial';
  panData: any;
  isDuplicate: string = 'intial';
  environment = environment;

  panNo: string = '';
  entityType: string = '';
  constructor(
    private service: PanServiceService,
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private sharedService: SharedServiceService,
    private messageService: MessageService,
  ) {}

  ngOnInit() {
    this.panNo = this.data.panNumber.toUpperCase();
    this.entityType = this.data.entType;

    this.getPanDetail(this.panNo);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getPanDetail(pan: string) {
    this.service.getPan([{pan: pan?.toUpperCase(), name: this.data.panName?.toUpperCase(), fathername: this.data.fathername?.toUpperCase() || '', dob: this.sharedService.getDisplayDateFormat(this.data.dob)}]).subscribe({
      next: (res) => {

        if (res?.status == 'FAILED') {
          this.messageService.showError(res?.message);
          return;
        } else if (res.message == 'Authentication Failure') {
          this.messageService.showError(res?.message);
          return;
        }

        this.checkDuplicate(pan);

        // this.statusCode = res.statusCode;
        this.statusCode = JSON.parse(res?.response)?.outputData[0]?.pan_status || null;
        this.panData = JSON.parse(res?.response)?.outputData[0] || null;
        this.data.fullName = res.fullName;
        this.data.panNumber = pan;
      },
      error: (data) => {

      },
    });
  }

  checkDuplicate(pan: string) {
    this.service
      .checkDuplicatePanKyc(pan.toUpperCase(), this.entityType)
      .subscribe({
        next: (res) => {
          let isdup: any = res.data;

          if (isdup.isFound) {
            this.messageService.showError('PAN is already registered with ' + isdup?.email);
            this.isDuplicate = 'duplicate';
          } else {
            this.isDuplicate = 'notDuplicate';
          }
        },
        error: () => {},
      });
  }
}
