import { Component, OnInit } from '@angular/core';
import { TRACK_YOUR_PROPOSAL_MENU } from 'src/app/shared/app-constant';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit {
  isMenuCollapsed: boolean = true;
  public trackYourProposalMenu : Array<any> = TRACK_YOUR_PROPOSAL_MENU
  constructor() {}

  ngOnInit(): void {}
}
