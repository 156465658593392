import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { MenuService } from 'src/app/services/menu-service';
import { MessageService } from 'src/app/services/message.service';
import { Observable } from 'rxjs';
import { ROLES } from '../../shared/app-constant';
import { Router } from '@angular/router';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/models/user/user.model';
import { UserService } from 'src/app/services/user.service';
import { ValidationUtility } from 'src/app/utils/validation.utility';
import { orderBy } from 'lodash';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
})
export class SidebarMenuComponent implements OnInit {
  user!: User;
  menues: any = [];
  legacyMenues: any = [];
  token!: any;
  email!: string;
  userRole$!: Observable<any>;
  devEnvironment: boolean = environment.dev;
  dispatch = false;
  animationState = 'out';
  constructor(
    private userService: UserService,
    private menuService: MenuService,
    private sessionStorageService: SessionStorageService,
    private messageService: MessageService,
    private translateService: TranslateService,
    public sharedService: SharedServiceService,
    private router: Router,
    private authService: AuthService,
    private validationUtil: ValidationUtility
  ) {

    this.userRole$ = this.userService.userRoleDetails$;
    this.userService.userLoginDetails$.subscribe(res => {
      this.user = res;
      this.sharedService.userSubject.next(res);
    })
  }

  ngOnInit(): void {
    this.token = this.authService.AUTH_TOKEN
      ? this.authService.AUTH_TOKEN.split(' ')[1]
      : '';
    this.email = this.authService.AUTH_EMAIL ? this.authService.AUTH_EMAIL : '';
    // this.getMyDetails();
    this.getMenuItems();
  }

  // getMyDetails() {
  //   // this.userService.getLoggedInUserAPI()


  // }

  getMenuItems() {
    this.userService.getLoggedInUserRoleName().subscribe({
      next: response =>  console.log()
    })
    setTimeout(() => {
      this.menuService.getMenuItems().subscribe((res) => {
        let data: any = res;
        data.map(async (ele: any) => {
          if (ele.queryParams && Object.keys(ele.queryParams).length) {
            if (await this.validationUtil.isRole(ROLES.EC_MS_SEAC)) {
              ele.queryParams['committee'] = 'SEAC';
            } else if (await this.validationUtil.isRole(ROLES.EC_MS_SEIAA)) {
              ele.queryParams['committee'] = 'SEIAA';
            }
          }
        });
        this.menues = orderBy(data, ['order'], ['asc']);
        this.legacyMenues = orderBy(
          data.filter((menu: any) => menu.legacy),
          ['order'],
          ['asc']
        );
      });
    }, 500);
  }

  navigate(menu: any) {
    let params: any = {};
    if (
      this.authService.ROLES?.includes(ROLES.MS) &&
      menu.label == 'Agenda Management'
    ) {
      params = { committee: 'EAC' };
    }

    this.router.navigate([menu.route], { queryParams: params });
  }

  onLogout() {
    this.userService.logout().subscribe((res) => { });
    this.sessionStorageService.removeAll();
    this.messageService.showSuccess(
      this.translateService.instant('registration.logout')
    );
    this.router.navigate(['/home']);
  }

  goToChangePass(event: any) {
    this.router.navigate(['/userRegistration/change-password']);
  }
}
