import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DocumentType } from '../models/okm/document_type.model';
import { OkmFileUploadResponse } from '../models/response/okm-upload-response.model';
import { API } from '../shared/app-constant';

@Injectable({
  providedIn: 'root',
})
export class OKMService {
  constructor(private http: HttpClient) {}

  getDocumentTypeByModuleID(
    moduleID: number,
    step?: number
  ): Observable<DocumentType[]> {
    let url = environment.baseURLKMLUpload + API.DOCUMENT_TYPE_OKM;
    let param: HttpParams = new HttpParams();
    param = param.append('Module Id', moduleID);
    if (step !== -1) {
      param = param.append('Step', step as number);
    }
    return this.http.get<DocumentType[]>(url, { params: param });
  }

  downloadDocument(
    uuid: string,
    version: string,
    docId: number,
    caf_id: string,
    refType: string
  ) {
    let url = environment.baseURLKMLUpload + API.DOWNLOAD_FILE;
    let param: HttpParams = new HttpParams();
    param = param.append('docTypemappingId', docId);
    param = param.append('refId', caf_id);
    param = param.append('refType', refType);
    param = param.append('uuid', uuid);
    param = param.append('version', version);
    return this.http.get(url, { params: param, responseType: 'blob' });
  }

  upload(
    ref_id: any,
    file: File,
    docName: string,
    docTypemappingId: number,
    refType: string
  ): Observable<OkmFileUploadResponse> {
    const formData = new FormData();
    formData.append('file', file);
    let url = environment.baseURLKMLUpload + API.UPLOAD_KML;
    let param = new HttpParams()
      .append('refId', ref_id)
      .append('docName', docName)
      .append('docTypemappingId', docTypemappingId)
      .append('refType', refType);
    return this.http.post<OkmFileUploadResponse>(url, formData, {
      params: param,
    });
  }
}
