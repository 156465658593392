import {Actions, createEffect, ofType} from "@ngrx/effects";
import {AuthActions} from "./auth-action-types";
import {exhaustMap} from "rxjs/operators";
import {AppState} from "../app-state/app-reducer";
import {Store} from "@ngrx/store";
import {UserService} from "../../services/user.service";
import {catchError, EMPTY, map, of} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable({providedIn: 'root'})
export class AuthEffects {

  constructor(private actions$: Actions, private userService: UserService, private store: Store<AppState>) {
  }

  loadUserDetails$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.loadUserDetails),
    exhaustMap(action => this.userService.getLoggedInUserAPI().pipe(
      map(user => this.store.dispatch(AuthActions.loadUserDetailsSuccess({user}))),
      catchError(() => {
        this.store.dispatch(AuthActions.loadUserDetailsSuccess({user: null}));
        return of(EMPTY);
      })
    ))
  ), {dispatch: false});

}
