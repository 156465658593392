import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-legacy',
  templateUrl: './home-legacy.component.html',
  styleUrls: ['./home-legacy.component.scss'],
})
export class HomeLegacyComponent {
  constructor() {
    window.location.href = environment.baseURLParivesh1;
  }
}
