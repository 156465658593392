<nav class="navbar navbar-expand-lg navbar-light bg-light shadow-sm">
  <div class="container-fluid">
    <div class="navbar-brand d-block d-lg-none fw-bold">
      <a class="text-warning" href="{{ 'baseURLLogin' | env }}"> Login</a
      >&nbsp;&nbsp;|&nbsp;&nbsp;<a routerLink="/userRegistration">Register</a>
    </div>
    <button
      class="navbar-toggler"
      type="button"
      (click)="isMenuCollapsed = !isMenuCollapsed"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
      <ul class="navbar-nav align-items-lg-center">
        <li class="nav-item active">
          <a class="nav-link" routerLink="/home">Home </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/cms/about"> About PARIVESH </a>
        </li>
        <li class="nav-item" ngbDropdown>
          <a
            class="nav-link"
            tabindex="0"
            ngbDropdownToggle
            id="navbarServices"
            role="button"
          >
            Services
          </a>
          <div
            ngbDropdownMenu
            aria-labelledby="navbarServices"
            class="dropdown-menu"
          >
            <a ngbDropdownItem routerLink="/services/environment-clearance"
              >Environmental Clearance</a
            >
            <div class="dropdown-divider"></div>
            <a ngbDropdownItem routerLink="/services/forest-clearance"
              >Forest Clearance</a
            >
            <div class="dropdown-divider"></div>
            <a ngbDropdownItem routerLink="/services/wildlife-clearance"
              >Wildlife Clearance</a
            >
            <div class="dropdown-divider"></div>
            <a ngbDropdownItem routerLink="/services/crz-clearance"
              >CRZ Clearance</a
            >
          </div>
        </li>
        <li
          class="nav-item"
          ngbDropdown
          display="dynamic"
          placement="bottom-end"
        >
          <a
            class="nav-link"
            tabindex="0"
            ngbDropdownToggle
            id="navbarDownload"
            role="button"
          >
            Downloads
          </a>
          <div
            ngbDropdownMenu
            aria-labelledby="navbarDownload"
            class="dropdown-menu"
          >
            <a ngbDropdownItem routerLink="/cms/act-rules-guidelines"
              >Acts, Rules &amp; Guidelines</a
            >
            <div class="dropdown-divider"></div>
            <a ngbDropdownItem routerLink="/cms/user-manuals">UserManuals</a>
          </div>
        </li>
        <!-- <li class="nav-item" ngbDropdown>
          <a
            class="nav-link"
            tabindex="0"
            ngbDropdownToggle
            id="navbarTrackYourProposal"
            role="button"
          >
            Track Your Proposal
          </a>
          <div
            ngbDropdownMenu
            aria-labelledby="navbarTrackYourProposal"
            class="dropdown-menu"
          >
            <ng-container *ngFor="let menu of trackYourProposalMenu">
              <a ngbDropdownItem href="{{ menu.url }}">{{ menu.name }}</a>
            </ng-container>
          </div>
        </li> -->
        <li class="nav-item">
          <a class="nav-link" routerLink="/trackYourProposal">Track Your Proposal</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/cms/user-manuals">Help</a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" href="#">
            <img
              loading="lazy"
              src="/assets/images/notification.svg"
              title="Notification"
              alt="Notification"
            />
          </a>
        </li> -->
      </ul>

      <ul class="navbar-nav ms-lg-auto align-items-lg-center">
        <li
          class="nav-item"
          ngbDropdown
          display="dynamic"
          placement="bottom-end"
        >
          <a
            class="login-btn"
            tabindex="0"
            ngbDropdownToggle
            id="navbarLogin"
            role="button"
          >
            Login
          </a>
          <div
            ngbDropdownMenu
            aria-labelledby="navbarLogin"
            class="dropdown-menu"
          >
            <a ngbDropdownItem href="{{ 'baseURLLogin' | env }}"
              >Login as Proponent</a
            >
            <div class="dropdown-divider"></div>
            <a ngbDropdownItem href="{{ 'baseURLAuthorityLogin' | env }}"
              >Login as Authority</a
            >
          </div>
        </li>
        <li class="nav-item">
          <a routerLink="/userRegistration" class="register-btn">Register</a>
        </li>
        <li class="nav-item">
          <a routerLink="/userRegistration/employee/register" class="btn btn-warning text-white">Employee Mapping Request</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
