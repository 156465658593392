import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, EMPTY, exhaustMap, map, of, tap } from 'rxjs';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { AppState } from '../app-state/app-reducer';
import { DeiaaSeiaaDashboardActions } from '../deiaa-seiaa-dashboard-state/deiaa-seiaa-dashboard-action-types';
import { DeiaaService } from 'src/app/services/department/deiaa.service';

@Injectable({ providedIn: 'root' })
export class DeiaaSeiaaDashboardEffects {
  constructor(
    private actions$: Actions,
    private _deiaa: DeiaaService,
    private store: Store<AppState>
  ) {}
  loadAllPending$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeiaaSeiaaDashboardActions.loadDeiaaSeiaaPending),
        tap((data) => console.log('pending',data)),
        exhaustMap((data) =>
          this._deiaa.getSeiaaProposalSubmitByDeiaaV2(data.stateId).pipe(
            map((states) =>
              this.store.dispatch(
                DeiaaSeiaaDashboardActions.deiaaSeiaaPendingSuccess({ pending: states?.data })
              )
            ),
            catchError(() => {
              this.store.dispatch(
                DeiaaSeiaaDashboardActions.deiaaSeiaaPendingSuccess({ pending: [] })
              );
              return of(EMPTY);
            })
          )
        )
      ),
    { dispatch: false }
  );

  loadAllValid$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeiaaSeiaaDashboardActions.loadDeiaaSeiaaValid),
        tap((data) => console.log('valid',data)),
        exhaustMap((data) =>
          this._deiaa.getSeiaaProjectCreationsV2(
            data.stateId,
            '',
            'Valid'
          ).pipe(
            map((states) =>
              this.store.dispatch(
                DeiaaSeiaaDashboardActions.deiaaSeiaaValidSuccess({ valid: states?.data })
              )
            ),
            catchError(() => {
              this.store.dispatch(
                DeiaaSeiaaDashboardActions.deiaaSeiaaValidSuccess({ valid: [] })
              );
              return of(EMPTY);
            })
          )
        )
      ),
    { dispatch: false }
  );

  loadAllInValid$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeiaaSeiaaDashboardActions.loadDeiaaSeiaaInvalid),
        tap((data) => console.log('invalid',data)),
        exhaustMap((data) =>
          this._deiaa.getSeiaaProjectCreationsV2(
            data.stateId,
            '',
            'In valid'
          ).pipe(
            map((states) =>
              this.store.dispatch(
                DeiaaSeiaaDashboardActions.deiaaSeiaaInvalidSuccess({ invalid: states?.data })
              )
            ),
            catchError(() => {
              this.store.dispatch(
                DeiaaSeiaaDashboardActions.deiaaSeiaaInvalidSuccess({ invalid: [] })
              );
              return of(EMPTY);
            })
          )
        )
      ),
    { dispatch: false }
  );
}
