import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionStorageService } from '../services/session-storage.service';
import {
  API,
  IS_NSWS_POPUP_OPENED,
  NSWS_REQUEST_ID,
  NSWS_SWS_ID,
  PROPONENT_APPLICATION_ID,
} from '../shared/app-constant';
import { SharedServiceService } from '../services/shared-service.service';
import { environment } from '../../environments/environment';

@Injectable()
export class UndertakingInterceptor implements HttpInterceptor {
  apiUrlsForUpdateApplication: string[] = [
    API.CRZ_SAVE_UNDERTAKING,
    API.CRZ_TRANSFER_SAVE_UNDERTAKING,
    API.SAVE_EC_FORM_Eight_UNDERTAKING,
    API.SAVE_EC_FORM_ELEVEN_UNDERTAKING,
    API.SAVE_EC_FORM_FIVE_UNDERTAKING,
    API.SAVE_EC_FORM_Nine_UNDERTAKING,
    API.SAVE_EC_FORM_SEVEN_UNDERTAKING,
    API.SAVE_EC_FORM_SIX_NEW_UNDERTAKING_DETAILS,
    API.SAVE_EC_FORM_Ten_UNDERTAKING,
    API.SAVE_EC_FORM_TWELVE_UNDERTAKING,
    API.SAVE_EC_FORM_TWO_UNDERTAKING,
    API.PART_C_SAVE_UNDERTAKING,
    API.SAVE_FC_FORM_C_UNDETAKING,
    API.SAVE_FC_FORM_B_UNDERTAKING,
    API.FC_FORM_E_SAVE_UNDERTAKING_DETAILS,
    API.SAVE_FORM_D_UNDERTAKING,
    API.SAVE_FC_UNDERTAKING,
    API.SAVE_WL_UNDERTAKING,
    API.SAVE_EC_OTHER_DETAILS,
    API.SAVE_EC_PROJECT_DETAILS,
  ];

  baseUrl: any = environment.baseURL;

  constructor(
    private sessionStorageService: SessionStorageService,
    private sharedService: SharedServiceService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      this.sessionStorageService.get(IS_NSWS_POPUP_OPENED) == 'true' &&
      this.apiUrlsForUpdateApplication.some(
        (item) => this.baseUrl + item == request?.url
      )
    ) {
      let req_id = this.sessionStorageService.get(NSWS_REQUEST_ID);
      let application_id = this.sessionStorageService.get(
        PROPONENT_APPLICATION_ID
      );
      let nswsId = this.sessionStorageService.get(NSWS_SWS_ID);
      this.sharedService.updateSws(req_id, application_id, nswsId).subscribe({
        next: () => {
          this.sessionStorageService.remove(NSWS_REQUEST_ID);
          this.sessionStorageService.remove(PROPONENT_APPLICATION_ID);
          this.sessionStorageService.remove(NSWS_SWS_ID);
          this.sessionStorageService.remove(IS_NSWS_POPUP_OPENED);
        },
      });
    } else return next.handle(request);

    return next.handle(request);
  }
}
