import { DashboardState } from "./reducers/dashboard.reducer";
import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { createFeatureSelector, createSelector, MemoizedSelector, Store } from "@ngrx/store";
import { AppState } from "../app-state/app-reducer";
import { PPDashboardState } from "./reducers/pp-dashboard.reducer";
import { DashboardActions } from "./dashboard-action-types";
import { JsDashboardState } from "./reducers/js-dashboard.reducer";

export const adapter: EntityAdapter<DashboardState> = createEntityAdapter<DashboardState>();

export const selectDepartmentDashboardState: MemoizedSelector<AppState, DashboardState> = createFeatureSelector('departmentDashboardProposals');

export const selectProposalId = (id: string) => createSelector(
  selectDepartmentDashboardState,
  action => ({ data: [action.entities[id]] })
);

export const isDashboardProposalsLoaded = createSelector(
  selectDepartmentDashboardState,
  state => state.isLoaded,
);


// PP Dashboard Selector

export const ppAdapter: EntityAdapter<PPDashboardState> = createEntityAdapter<PPDashboardState>();

export const selectPpDashboardProjectsState: MemoizedSelector<AppState, PPDashboardState> = createFeatureSelector('ppDashboard');

export const selectJsDashboardState: MemoizedSelector<AppState, JsDashboardState> = createFeatureSelector('jsDashboard');


export const selectPpDashboardProjects = (store: Store<AppState>, page: number, size: number) => createSelector(
  selectPpDashboardProjectsState,
  state => {
    if (state.isLoaded) return {
      data: {
        content: Object.values(state.entities)?.reverse()
      }
    }
    else return store.dispatch(DashboardActions.loadAllProjects({ page, size }));
  }
);

export const selectSectors = (store: Store<AppState>) => createSelector(
  selectJsDashboardState,
  state => {
    if (state.sectors.length) return { data: state.sectors }
    else return store.dispatch(DashboardActions.loadAllSectors())
  }

)
