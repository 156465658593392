import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GenCode } from 'src/app/models/master/gen-code.model';
import { GenCodeServiceService } from 'src/app/services/gen-code.service';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-gen-code-master',
  templateUrl: './gen-code-master.component.html',
  styleUrls: ['./gen-code-master.component.scss'],
})
export class GenCodeMasterComponent implements OnInit {
  genCodeList: GenCode[] = [];
  switchValue: string = 'Intial';
  parentGroup: string = 'Intial';
  parentList: string[] = [];
  genCodeListByParent: GenCode[] = [];
  inputSelect: string = '';
  productDialog: boolean = false;
  registrationType: string = '';
  stateOptions: any[] = [];
  product: any;
  image!: File;
  validationCheck: boolean = false;

  edit: boolean = false;

  genCodeForm: FormGroup = new FormGroup({});
  false: any;
  constructor(
    private service: GenCodeServiceService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private genCodeService: GenCodeServiceService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.registrationType = params['type'];
    });
  }

  ngOnInit(): void {
    this.switchValue = 'Intial';
    this.stateOptions = [
      { label: 'Active', value: true },
      { label: 'Inactive', value: false },
    ];
    this.getGenCode();
    this.createForm();
  }
  getGenCode() {
    this.genCodeService
      .getAllGenderGenCode({
        type: this.registrationType,
      })
      .subscribe((res) => {
        this.genCodeList = res;
      });
  }

  createForm() {
    this.genCodeForm = this.fb.group({
      id: [0, []],
      name: [null, [Validators.required]],
      abbr: [null, [Validators.required]],
      val: [null, [Validators.required]],
      parent_grp: [null, [Validators.required]],
      is_active: [null, [Validators.required]],
    });
  }

  editGenCodeSection(product: any) {
    this.validationCheck = false;
    this.edit = true;
    this.genCodeForm.reset();
    this.product = { ...product };
    this.genCodeForm.patchValue({
      id: this.product.id,
      name: this.product.name,
      abbr: this.product.abbr,
      val: this.product.val,
      parent_grp: this.product.parent_grp,
      is_active: this.product.is_active,
    });
    this.productDialog = true;
  }

  openNew() {
    this.validationCheck = false;
    this.genCodeForm.reset();
    this.edit = false;
    this.genCodeForm.patchValue({
      parent_grp: this.registrationType,
    });
    this.productDialog = true;
  }

  hideDialog() {
    this.edit = false;
    this.productDialog = false;
  }

  getParent(list: GenCode[]) {
    for (let i of list) {
      let grp = i.parent_grp;

      if (!this.parentList.includes(grp)) {
        this.parentList.push(grp);
      }
    }
  }

  saveForm() {
    if (this.genCodeForm.invalid) {
      this.validationCheck = true;
      this.messageService.showError('Please Fill All Mandatory Fields');
      return false;
    }
    this.genCodeService.createGenCode(this.genCodeForm.value).subscribe(
      (res) => {
        this.messageService.showSuccess('Successfully Saved');
        setTimeout(() => {
          this.getGenCode();
        }, 500);
      },
      (err) => {
        this.messageService.showError('Some Error Occured');
      }
    );
    this.productDialog = false;

    return true;
  }
  onSubmit() {
    let body = this.genCodeList.filter((value) => {
      return (
        value.parent_grp == this.genCodeForm.get('parent_grp')?.value &&
        value.abbr == this.genCodeForm.get('abbr')?.value
      );
    });

    this.service.deleteGenCode(body[0]).subscribe((res) => {
    });
    this.ngOnInit();
  }
  onSubmitCreate() {
    let body = this.genCodeForm.value;

    this.service.createGenCode(body).subscribe((res) => {

    });
    // this.ngOnInit()
  }
  onSubmitUpdate() {
    let body = this.genCodeList.filter((value) => {
      return (
        value.parent_grp == this.genCodeForm.get('parent_grp')?.value &&
        value.abbr == this.genCodeForm.get('abbr')?.value
      );
    });
    this.service.updateGenCode(body[0]).subscribe((res) => {
    });
    this.ngOnInit();
  }
}
