import {Component, EventEmitter, Input, OnInit, Output,} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {ValidationUtility} from '../../../../utils/validation.utility';
import {SharedServiceService} from '../../../../services/shared-service.service';
import {MessageService} from '../../../../services/message.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  @Input() appAutoFocus = false;
  @Input() formGroup!: FormGroup;
  @Input() control: any;
  @Input() type: string = 'text';
  @Input() id = '';
  @Input() name: string = this.id || '';
  @Input() placeholder = '';
  @Input() label = '';
  @Input() errorMessage!: string | boolean;
  @Input() extraClass: string | string[] = '';
  @Input() maxLength = 255;
  @Input() max: any = '';
  @Input() min: any = '';
  @Input() step = '';
  @Input() minLength = 0;
  @Input() readonly: boolean = false;
  @Input() isRequiredAddRow: boolean = false;
  @Input() isHideRequiredSign: boolean = false;
  @Input() checked: boolean = false;
  @Input() disabled: boolean = false;
  @Input() class = '';
  @Input() tooltip = '';
  @Input() isAmountInWords: boolean = false;
  @Input() isAmountInCrore: boolean = false;
  @Input() isAmountInLakhs: boolean = false;
  @Input() isLakhsWord: boolean = false;
  @Input() isCroreWord: boolean = false;
  @Input() isAppendZero: boolean = false;
  @Output() onBlur: EventEmitter<boolean> = new EventEmitter<boolean>();
  objectFn = Object;

  @Input() showPreview: any = false;
  @Input() value: any = '';
  @Input() isButtonDisable: boolean = false;

  @Input() isSearchAllowed!: boolean;
  @Input() isSmallSearchButton!: boolean;
  @Input() isRemoveW100!: boolean;
  @Output() proposalData: EventEmitter<any> = new EventEmitter<any>();

  @Input() searchBy!: string;

  constructor(
    public validator: ValidationUtility,
    private sharedService: SharedServiceService,
    private msgService: MessageService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.showPreview
      ? localStorage.setItem('isPreviewMode', 'true')
      : localStorage.removeItem('isPreviewMode');
  }

  blur() {
    this.onBlur.emit(true);
  }

  keyDownEvent(event: any, type: string) {
    if (type == 'date' || type == 'month') {
      event.preventDefault();
    }
  }

  appendZero() {
    if (this.isAppendZero && this.control.value) {
      let afterDecimalValue = this.control.value.includes('.')
        ? this.control.value.split('.')[1]
        : undefined;
      if (!this.control.value.includes('.')) {
        this.formGroup.controls[this.id].setValue(this.control.value + '.00');
      }

      if (afterDecimalValue == '') {
        this.formGroup.controls[this.id].setValue(this.control.value + '00');
      } else if (afterDecimalValue && afterDecimalValue.length == 1) {
        this.formGroup.controls[this.id].setValue(this.control.value + '0');
      }
    }
  }

  onChangeCheckbox(event: any, id: string) {
    let checkbox: any = document.getElementById(id);
    let remarks: any = document.getElementById('remarks_' + this.id);

    if (checkbox?.checked) remarks.classList.remove('d-none');
    else remarks.classList.add('d-none');
  }

  get isRequired(): boolean {
    const validator = this.control.validator
      ? this.control.validator({} as AbstractControl)
      : '';
    return !!(validator && validator.required);
  }

  searchProposal() {
    this.sharedService
      .getCafDetailsByProposalNo(this.control?.value, this.searchBy)
      .subscribe({
        next: (response) => {
          this.proposalData.emit(response.data);
          if (!response.data) this.noDataMessage();
        },
        error: () => {
          this.proposalData.emit(null);
          this.noDataMessage();
        },
      });
  }

  noDataMessage() {
    this.msgService.showError(this.translateService.instant('generic.no_data'));
  }
}
