import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {environment} from 'src/environments/environment';
import {API} from '../shared/app-constant';
import {AppState} from '../state/app-state/app-reducer';
import {select, Store} from "@ngrx/store";
import {selectRoleData} from "../state/auth-state/auth.selectors";
import {tap} from "rxjs/operators";
import {AuthActions} from "../state/auth-state/auth-action-types";

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  roleData: any;

  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store.pipe(select(selectRoleData)).subscribe({
      next: response => this.roleData = response
    });
  }

  getByAbbr(abbr: string): Observable<any> {
    let url = `${environment.baseURLMis}${API.GET_ROLE_BY_ABBR}/${abbr}`;
    return this.http.get<any>(url);
  }

  list(): Observable<any> {
    let url = `${environment.baseURLProcessEngine}${API.GET_ROLE}`;
    return this.http.post<any>(url, {});
  }

  getRolesByUserId(userId: string): Observable<any> {
    if (this.roleData) return of({data: this.roleData});
    const params = new HttpParams().append('user_id', userId);
    let url = `${environment.baseURLProcessEngine}${API.GET_ROLE_BY_USER_ID}`;
    return this.http.post<any>(url, {}, {params}).pipe(
      tap(response => this.store.dispatch(AuthActions.loadRoleByUser({roleData: response?.data})))
    );
  }
}
