import {NgModule} from '@angular/core';
import {AutofocusDirective} from './autofocus.directive';
import {BlockCopyPasteDirective} from './block-copy-paste.directive';
import {DecimalNumberDirective} from './decimal-number.directive';
import {DragNDropDirective} from './drag-ndrop.directive';
import {OnlyAlphabetAndSpaceDirective} from './only-alphabet-and-space.directive';
import {OnlynumberDirective} from './onlynumber.directive';
import {MaxLengthDirective} from './max-length.directive';
import {OnlyTextDirective} from './only-text.directive';
import {OnlyTextNumberDirective} from './only-text-number.directive';

import {AfterDecimalLengthDirective} from './after-decimal-length.directive';
import {AplhaNumericDirective} from './aplhaNumeric.directive';
import {YyMMDirective} from "./yy-mm.directive";
import { NumberSpecialCharacterDirective } from './number-special-character.directive';
import { YyyyYyyyDirective } from './yyyy-yyyy.directive';
import { MaxLengthNumberDirective } from './max-length-number.directive';
import { InvalidControlScrollDirective } from './invalid-control-scroll.directive';




@NgModule({
  declarations: [
    AutofocusDirective,
    BlockCopyPasteDirective,
    OnlynumberDirective,
    DecimalNumberDirective,
    OnlyAlphabetAndSpaceDirective,
    DragNDropDirective,
    MaxLengthDirective,
    OnlyTextDirective,
    AplhaNumericDirective,
    OnlyTextNumberDirective,
    AfterDecimalLengthDirective,
    YyMMDirective,
    NumberSpecialCharacterDirective,
    YyyyYyyyDirective,
    MaxLengthNumberDirective,
    InvalidControlScrollDirective,

  ],
  exports: [
    AutofocusDirective,
    BlockCopyPasteDirective,
    OnlynumberDirective,
    DecimalNumberDirective,
    OnlyAlphabetAndSpaceDirective,
    DragNDropDirective,
    MaxLengthDirective,
    OnlyTextDirective,
    OnlyTextNumberDirective,
    AplhaNumericDirective,
    AfterDecimalLengthDirective,
    YyMMDirective,
    NumberSpecialCharacterDirective,
    YyyyYyyyDirective,
    MaxLengthNumberDirective
  ],
})
export class DirectivesModule {
}
