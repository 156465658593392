import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit{

@Input() title!: string;
@Input() message!: string;
@Input() btnOkText!: string;
@Input() btnCancelText!: string;
@Input() htmlElement: any;

constructor(private activeModal: NgbActiveModal) { }

ngOnInit() { }

public decline() {
  this.activeModal.close(false);
}

public accept() {
  this.activeModal.close(true);
}

public dismiss() {
  this.activeModal.dismiss();
}
}
