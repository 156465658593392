import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ClearenceService {
  constructor(private http: HttpClient) {}

  getECData(): Observable<any> {
    if (environment.production) {
      return this.http.get('assets/json/menues/prod.json');
    } else {
      return this.http.get('assets/json/services/ec/dev.json');
    }
  }

  download(un: any) {
    let param: HttpParams = new HttpParams().append('doc', un);
    var url = environment.baseURLKMLUpload + '/okm/downloadReferenceDocument';
    return this.http.get(url, { params: param, responseType: 'blob' });
  }

  downloadDocumentByPath(path: any) {
    let param: HttpParams = new HttpParams().append('doc', path);
    var url = environment.baseURLKMLUpload + '/okm/downloadReferenceDocument';
    return this.http.get(url, { params: param, responseType: 'blob' });
  }
}
