<div
  class="container-fluid align-item-center pt-4"
  *ngIf="statusCode == 'intial'"
>
  <div class="row">
    <div class="col-md-8 offset-md-2 text-center pt-10">
      <h3>Verifying PAN Details..</h3>
    </div>
  </div>
</div>

<div
  class="container-fluid align-item-center pt-4"
  *ngIf="statusCode != 'intial'"
>
  <div class="row" *ngIf="isDuplicate == 'intial'">
    <div class="col-md-8 offset-md-2 text-center pt-10">
      <h3>Verifying PAN Details ...</h3>
    </div>
  </div>
  <div *ngIf="isDuplicate == 'duplicate'" class="pt-4">
    <h2 mat-dialog-title>Details</h2>
    <div mat-dialog-content>
      <div class="formFooter">
        <div class="col-md-8 offset-1">
        <strong>Details of PAN No. {{ data.panNumber }}</strong>
        </div>
      </div>
      <div class="col-md-12 text-center">
        User already exists, you are requested to LOGIN .
      </div>
    </div>
    <div mat-dialog-actions class="row offset-6" align="end">
      <div class="col-md-6">
        <button mat-button (click)="onNoClick()" class="text-success">
          Edit PAN No.
        </button>
      </div>
      <div class="col-md-6">
       <a  href="{{environment.baseURLLogin}}"> <button mat-button class="btn btn-success text-white">
        LOGIN
        </button></a>
      </div>
    </div>
  </div>
  <div *ngIf="isDuplicate == 'notDuplicate'" class="pt-4">
    <div style="font-family: 'Lato'">
      <h1
        mat-dialog-title
        class="text-center"
        style="font-family: 'Lato'; font-size: 38px; font-weight: 700"
      >
        PAN Details
      </h1>
      <div mat-dialog-content *ngIf="statusCode == 'E' && panData.name == 'Y' && panData.dob == 'Y'" >
        <div class="formFooter">
          <div class="row">
            <div class="col-sm-6 text-start heading">
              <span>Details of PAN No:</span>
            </div>
            <div class="col-sm-6 text-center">
              <div class="headingText">
                <span class="pan">{{ data.panNumber }}</span>
              </div>
            </div>
            <div class="col-sm-12 text-center heading alert alert-success mb-0 w-100">
              <h2 class="mb-0 fw-bold">PAN Verified</h2>
            </div>
<!--            <div class="col-sm-6 text-start headingText">-->
<!--              <span style="padding: 7px">{{ data.fullName | uppercase }}</span>-->
<!--            </div>-->
          </div>
        </div>
        <div style="padding: 13px 39px; text-align: start">
          Do you want to Register as <b>{{ data.entType }}</b> on portal ?
        </div>
      </div>
      <div mat-dialog-actions align="end">
        <button
          style="font-family: 'Lato'"
          mat-button
          (click)="onNoClick()"
          class="text-success"
        >
          Edit PAN Details
        </button>
        <button
          mat-button
          [mat-dialog-close]="data"
          cdkFocusInitial
          class="btn-success btn-continue"
          *ngIf="statusCode == 'E' && panData.name == 'Y' && panData.dob == 'Y'"
        >
          Continue
        </button>
      </div>
    </div>

    <div *ngIf="statusCode == 'N'">
      <h2 mat-dialog-title>Details</h2>
      <div mat-dialog-content class="d-flex align-items-md-center">
        <div class="col-md-10 text-center pt-10 errMsg alignc alert alert-danger mb-0">
          <h2>PAN Details Doesn't Exist .</h2>
        </div>
      </div>
      <div mat-dialog-actions class="row offset-4">
        <div class="col-md-4">
          <button mat-button (click)="onNoClick()">Back</button>
        </div>
      </div>
    </div>
    <div *ngIf="statusCode == 'F'">
      <h2 mat-dialog-title>Details</h2>
      <div mat-dialog-content class="d-flex align-items-md-center">
        <div class="col-md-10 text-center pt-10 errMsg alignc alert alert-danger mb-0">
          <h2>PAN is Fake .</h2>
        </div>
      </div>
      <div mat-dialog-actions class="row offset-4">
        <div class="col-md-4">
          <button mat-button (click)="onNoClick()">Back</button>
        </div>
      </div>
    </div>
    <div *ngIf="statusCode == null">
      <h2 mat-dialog-title>Details</h2>
      <div mat-dialog-content>
        <div class="formFooter">
          <div class="col-md-12"><h3>Internal Server Error ...</h3></div>
        </div>
      </div>
      <div mat-dialog-actions class="row offset-4">
        <div class="col-md-4">
          <button mat-button (click)="onNoClick()" class="text-success">
            Back
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="statusCode == 'X'">
      <h2 mat-dialog-title>Details</h2>
      <div mat-dialog-content class="d-flex align-items-md-center">
        <div class="col-md-10 text-center pt-10 errMsg alignc alert alert-danger mb-0">
          <h2>PAN is Deactivated?</h2>
        </div>
      </div>
      <div mat-dialog-actions class="row offset-4">
        <div class="col-md-4">
          <button mat-button (click)="onNoClick()">Back</button>
        </div>
      </div>
    </div>
    <div *ngIf="statusCode == 'D'">
      <h2 mat-dialog-title>Details</h2>
      <div mat-dialog-content class="d-flex align-items-md-center">
        <div class="col-md-10 text-center pt-10 errMsg alignc alert alert-danger mb-0">
          <h2>PAN Details Deleted.</h2>
        </div>
      </div>
      <div mat-dialog-actions class="row offset-4">
        <div class="col-md-4">
          <button mat-button (click)="onNoClick()">Back</button>
        </div>
      </div>
    </div>
    <div *ngIf="statusCode == 'EA'">
      <h2 mat-dialog-title>Details</h2>
      <div mat-dialog-content class="d-flex align-items-md-center">
        <div class="col-md-10 text-center pt-10 errMsg alignc alert alert-danger mb-0">
          <h2>
            Existing and Valid but event marked as “Amalgamation” in ITD
            database
          </h2>
        </div>
      </div>
      <div mat-dialog-actions class="row offset-4">
        <div class="col-md-4">
          <button mat-button (click)="onNoClick()">Back</button>
        </div>
      </div>
    </div>
    <div *ngIf="statusCode == 'EC'">
      <h2 mat-dialog-title>Details</h2>
      <div mat-dialog-content class="d-flex align-items-md-center">
        <div class="col-md-10 text-center pt-10 errMsg alignc alert alert-danger mb-0">
          <h2>
            Existing and Valid but event marked as “Acquisition” in ITD database
          </h2>
        </div>
      </div>
      <div mat-dialog-actions class="row offset-4">
        <div class="col-md-4">
          <button mat-button (click)="onNoClick()">Back</button>
        </div>
      </div>
    </div>
    <div *ngIf="statusCode == 'ED'">
      <h2 mat-dialog-title>Details</h2>
      <div mat-dialog-content class="d-flex align-items-md-center">
        <div class="col-md-10 text-center pt-10 errMsg alignc alert alert-danger mb-0">
          <h2>
            Existing and Valid but event marked as “Death” in ITD database
          </h2>
        </div>
      </div>
      <div mat-dialog-actions class="row offset-4">
        <div class="col-md-4">
          <button mat-button (click)="onNoClick()">Back</button>
        </div>
      </div>
    </div>
    <div *ngIf="statusCode == 'EI'">
      <h2 mat-dialog-title>Details</h2>
      <div mat-dialog-content class="d-flex align-items-md-center">
        <div class="col-md-10 text-center pt-10 errMsg alignc alert alert-danger mb-0">
          <h2>
            Existing and Valid but event marked as “Dissolution” in ITD database
          </h2>
        </div>
      </div>
      <div mat-dialog-actions class="row offset-4">
        <div class="col-md-4">
          <button mat-button (click)="onNoClick()">Back</button>
        </div>
      </div>
    </div>
    <div *ngIf="statusCode == 'EL'">
      <h2 mat-dialog-title>Details</h2>
      <div mat-dialog-content class="d-flex align-items-md-center">
        <div class="col-md-10 text-center pt-10 errMsg alignc alert alert-danger mb-0">
          <h2>
            Existing and Valid but event marked as “Liquidated” in ITD database
          </h2>
        </div>
      </div>
      <div mat-dialog-actions class="row offset-4">
        <div class="col-md-4">
          <button mat-button (click)="onNoClick()">Back</button>
        </div>
      </div>
    </div>
    <div *ngIf="statusCode == 'EM'">
      <h2 mat-dialog-title>Details</h2>
      <div mat-dialog-content class="d-flex align-items-md-center">
        <div class="col-md-10 text-center pt-10 errMsg alignc alert alert-danger mb-0">
          <h2>
            Existing and Valid but event marked as “Merger” in ITD database
          </h2>
        </div>
      </div>
      <div mat-dialog-actions class="row offset-4">
        <div class="col-md-4">
          <button mat-button (click)="onNoClick()">Back</button>
        </div>
      </div>
    </div>
    <div *ngIf="statusCode == 'EP'">
      <h2 mat-dialog-title>Details</h2>
      <div mat-dialog-content class="d-flex align-items-md-center">
        <div class="col-md-10 text-center pt-10 errMsg alignc alert alert-danger mb-0">
          <h2>
            Existing and Valid but event marked as “Partition” in ITD database
          </h2>
        </div>
      </div>
      <div mat-dialog-actions class="row offset-4">
        <div class="col-md-4">
          <button mat-button (click)="onNoClick()">Back</button>
        </div>
      </div>
    </div>
    <div *ngIf="statusCode == 'ES'">
      <h2 mat-dialog-title>Details</h2>
      <div mat-dialog-content class="d-flex align-items-md-center">
        <div class="col-md-10 text-center pt-10 errMsg alignc alert alert-danger mb-0">
          <h2>
            Existing and Valid but event marked as “Split” in ITD database
          </h2>
        </div>
      </div>
      <div mat-dialog-actions class="row offset-4">
        <div class="col-md-4">
          <button mat-button (click)="onNoClick()">Back</button>
        </div>
      </div>
    </div>
    <div *ngIf="statusCode == 'EU'">
      <h2 mat-dialog-title>Details</h2>
      <div mat-dialog-content class="d-flex align-items-md-center">
        <div class="col-md-10 text-center pt-10 errMsg alignc alert alert-danger mb-0">
          <h2>
            Existing and Valid but event marked as “Under Liquidation” in ITD
            database
          </h2>
        </div>
      </div>
      <div mat-dialog-actions class="row offset-4">
        <div class="col-md-4">
          <button mat-button (click)="onNoClick()">Back</button>
        </div>
      </div>
    </div>
    <div *ngIf="panData?.seedingStatus == 'I'">
      <h2 mat-dialog-title>Details</h2>
      <div mat-dialog-content class="d-flex align-items-md-center">
        <div class="col-md-10 text-center pt-10 errMsg alignc alert alert-danger mb-0">
          <h2>Inoperative</h2>
        </div>
      </div>
      <div mat-dialog-actions class="row offset-4">
        <div class="col-md-4">
          <button mat-button (click)="onNoClick()">Back</button>
        </div>
      </div>
    </div>
    <div *ngIf="panData?.seedingStatus == 'R'">
      <h2 mat-dialog-title>Details</h2>
      <div mat-dialog-content class="d-flex align-items-md-center">
        <div class="col-md-10 text-center pt-10 errMsg alignc alert alert-danger mb-0">
          <h2>Inoperative PAN</h2>
        </div>
      </div>
      <div mat-dialog-actions class="row offset-4">
        <div class="col-md-4">
          <button mat-button (click)="onNoClick()">Back</button>
        </div>
      </div>
    </div>

    <h3 *ngIf="panData?.name == 'N' || panData?.dob == 'N'">Details</h3>
    <div *ngIf="panData?.dob == 'N'" class="mb-3">
      <div mat-dialog-content class="d-flex align-items-md-center">
        <div class="col-md-12 text-center errMsg alignc alert alert-danger mb-0">
          <h4 class="mb-0">DOB does not match with registered PAN Details</h4>
        </div>
      </div>
    </div>

    <div *ngIf="panData?.name == 'N'">
      <div mat-dialog-content class="d-flex align-items-md-center">
        <div class="col-md-12 text-center errMsg alignc alert alert-danger mb-0">
          <h4 class="mb-0">Name does not match with registered PAN Details</h4>
        </div>
      </div>
    </div>

    <div *ngIf="panData?.name == 'N' || panData?.dob == 'N'" class="row">
      <div class="col-md-12 text-center">
        <button mat-button (click)="onNoClick()">Back</button>
      </div>
    </div>

    <div></div>
  </div>
</div>
