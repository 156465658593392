import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionStorageService } from '../session-storage.service';
import { ValidationUtility } from 'src/app/utils/validation.utility';
import { environment } from 'src/environments/environment';
import { PAGINATION } from 'src/app/shared/app-constant';

@Injectable({
  providedIn: 'root'
})
export class DeiaaService {

  constructor(
    private http: HttpClient,
  ) { }

  getProposalTransfer(): Observable<any> {
    return this.http.post<any>(
      environment.baseURL + 'ecSeiaaForm/getDeiaaProposalDetailsByUserId',
      {}
    );
  }

  getSeiaaProposalSubmitByDeiaa(state: any): Observable<any> {
    let url = environment.baseURL + "ecSeiaaForm/getSeiaaProposalDetailsSubmitByDeiaa";
    let params: any = {
      state: state
    }
    return this.http.post(url, {}, { params: params });
  }

  getSeiaaProposalSubmitByDeiaaV2(state: any, page = 0, size = PAGINATION.PER_PAGE,searchString=''): Observable<any> {
    let url = environment.baseURL + "ecSeiaaForm/getSeiaaProposalDetailsSubmitByDeiaaV2";
    let params: any = {
      state, page, size, searchString
    }
    return this.http.post(url, {}, { params: params });
  }

  getSeiaaProjectCreations(state: any, district?: any, status?: string): Observable<any> {
    let url = environment.baseURL + "ecSeiaaForm/getSeiaaProposalDetailsFilter";
    let params: any = {
      state: state
    }
    if (district)
      params['district'] = district;
    if (status)
      params['status'] = status;

    return this.http.post(url, {}, { params: params });
  }

  getSeiaaProjectCreationsV2(state: any, district?: any, status?: string, page = 0, size = PAGINATION.PER_PAGE,searchString=''): Observable<any> {
    let url = environment.baseURL + "ecSeiaaForm/getSeiaaProposalDetailsFilterV2";
    let params: any = {
      state: state,
      page: page,
      size: size,
      searchString:searchString
    }
    if (district)
      params['district'] = district;
    if (status)
      params['status'] = status;

    return this.http.post(url, {}, { params: params });
  }
}
