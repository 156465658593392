<div
  class="file-upload"
  [formGroup]="formGroup"
  [ngClass]="extraClass"
  [class.invalid]="control?.touched && control?.invalid"
>
  <div
    [ngClass]="{ 'file-upload-inner': !isOnlyButtonVisible }"
    [class.file-uploaded]="getFilePresent(control?.value)"
  >
    <!-- File Uploading Block -->
    <div
      (click)="uploadDocument.click()"
      [class.d-none]="getFilePresent(control?.value)"
    >
      <div
        class="d-flex flex-column justify-content-between align-items-center h-100"
        appDragNDrop
        (fileDropped)="saveFile($event)"
        [ngClass]="{ 'file-upload-content': !isOnlyButtonVisible }"
      >
        <h6
          class="file-upload-heading text-center fw-normal mb-4"
          *ngIf="!isOnlyButtonVisible"
        >
          {{ placeholder | translate }}
        </h6>
        <span class="file-upload-msg msg-error" *ngIf="!isOnlyButtonVisible">
          <img class="me-2" src="./assets/images/Warning.svg" />
          Only {{ documentMapping.extension?.join(", ") }} file(s) are allowed.
          Maximum file size is {{ documentMapping?.sizelimit }} MB.
        </span>
      </div>
      <span *ngIf="isOnlyButtonVisible">
        <button type="button" class="btn btn-sm btn-primary w-100 mn-w-100">
          Upload {{ documentMapping.extension?.join(", ") }}
        </button>
        <p class="mb-0 mt-2 text-center">
          (Max {{ documentMapping?.sizelimit }} MB)
        </p>
      </span>
      <input
        class="form-control"
        [type]="type"
        (blur)="blur()"
        #uploadDocument
        hidden
        (change)="saveFile($event.target)"
        [disabled]="isReadOnly"
      />
      <input class="form-control" type="text" [formControl]="control" hidden />
    </div>
    <!-- File Uploaded Block  -->
    <div
      class="file-upload-content d-flex flex-column justify-content-between align-items-center h-100"
      *ngIf="getFilePresent(control?.value)"
    >
      <h6
        class="file-upload-heading text-center fw-normal mb-4"
        *ngIf="!isOnlyButtonVisible"
      >
        <img src="./assets/images/file-icon.svg" />
      </h6>
      <span
        class="file-upload-msg msg-success d-inline-flex align-items-center"
        [ngClass]="{ 'flex-column p-2': isOnlyButtonVisible }"
      >
        <span class="file-upload-name text-break me-2">
          {{ getFileName(control?.value) }}
        </span>
        <span class="d-flex">
          <span
            class="text-primary cursor-pointer cross-button"
            (click)="downloadFile()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              version="1.1"
              width="20"
              height="20"
            >
              <g
                stroke="currentColor"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                <polyline points="7 10 12 15 17 10"></polyline>
                <line x1="12" y1="15" x2="12" y2="3"></line>
              </g>
            </svg>
          </span>
          <span
          *ngIf = "preview"
            class="text-primary cursor-pointer cross-button"
            (click)="previewFile()"
          >
            <i class="fa fa-eye fa-2xs" aria-hidden="true"></i>
          </span>
          <span
            [ngClass]="{ 'd-none': isReadOnly }"
            class="text-danger cursor-pointer cross-button"
            (click)="deleteUploadFile()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              version="1.1"
              width="20"
              height="20"
            >
              <g
                stroke="currentColor"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </g>
            </svg>
          </span>
        </span>
      </span>
    </div>
  </div>
  <div
    class="error-msg"
    *ngIf="control?.errors && control?.touched && isRequired == true"
  >
    {{ control?.errors[objectFn.keys(control?.errors)[0]] | translate }}
  </div>
</div>

<ng-template #previewModel let-model>
  <div class="modal-body">
    <div class="text-center">
      <h1 class="h3">{{ getFileName(control?.value) }}</h1>
    </div>
    <div class="" *ngIf="showView">
      <div class="">
        <iframe
          width="100%"
          (load)="isLoading = false"
          class=""
          frameborder="0"
          style="height: 80vh"
        ></iframe>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <a class="btn btn-outline-secondary mn-w-100" (click)="closePreviewModal()">
      Close
    </a>
    <!-- <a class="btn btn-primary mn-w-100" (click)="downloadFile()">Download</a> -->
  </div>
</ng-template>

<ng-template #kmlmodal let-modal>
  <div class="modal-body my-3">
    <div class="text-center">
      <h3></h3>
    </div>
    <div>
      <div class="row">
        <div class="col-12 mt-2">
          <div *ngIf="currentGisUrl" class="ratio ratio-16x9">
            <iframe [src]="currentGisUrl | safe : 'url'"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-secondary" (click)="closePreviewModal()">
      Back
    </button>
  </div>
</ng-template>
