import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { GenericResponse } from '../models/shared/generic-response.model';
import { API } from '../shared/app-constant';

@Injectable({
  providedIn: 'root',
})
export class ConsultantService {
  constructor(private http: HttpClient) { }

  saveQCIOrganization(
    organisationId: any,
    step: any,
    body?: any
  ): Observable<GenericResponse> {
    let params = new HttpParams()
      .append('ConsultantOrgId', organisationId)
      .append('Step', step);
    return this.http.post<GenericResponse>(
      environment.baseURL + API.GET_QCI_ORGANIZATION,
      body,
      { params }
    );
  }

  list(active: string = ''): Observable<any> {
    let params = new HttpParams();
    if (!isEmpty(active)) {
      params.append('active', active);
    }
    return this.http.post<any>(
      environment.baseURL + API.CONSULTANT_LIST,
      {},
      { params }
    );
  }

  all(organisationId: any): Observable<any> {
    let params;
    if (!isEmpty(organisationId)) {
      params = new HttpParams().append('orgId', organisationId);
    }

    return this.http.post<any>(
      environment.baseURL + API.CONSULTANT_LIST_ALL,
      {},
      {
        params,
      }
    );
  }

  activate(body: any): Observable<any> {
    let url = environment.baseURL + API.ACTIVATE_CONSULTANT;
    return this.http.post<any>(url, body);
  }

  mapProject(projectId: any, body: any) {
    let url = environment.baseURL + API.MAP_PROJECT_TO_CONSULTANT;
    let param = new HttpParams().append('projectId', projectId);
    return this.http.post<any>(url, body, {
      params: param,
    });
  }

  mapProjectToConsultant(projectId: any, consultantId: any) {
    let url = environment.baseURL + API.MAP_PROJECT_TO_CONSULTANT_NEW;
    let param = new HttpParams()
      .append('projectId', projectId)
      .append('consultantId', consultantId);
    return this.http.post<any>(
      url,
      {},
      {
        params: param,
      }
    );
  }

  mapProjectToConsultantNew(projectId: any, orgId: any, status: string, sector: string, category: string, remakrs?: string) {
    // let url = environment.baseURL + API.MAP_PROJECT_TO_CONSULTANT_NEW;
    let url = environment.baseURL + API.SAVE_ACO_STATUS;
    let param = new HttpParams()
      .append('projectId', projectId)
      .append('organizationId', orgId)
      .append('status', status)
      .append('sector', sector)
      .append('category', category)
    if (remakrs) {
      param.append('remakrs', remakrs)
    }
    return this.http.post<any>(
      url,
      {},
      {
        params: param,
      }
    );
  }

  getConsultantDetails(projectId?: any) {
    let url = environment.baseURL + API.GET_ACO_LIST;
    let param
    if (projectId)
      param = {
        projectID: projectId
      };
    return this.http.post(url, {}, {
      params: param
    })
  }

  updateACOStatus(projectId: any, orgId: any, status: any, remarks?: string) {
    let url = environment.baseURL + API.UPDATE_ACO_STATUS;
    let param: any = {
      projectId, orgId, status
    }
    if (remarks)
      param['remarks'] = remarks

    return this.http.post(
      url,
      {},
      {
        params: param
      }
    )
  }



  organizations(): Observable<any> {
    let url = environment.baseURL + API.CONSULTANT_ORGANIZATION_LIST;
    return this.http.post<any>(url, {});
  }

  sync(): Observable<any> {
    return this.http.post<any>(
      `${environment.baseURL}${API.CONSULTANT_SYNC}`,
      {}
    );
  }
}
