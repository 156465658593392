<div class="container-fluid">
  <div class="modal-dialog">
    <div class="modal-content" style="text-align: center">
      <!-- Modal Header -->
      <div class="modal-header">
        <h4
          class="modal-title"
          style="font-size: 30px; text-align: center; width: 100%"
        >
          Form not Submitted  .
        </h4>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
     
        <div class="alert alert-danger" role="alert">
          <strong>{{ data.errMsg }}</strong>
        </div>
        <br /><br /><br /><br />
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-success"
          data-bs-dismiss="modal"
          (click)="onNoClick()"
        >
          OK
        </button>
      </div>
    </div>
  </div>
</div>
