import {createReducer, on} from "@ngrx/store";
import {createEntityAdapter, EntityAdapter, EntityState, Update} from "@ngrx/entity";
import {departmentDashboardCompare, DepartmentDashboardProposals} from "../models/Proposals.model";
import {DashboardActions} from "../dashboard-action-types";
import {TypedAction} from "@ngrx/store/src/models";
import {updateIsLoadedFlagToFalseOnTabChange} from "../dashboard.actions";

export interface DashboardState extends EntityState<DepartmentDashboardProposals> {
  isLoaded: boolean;
}

const adapter: EntityAdapter<DepartmentDashboardProposals>= createEntityAdapter<DepartmentDashboardProposals>({
  sortComparer: departmentDashboardCompare,
  // selectId: list => list.id
});

const initialState: EntityState<DepartmentDashboardProposals> & { isLoaded: boolean } = adapter.getInitialState({
  isLoaded: false
});
export const dashboardReducer = createReducer<DashboardState>(
  initialState,
  on(DashboardActions.loadDepartmentDashboardProposalListSuccess, (state: DashboardState, action) => adapter.addMany(action.proposals, {...state, isLoaded: true})),
  on(DashboardActions.updateIsLoadedFlagToFalseOnTabChange, (state: DashboardState, action) => adapter.removeAll({...state, isLoaded: false})),
);


