import { AmountinwordsPipe } from './amountinwords.pipe';
import { CommonModule } from '@angular/common';
import { EnvPipe } from './env.pipe';
import { FilterPipe } from './filter.pipe';
import { NgModule } from '@angular/core';
import { SafePipe } from './safe.pipe';
import { SortPipe } from './sort.pipe';

@NgModule({
  declarations: [EnvPipe, SafePipe, FilterPipe, SortPipe, AmountinwordsPipe],
  exports: [EnvPipe, SafePipe, FilterPipe, SortPipe, AmountinwordsPipe],
  imports: [CommonModule],
})
export class PipeModule {}
