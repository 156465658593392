import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn:'root'
})
export class MessageService {
  constructor(private toastr: ToastrService) {}
  //show success
  showSuccess(
    message: string,
    config: Partial<IndividualConfig> = { timeOut: 5000, disableTimeOut: false }
  ) {
    this.toastr.success(message, '', config);
  }
  //show error
  showError(
    message: string,
    config: Partial<IndividualConfig> = { timeOut: 5000, disableTimeOut: false }
  ) {
    this.toastr.error(message, '', config);
  }

  //hide all notification
  hideAllMessage() {
    this.toastr.clear();
  }
}
