import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { DownloadFileComponent } from './download-file.component';
import { NgModule } from '@angular/core';
import { PipeModule } from '../../pipes/pipe.module';

@NgModule({
  declarations: [DownloadFileComponent],
  exports: [DownloadFileComponent],
  imports: [CommonModule, ReactiveFormsModule, FormsModule,PipeModule],
})
export class DownloadFileModule {}
