import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { User } from 'src/app/models/user/user.model';
import { GenericResponse } from 'src/app/models/shared/generic-response.model';

@Injectable({
  providedIn: 'root',
})
export class PpFormService {
  constructor(private http: HttpClient) {}

  postUserForm(user: User, entType: string): Observable<any> {
    const param = new HttpParams().append('entityType', entType);

    let url = environment.baseURL + 'user/registerUser';

    return this.http.post<any>(url, user, {
      params: param,
    });
  }

  postUserFormEntity(
    user: string,
    // file: any,
    entType: string
  ): Observable<any> {
    const param = new HttpParams()
      // .append('proponent', JSON.stringify(user))
      .append('entityType', entType);

    let url = environment.baseURL + 'user/registerEntity';

    return this.http.post<any>(url, user, {
      params: param,
    });
  }

  postUserFormGovt(user: string,  entType: string): Observable<any> {
    const param = new HttpParams()
      // .append('proponent', JSON.stringify(user))
      .append('entityType', entType);

    let url = environment.baseURL + 'user/registerGovernment';

    return this.http.post<any>(url, user, {
      params: param,
    });
  }

  postUserOther(user: string, entType: string): Observable<any> {
    const param = new HttpParams()
      // .append('proponent', JSON.stringify(user))
      .append('entityType', entType);

    let url = environment.baseURL + 'user/registerOthers';

    return this.http.post<any>(url, user, {
      params: param,
    });
  }

  getStateDepartmentCentre(code: any): Observable<GenericResponse> {
    let url = environment.baseURL + 'kyc/getStateDepartment?code=' + code;

    return this.http.post<GenericResponse>(url, {});
  }

  getCentralDepartment(): Observable<GenericResponse> {
    let url = environment.baseURL + 'kyc/getCenterDepartments';
    return this.http.get<GenericResponse>(url);
  }
}
