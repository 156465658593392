import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { CommonForm } from 'src/app/models/user/common-form.model';
import { SharedServiceService } from '../../../services/shared-service.service';

@Injectable({
  providedIn: 'root',
})
export class BasicGuardGuard implements CanActivate {
  constructor(private service: SharedServiceService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let obj = new CommonForm();

    this.service.updatedCommonDetail.subscribe((res) => {
      obj = res;
    });

    if (
      obj.entityType == '' ||
      obj.registerType == '' ||
      obj.entityType == null ||
      obj.registerType == null
    ) {
      this.router.navigate(['/userRegistration']);
      return false;
    } else if (
      obj.entityType == 'Others' ||
      obj.entityType == '"Central Government (Department/Autonomous body)" ' ||
      obj.entityType == 'State Government (Department/Autonomous body)'
    ) {
      return true;
    } else if (obj.name == '') {
      this.router.navigate(['/userRegistration']);
      return false;
    }
    return true;
  }
}
