<div id="main-content">
  <!-- Service Section -->
  <div class="service-section section-bg">
    <div class="container">
      <h1 class="main-heading">How it works?</h1>
      <div class="row">
        <div class="col-sm-6 col-lg-3">
          <div class="service-box">
            <span class="service-icon">
              <img class="img-fluid" loading="lazy" src="/assets/images/howitworks/register-icon.svg"
                alt="Register Yourself" title="Register Yourself" />
            </span>
            <div class="service-label">Register Yourself</div>
            <div class="service-desc">
              Register with your PAN or <br />
              CIN number
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="service-box">
            <span class="service-icon">
              <img class="img-fluid" loading="lazy" src="/assets/images/howitworks/apply-clr-icon.svg"
                alt="Apply for Clearances" title="Apply for Clearances" />
            </span>
            <div class="service-label">Apply for Clearances</div>
            <div class="service-desc">
              Apply for EC, FC, WC, CRZ <br />
              Clearances
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="service-box">
            <span class="service-icon">
              <img class="img-fluid" loading="lazy" src="/assets/images/howitworks/tos-icon.svg"
                alt="Track Online Status" title="Track Online Status" />
            </span>
            <div class="service-label">Track Online Status</div>
            <div class="service-desc">
              Track your status for <br />
              Proposal Number
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3">
          <div class="service-box">
            <span class="service-icon">
              <img class="img-fluid" loading="lazy" src="/assets/images/howitworks/compliance-report-icon.svg"
                alt="Compliance Report" title="Compliance Report" />
            </span>
            <div class="service-label">Compliance Report</div>
            <div class="service-desc">
              Submit your <br />
              compliance Report
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- KPI Section -->
  <div class="kpi-section">
    <div class="count-boxbg container-fluid">
      <div class="row m-0">
        <div class="col-sm-6 col-xl-3 col-6" *ngFor="let i of KPIMaster | slice: 0:4">
          <div class="count-item">
            <div class="row">
              <div class="col-sm-3">
                <img class="img-fluid" loading="lazy" src="{{ i.image_url }}" alt="" class="mt-3" width="44" />
              </div>
              <div class="col-sm-9">
                <div class="count">{{ i.count }}</div>
                <div class="count-name">{{ i.subject }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Approval Section -->
  <div class="kya-section section-bg">
    <div class="container">
      <h1 class="main-heading">Green Clearances</h1>
    </div>
  </div>

  <!-- Notification Section -->
  <div class="notification-section section-bg">
    <div class="container">
      <div class="row align-items-center">
        <div class="col">
          <h1 class="main-heading">Notifications &amp; Announcements</h1>
        </div>
        <div class="col-auto">
          <a routerLink="/cms/announcements">View more</a>
        </div>
      </div>
    </div>
  </div>

  <div class="events-section section-bg">
    <div class="container">
      <div class="row align-items-center">
        <div class="col">
          <h1 class="main-heading">Events &amp; News</h1>
        </div>
        <div class="col-auto">
          <a routerLink="/cms/events">View more</a>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-xl-4 mt-3" *ngFor="let i of homeRecent">
          <div class="events-box">
            <div class="events-image">
              <div class="ratio ratio-4x3">
                <img loading="lazy" src="{{ i.image_url }}" alt="{{ i.subject }}" />
              </div>
              <div class="event-image-shadow"></div>
              <div class="events-content">
                <p>{{ i.subject }}</p>
                <p>
                  <i class="bi bi-calendar-event"></i> &nbsp;
                  {{ getDisplayDateFormat(i.event_date) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bulliten-section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col">
          <h1 class="main-heading">Circulars &amp; Orders</h1>
        </div>
        <div class="col-auto">
          <a href="#">View more</a>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <ul class="bulliten-items">
            <li *ngFor="
                let i of recentUpload
                  | slice
                    : 0
                    : (recentUpload.length <= 8 ? recentUpload.length / 2 : 4)
              ">
              <a href="{{ i.doc_url }}" target="_blank" download>
                {{ i.subject }}</a>
            </li>
          </ul>
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-5">
          <ul class="bulliten-items">
            <li *ngFor="
                let i of recentUpload
                  | slice
                    : (recentUpload.length <= 8 ? recentUpload.length / 2 : 4)
                    : 8
              ">
              <a href="{{ i.doc_url }}" target="_blank" download>
                {{ i.subject }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="usefullinks-section">
    <div class="container">
      <h1 class="main-heading">Useful Links</h1>
      <div class="row">
        <div class="col-md-4" *ngFor="let i of homeUseFulLink">
          <a href="{{ i.url }}" class="usefull-link text-truncate" title="{{i.subject}}">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15 7H18C18.6566 7 19.3068 7.12933 19.9134 7.3806C20.52 7.63188 21.0712 8.00017 21.5355 8.46447C21.9998 8.92876 22.3681 9.47996 22.6194 10.0866C22.8707 10.6932 23 11.3434 23 12C23 12.6566 22.8707 13.3068 22.6194 13.9134C22.3681 14.52 21.9998 15.0712 21.5355 15.5355C21.0712 15.9998 20.52 16.3681 19.9134 16.6194C19.3068 16.8707 18.6566 17 18 17H15M9 17H6C5.34339 17 4.69321 16.8707 4.08658 16.6194C3.47995 16.3681 2.92876 15.9998 2.46447 15.5355C1.52678 14.5979 1 13.3261 1 12C1 10.6739 1.52678 9.40215 2.46447 8.46447C3.40215 7.52678 4.67392 7 6 7H9"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M8 12H16" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            {{ i.subject }}
            <span>
              <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 13L7 7L1 1" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="media-gallery position-relative">
    <div class="container position-relative">
      <h1 class="main-heading">Media Gallery</h1>
      <div class="gallery" [class.pop]="isGalleryPopupShow">
        <figure (click)="showGalleryPopup(gallery)" *ngFor="let gallery of galleryItems">
          <div class="ratio ratio-4x3">
            <img [src]="gallery.url" alt="" />
          </div>
          <figcaption class="text-truncate">
            {{ gallery.description }}
          </figcaption>
        </figure>
      </div>
      <div class="popup" [class.pop]="isGalleryPopupShow" *ngIf="isGalleryPopupShow">
        <figure>
          <img class="img-fluid" [src]="selectedGallery.url" alt="" />
          <figcaption>{{ selectedGallery.description }}</figcaption>
          <div class="close" (click)="hideGalleryPopup()">
            <svg>
              <use xlink:href="./assets/images/icons.svg#close"></use>
            </svg>
          </div>
        </figure>
      </div>
    </div>
  </div>

  <!-- <div class="clients-section">
    <div class="container-fluid text-center">
      <div class="clients-logo">
        <span class="client-logo-image">
          <a href="https://swachhbharat.mygov.in/" target="_blank">
            <img loading="lazy" src="/assets/homePageImages/sb-logo.png" alt="Swachh Bharat" title="Swachh Bharat" />
          </a>
        </span>
        <span class="client-logo-image">
          <a href="https://www.digitalindia.gov.in/" target="_blank">
            <img loading="lazy" src="/assets/homePageImages/di-logo.png" alt="Digital India" title="Digital India" />
          </a>
        </span>
        <span class="client-logo-image">
          <a href="https://data.gov.in/" target="_blank">
            <img loading="lazy" src="/assets/homePageImages/data-gov-logo.png" alt="Data.gov.in" title="Data.gov.in" />
          </a>
        </span>
        <span class="client-logo-image">
          <a href="https://www.mygov.in/" target="_blank">
            <img loading="lazy" src="/assets/homePageImages/mygov-logo.png" alt="MyGov" title="MyGov" />
          </a>
        </span>
        <span class="client-logo-image">
          <a href="https://www.india.gov.in/" target="_blank">
            <img loading="lazy" src="/assets/homePageImages/india-logo.png" alt="Inidia.Gov.In" title="Inidia.Gov.In" />
          </a>
        </span>
        <span class="client-logo-image">
          <a href="https://www.meity.gov.in/" target="_blank">
            <img loading="lazy" src="/assets/homePageImages/meity-logo.png" alt="MeitY" title="MeitY" />
          </a>
        </span>
        <span class="client-logo-image">
          <a href="https://www.nic.in/" target="_blank">
            <img loading="lazy" src="/assets/homePageImages/nic-logo.png" alt="NIC" title="NIC" />
          </a>
        </span>
      </div>
    </div>
  </div> -->
</div>
<ng-template #modiModalPopup let-modal>
  <button
    type="button"
    class="btn btn-link btn-modi-modal-close p-0 position-absolute"
    (click)="modal.dismiss('Cross click')"
  >
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M20 12L12 20"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20 20L12 12"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
  <div class="modal-body p-0 overflow-hidden position-relative rounded">
    <img class="img-fluid" src="/assets/images/bg-modal-popup.png" alt="" />
  </div>
</ng-template>
