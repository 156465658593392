import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';
import { environment } from 'src/environments/environment';

@Pipe({ name: 'env' })
export class EnvPipe implements PipeTransform {
  transform(variable: any): any {
    return get(environment, variable);
  }
}
