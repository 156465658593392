<div class="container">
  <div class="card1" style="font-family: 'Lato'">
    <div id="headingTwo"></div>
    <form [formGroup]="registerUserForm" (ngSubmit)="onSubmit()">
      <div class="text-center">
        <h1
          class="heading mt-3 text-capitalize"
          *ngIf="registerUserForm.controls['name_of_Entity'].value != ''"
        >
          Welcome,
          <b class="text-capitalize">{{
            registerUserForm.controls["name_of_Entity"].value | titlecase
          }}</b>
        </h1>
        <h1
          class="heading mt-3 text-capitalize"
          *ngIf="registerUserForm.controls['name_of_Entity'].value == ''"
        >
          Welcome
        </h1>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 heading3">Enter your location details</div>
          <ng-container
            *ngIf="
              cEntityType == 'Central Government (Department/Autonomous body)'
            "
          >
            <!--Name of project proponent-->
            <ng-container>
              <div class="col-sm-3">
                <label for="userNAme"
                  >Name of Project Proponent Entity<b class="text-red">*</b>
                </label>
              </div>

              <div class="col-sm-5 z-index-up">
                <div
                  class="form-group"
                  *ngIf="registerUserForm.controls['pan_no'].value != null"
                >
                  <input
                    type="text"
                    id="userNAme"
                    placeholder="Name of Project Proponent Entity"
                    class="form-control"
                    formControlName="name_of_Entity"
                    value="{{
                      registerUserForm.controls['name_of_Entity'].value
                    }}"
                  />
                </div>
                <div
                  class="form-group"
                  *ngIf="registerUserForm.controls['pan_no'].value == null"
                >
                  <select
                    name="ddl_regstate"
                    id="ddl_regstate"
                    class="form-select form-control"
                    formControlName="name_of_Entity"
                  >
                    <option value="">{{ "generic.select" | translate }}</option>
                    <option
                      value="{{ st.dept_Name }}"
                      *ngFor="let st of centralDepartmentList"
                      class="text-capitalize"
                    >
                      {{ st.dept_Name.toString() | titlecase }}
                    </option>
                  </select>
                  <span
                    class="errorMsg"
                    *ngIf="
                      registerUserForm.controls['name_of_Entity'].value == '' &&
                      validationCheck
                    "
                    >*Field Required
                  </span>
                </div>
              </div>

              <div class="col-sm-4"></div>
            </ng-container>
            <!--Contact Person-->
            <div class="col-sm-3">
              <label for="userNAme" class="col-form-label"
                >Name of Contact Person<b class="text-red">*</b>
              </label>
            </div>
            <div class="col-sm-5 z-index-up">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="userNAme"
                  placeholder="Name of Contact Person"
                  value="Name of Contact Person"
                  formControlName="name_of_Contact_Person"
                  [OnlyAlphabetAndSpace]="true"
                />
                <span
                  class="errorMsg"
                  *ngIf="
                    registerUserForm.controls['name_of_Contact_Person'].value ==
                      '' && validationCheck
                  "
                >
                  {{ "generic.required" | translate }}
                </span>
              </div>
            </div>

            <div class="col-sm-4"></div>

            <!--  Address-->
            <div class="col-sm-3">
              <label for="txt_regaddress" class="col-form-label"
                >Address (Registered Office)<b class="text-red">*</b>
              </label>
            </div>
            <div class="col-sm-5 z-index-up">
              <div class="form-group">
                <textarea
                  name="txt_regaddress"
                  rows="3"
                  cols="10"
                  id="txt_regaddress"
                  class="form-control"
                  placeholder="Max 250 characters only"
                  autocomplete="Off"
                  maxlength="250"
                  formControlName="address"
                  appBlockCopyPaste
                ></textarea>
                <span
                  class="errorMsg"
                  *ngIf="
                    registerUserForm.controls['address'].errors &&
                    validationCheck
                  "
                  >*Field Required
                </span>
              </div>
            </div>

            <div class="col-sm-4"></div>

            <!--State-->
            <div class="col-sm-3">
              <label for="ddl_regstate" class="col-form-label"
                >State/ UT<b class="text-red">*</b></label
              >
            </div>
            <div class="col-sm-5 z-index-up">
              <div class="form-group">
                <select
                  name="ddl_regstate"
                  id="ddl_regstate"
                  class="form-select form-control"
                  formControlName="state_ut"
                  (change)="getSelectedDist($event)"
                >
                  <option value="">{{ "generic.select" | translate }}</option>
                  <option value="{{ st.code }}" *ngFor="let st of stateList">
                    {{ st.name.toString() | titlecase }}
                  </option>
                </select>
                <span
                  class="errorMsg"
                  *ngIf="
                    registerUserForm.controls['state_ut'].value == '' &&
                    validationCheck
                  "
                  >*Field Required
                </span>
              </div>
            </div>

            <div class="col-sm-4"></div>
          </ng-container>
          <ng-container
            *ngIf="
              cEntityType == 'State Government (Department/Autonomous body)'
            "
          >
            <!--Name of project proponent-->
            <ng-container *ngIf="enableSection">
              <div class="col-sm-3">
                <label for="userNAme"
                  >Name of Project Proponent Entity<b class="text-red">*</b>
                </label>
              </div>
              <div class="col-sm-5 z-index-up">
                <div class="form-group">
                  <input
                    type="text"
                    id="userNAme"
                    placeholder="Name of Project Proponent Entity"
                    class="form-control"
                    formControlName="name_of_Entity"
                    value="{{
                      registerUserForm.controls['name_of_Entity'].value
                    }}"
                  />
                </div>
              </div>
              <div class="col-md-4"></div>
            </ng-container>

            <!--Dept of state-->
            <ng-container *ngIf="!enableSection">
              <div class="col-sm-3">
                <label for="ddl_regstate"
                  >Department of State<b class="text-red">*</b>
                </label>
              </div>
              <div class="col-sm-5 z-index-up">
                <div class="form-group">
                  <select
                    class="form-select form-control"
                    name="ddl_regstate"
                    id="ddl_regstate"
                    formControlName="state_ut"
                    (change)="getStateDepartmentByCode($event)"
                  >
                    <option value="">{{ "generic.select" | translate }}</option>
                    <option value="{{ st.code }}" *ngFor="let st of stateList">
                      {{ st.name.toString() | titlecase }}
                    </option>
                  </select>
                  <span
                    class="errorMsg"
                    *ngIf="
                      registerUserForm.controls['state_ut'].value == '' &&
                      validationCheck
                    "
                    >*Field Required
                  </span>
                </div>
              </div>

              <div class="col-sm-4"></div>

              <div class="col-sm-3">
                <label for="userNAme" class="col-form-label"
                  >Name of Project Proponent Entity<b class="text-red">*</b>
                </label>
              </div>
              <div class="col-sm-5 z-index-up">
                <div class="form-group">
                  <select
                    class="form-select form-control"
                    formControlName="name_of_Entity"
                  >
                    <option value="">{{ "generic.select" | translate }}</option>
                    <option *ngFor="let val of selectedStateDepartment">
                      {{ val.dept_Name }}
                    </option>
                  </select>
                  <span
                    class="errorMsg"
                    *ngIf="
                      registerUserForm.controls['name_of_Entity'].errors &&
                      validationCheck
                    "
                    >*Field Required
                  </span>
                </div>
              </div>
              <div class="col-sm-4"></div>
            </ng-container>

            <!--Contact person-->
            <div class="col-sm-3">
              <label for="userNAme" class="col-form-label"
                >Name of Contact Person<b class="text-red">*</b></label
              >
            </div>
            <div class="col-sm-5 z-index-up">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="userNAme"
                  class="form-control"
                  placeholder="Name of Contact Person"
                  value="Name of Contact Person"
                  formControlName="name_of_Contact_Person"
                  [OnlyAlphabetAndSpace]="true"
                />
                <span
                  class="errorMsg"
                  *ngIf="
                    registerUserForm.controls['name_of_Contact_Person']
                      .errors && validationCheck
                  "
                >
                  {{ "generic.required" | translate }}
                </span>
              </div>
            </div>
            <div class="col-sm-4"></div>

            <!--Address-->
            <div class="col-sm-3">
              <label for="txt_regaddress" class="col-form-label"
                >Address (Registered Office)<b class="text-red">*</b></label
              >
            </div>
            <div class="col-sm-5 z-index-up">
              <div class="form-group">
                <textarea
                  name="txt_regaddress"
                  rows="3"
                  cols="10"
                  id="txt_regaddress"
                  class="form-control"
                  placeholder="Max 250 characters only"
                  autocomplete="Off"
                  maxlength="250"
                  formControlName="address"
                  appBlockCopyPaste
                ></textarea>
                <span
                  class="errorMsg"
                  *ngIf="
                    registerUserForm.controls['address'].errors &&
                    validationCheck
                  "
                  >*Field Required
                </span>
              </div>
            </div>

            <div class="col-sm-4"></div>

            <!--State/UT-->
            <div class="col-sm-3">
              <label for="ddl_regstate"
                >State/ UT<b class="text-red">*</b></label
              >
            </div>
            <div class="col-sm-5 z-index-up" *ngIf="enableSection">
              <div class="form-group">
                <select
                  name="ddl_regstate"
                  id="ddl_regstate"
                  class="form-select form-control"
                  formControlName="state_ut"
                  (change)="getSelectedDist($event)"
                >
                  <option value="">{{ "generic.select" | translate }}</option>
                  <option value="{{ st.code }}" *ngFor="let st of stateList">
                    {{ st.name.toString() | titlecase }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-5 z-index-up" *ngIf="!enableSection">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  value="{{ stateName | titlecase }}"
                  [readonly]="true"
                />
                <span
                  class="errorMsg"
                  *ngIf="
                    registerUserForm.controls['state_ut'].value == '' &&
                    validationCheck
                  "
                  >*Field Required
                </span>
              </div>
            </div>

            <div class="col-sm-4"></div>
          </ng-container>

          <!--District-->
          <div class="col-sm-3">
            <label for="ddl_regdistrict" class="col-form-label"
              >District<b class="text-red">*</b></label
            >
          </div>
          <div class="col-sm-5 z-index-up">
            <div class="form-group">
              <select
                class="form-select form-control"
                name="ddl_regdistrict"
                id="ddl_regdistrict"
                formControlName="district"
              >
                <option value="">{{ "generic.select" | translate }}</option>
                <option
                  value="{{ dist.code }}"
                  *ngFor="let dist of selectedDistrictList"
                  class="text-capitalize"
                >
                  {{ dist.name.toString() | titlecase }}
                </option>
              </select>
              <span
                class="errorMsg"
                *ngIf="
                  registerUserForm.controls['district'].value == '' &&
                  validationCheck
                "
                >*Field Required
              </span>
            </div>
          </div>

          <div class="col-sm-4"></div>

          <!--PIN Code-->
          <div class="col-sm-3">
            <label for="txt_regpincode" class="col-form-label"
              >PIN code<b class="text-red">*</b></label
            >
          </div>
          <div class="col-sm-5 z-index-up">
            <div class="form-group">
              <input
                class="form-control"
                name="txt_regpincode"
                type="text"
                maxlength="6"
                id="txt_regpincode"
                pattern="[0-9]{6}"
                formControlName="pincode"
                placeholder="Pincode"
                appBlockCopyPaste
                [appOnlynumber]="true"
              />
              <div
                class="errorMsg"
                *ngIf="(registerUserForm.controls['pincode'].value=='')&& registerUserForm.controls['pincode'].errors?.['required'] &&validationCheck"
              >
                *Field Required
              </div>
              <div
                class="errorMsg"
                *ngIf="(registerUserForm.controls['pincode'].value!='')&& registerUserForm.controls['pincode'].errors?.['pattern']"
              >
                Please enter valid Pincode
              </div>
            </div>
          </div>
          <div class="col-sm-4"></div>

          <!--Email ID-->
          <div class="col-sm-3">
            <label for="txtcontactemail">
              Email Id<b class="text-red">*</b>
            </label>
          </div>
          <div class="col-sm-5 z-index-up">
            <div class="form-group">
              <input
                name="txtcontactemail"
                type="email"
                id="txtcontactemail"
                class="form-control"
                autocomplete="Off"
                appBlockCopyPaste
                formControlName="email"
                (input)="checkEmail($event)"
                maxlength="320"
                placeholder="Enter Email ID"
              />
              <div
                class="errorMsg"
                *ngIf="registerUserForm.controls['email'].errors?.['required']&&validationCheck"
              >
                {{ "generic.required" | translate }}
              </div>
              <div
                class="errorMsg"
                *ngIf="registerUserForm.controls['email'].errors?.['pattern']"
              >
                <small> Please enter valid Email Id</small>
              </div>
              <div
                class="errorMsg"
                *ngIf="duplicateEmail == 'YES' && emailError == ''"
              >
                <small
                  >*Email Address is already registered with Parivesh</small
                >
              </div>
              <div
                class="text-success"
                *ngIf="
                  duplicateEmail == 'NO' &&
                  registerUserForm.controls['email'].dirty &&
                  emailError == ''
                "
              >
                <small> *This email is available for registration.</small>
              </div>

              <div
                class="errorMsg"
                *ngIf="
                  emailError != '' && registerUserForm.controls['email'].dirty
                "
              >
                <small>*{{ emailError }}</small>
              </div>
            </div>
          </div>
          <div class="col-sm-4"></div>
          <div class="col-sm-3">
            <label for="txtcontactmobile"
              >Mobile No.<b class="text-red">*</b>
            </label>
          </div>

          <div class="col-sm-5 z-index-up">
            <div style="margin-bottom: 25px; padding: 0 22px">
              <input
                class="form-check-input"
                (change)="checkMobile($event)"
                value="1"
                type="checkbox"
                id="check"
              />
              <span class="text-danger" style="font-size: 14px">
                Tick to confirm if your mobile no. is linked with Aadhar</span
              >
            </div>
            <div style="margin-bottom: 25px">
              <input
                name="txtcontactmobile"
                type="text"
                maxlength="10"
                id="txtcontactmobile"
                class="form-control"
                autocomplete="Off"
                placeholder="Mobile No."
                formControlName="mobile"
                [readonly]="!check"
                appBlockCopyPaste
                [appOnlynumber]="true"
              />
              <div
                class="errorMsg"
                *ngIf="registerUserForm.controls['mobile'].errors?.['required']&&validationCheck"
              >
                *Field Required
              </div>
              <div
                *ngIf="registerUserForm.controls['mobile'].errors?.['pattern'] "
                class="errorMsg"
              >
                Please enter valid mobile number
              </div>
            </div>
          </div>
          <div class="col-sm-4"></div>

          <div class="col-sm-3">
            <label for="txtstdcountry">Landline Number </label>
          </div>

          <div class="col-sm-2 z-index-up">
            <div>
              <input
                name="txtstdcountry"
                type="tel"
                maxlength="5"
                minlength="2"
                id="txtstdcountry"
                (keyup)="checkStdCode($event)"
                class="form-control"
                appBlockCopyPaste
                autocomplete="Off"
                placeholder="STD Code"
                [appOnlynumber]="true"
              />
            </div>
          </div>
          <div class="col-sm-3 z-index-up">
            <input
              name="txt_regphoneno"
              type="tel"
              maxlength="8"
              id="txt_regphoneno"
              class="form-control"
              appBlockCopyPaste
              autocomplete="Off"
              placeholder="Landline No."
              formControlName="landline_No"
              placeholder="Landline number"
              [appOnlynumber]="true"
              (keyup)="LandLineValidation()"
            />
          </div>

          <div class="col-sm-4"></div>
          <div class="offset-3 col-sm-5 form-group">
            <div class="errorMsg">
              Total length of Landline No. must not be greater than 13 digits.
            </div>
            <div class="errorMsg">
              {{ errorMsgStdCode }}
            </div>
            <div class="errorMsg">
              {{ errorMsgLandLineNumber }}
            </div>
          </div>
          <div class="col-sm-4"></div>
          <div class="col-sm-3">
            <label for="txt_regwebsite">Website </label>
          </div>
          <div class="col-sm-5 z-index-up">
            <div class="form-group">
              <input
                name="txt_regwebsite"
                appBlockCopyPaste
                type="text"
                id="txt_regwebsite"
                class="form-control"
                autocomplete="Off"
                formControlName="website"
                placeholder="www.example.com"
              />
            </div>
          </div>
          <div class="col-sm-4"></div>
          <ng-container *ngIf="cEntityType != 'Individual'">
            <!-- <div class="col-sm-3">
                            <label for="fileUpload">Upload Authority Letter<b class="text-red">*</b>
                            </label>
                        </div>
                        <div class="col-sm-5 z-index-up">
                            <div class="form-group">
                                <input name="fileUpload" appBlockCopyPaste type="file" id="fileUpload" #file
                                    class="form-control upload" accept=".pdf" (change)="checkFileupload($event)" />

                                <div class="errorMsg" *ngIf="(!isFilePresent)&&validationCheck&&(FileErrorMsg=='')">
                                    {{ "generic.required" | translate }}
                                </div>
                                <div class="errorMsg" *ngIf="!isFilePresent &&(FileErrorMsg!='')">
                                    {{FileErrorMsg}}
                                </div>
                                <div class="errorMsg">(Authority Letter must be Only in pdf format and max. allowed size
                                    will be upto 2 MB only)
                                </div>

                            </div>
                        </div> -->

            <div class="row mb-3 upload-wrap">
              <label class="col-form-label col-5 field-required"
                >Upload Authority Letter</label
              >
              <div class="col-7">
                <app-upload-document
                  [refId]="registerUserForm.controls['email'].value"
                  [refType]="DOCUMENT_REF_TYPES.USER_REGISTRATION"
                  [documentMapping]="
                    getDocumentTypeMapping(DOCUMENT_TYPE.USER_AUTHORITY_LETTER)
                  "
                  name="authority_letter"
                  [control]="registerUserForm.get('authority_letter')"
                  [formGroup]="registerUserForm"
                  (shareData)="receivedMessageHandler($event)"
                  [isReadOnly]="isReadonly"
                ></app-upload-document>
              </div>
            </div>

            <div class="row my-3">
              <div class="col-5"></div>
              <div class="col-7 errorMsg">
                In case if PAN is not available then provide authority letter for authorising a person for registration on PARIVESH and declaration that PAN is not available.
              </div>
            </div>

            <!-- <div class="col-sm-4"></div> -->
          </ng-container>
          <div class="col-sm-3">
            <label for="email">Captcha Code<b class="text-red">*</b> </label>
          </div>
          <div class="col-sm-2 z-index-up">
            <canvas
              style="border-radius: 4px"
              width="126px"
              height="52px"
              #myCanvas
            ></canvas>
          </div>
          <div class="col-sm-2 z-index-up">
            <button type="button" class="btnCaptcha" (click)="getCaptcha(6)">
              <span (click)="getCaptcha(6)" class="btnCaptchatext"
                >&#10226; Refresh</span
              >
            </button>
          </div>
          <div class="col-sm-3 z-index-up">
            <input
              class="captcha-input form-control"
              appBlockCopyPaste
              type="text"
              #captcha
              aria-describedby="error-captcha"
              placeholder="Enter Captcha"
              (blur)="captchaCaptured($event)"
              maxlength="6"
            />
          </div>
          <div class="col-sm-2"></div>
          <div class="col-sm-3 offset-sm-3">
            <p *ngIf="isCaptcha" class="text-success">&#10004;</p>
            <span
              *ngIf="captchaErrorMsg"
              id="error-captcha"
              class="p-error text-danger"
              >Captcha mismatch .</span
            >
          </div>
        </div>
        <div class="text-center offset-sm-1 row">
          <span class="pt-4" style="font-size: 14px">
            <input
              class="form-check-input"
              (change)="checkDeclare($event)"
              value="1"
              type="checkbox"
              id="check"
            />&nbsp; <b>Declaration:</b> I agree to the correctness of
            information provided and accept the Terms of Use and Privacy Policy
            applicable to avail the services of this platform.</span
          >
        </div>
      </div>

      <div class="text-center offset-sm-6 row">
        <button
          matStepperPrevious
          class="text-success btncustom z-index-up"
          style="
            position: relative;
            background-color: #f8f9fa;
            border-color: green;
          "
        >
          Back
        </button>
        <button
          type="submit"
          [disabled]="duplicateEmail == 'YES' || checkDeclaration == false"
          class="btn btncustom text-white z-index-up"
          style="position: relative"
        >
          Submit
        </button>
      </div>
      <br />

      <div class="alert alert-warning form-note" role="alert">
        <i class="bi bi-exclamation-triangle" style="font-size: 18px"></i>
        <b>Note:</b>
        Once Registration application is submitted the activation mail will be
        sent to the registered Email Id, Click on the activation link within 24*
        hours to activate your login.
      </div>
    </form>
  </div>
</div>
