<div class="container-fluid custom" style="font-family: 'Lato'">
  <div class="row">
    <div class="col-sm-12 col-md-12 row pTop">
      <div class="col-sm-1"></div>
      <div class="col-sm-1 padding1">
        <span class="btnBack" routerLink="/home">
          <svg width="24" height="24" viewBox="0 5 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.25 12H3.75" stroke="#181824" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.5 5.25L3.75 12L10.5 18.75" stroke="#181824" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
          &nbsp; Back
        </span>
      </div>
      <div class="col-sm-8">
        <h4 class="headingForm text-center">Registration</h4>
      </div>
      <div class="col-sm-2"></div>
    </div>
    <div class="container-fluid">
      <div>
        <img src="../../../../assets/images/background.png" class="bg-image" />
      </div>

      <mat-horizontal-stepper [linear]="true" #stepper="matHorizontalStepper" labelPosition="bottom">
        <ng-template matStepperIcon="numberC" let-index="index">
          <b>{{ "0" + (index + 1) }}</b>
        </ng-template>
        <ng-template matStepperIcon="number" let-index="index">
          <b> {{ "0" + (index + 1) }}</b>
        </ng-template>
        <ng-template matStepperIcon="edit" let-index="index">
          <b>{{ "0" + (index + 1) }}</b>
        </ng-template>
        <ng-template matStepperIcon="done" let-index="index"><b>{{ "0" + (index + 1) }}</b></ng-template>
        <mat-step [stepControl]="commonForm" state="numberC">
          <div class="card1" style="font-family: 'Lato'">
            <div class="form-horizontal container-fluid">
              <form [formGroup]="commonForm" (ngSubmit)="onSubmit()">
                <ng-template matStepLabel><b>Details</b></ng-template>
                <div class="card-body">
                  <div class="pb-2 pt-2 row">
                    <div class="col-sm-3">
                      <label for="RegisterTy" class="inputLabel">Register As<b class="errorMsg">*</b></label>
                    </div>
                    <div class="col-sm-6 form-group z-index-up">
                      <select class="form-select form-control inputField" formControlName="registerType" id="RegisterTy"
                        name="RegisterTy" [(ngModel)]="selectedRegisterType" (change)="onRegisterEntityChange($event)">
                        <option value="" disabled selected hidden>
                          <b>{{ "generic.select" | translate }}</b>
                        </option>

                        <option *ngFor="let user of registerType" [value]="user.name">
                          {{ user.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="pb-2 pt-2 row" *ngIf="selectedRegisterType === 'Accredited Consultant Organization'">
                    <div class="col-sm-3">
                      <label for="OrgId" class="inputLabel">Organization Id<b class="errorMsg">*</b></label>
                    </div>
                    <div class="col-sm-6 form-group z-index-up">
                      <input type="text" class="form-control inputField text-normal text-uppercase"
                        formControlName="orgId" id="OrgId" name="OrgId" placeholder="QCI Organization Id"
                        maxlength="9" />
                      <span class="errorMsg" *ngIf="
                          commonForm.controls['orgId'].errors && validationCheck
                        ">
                        {{ "generic.required" | translate }}
                      </span>
                    </div>

                  </div>

                  <div class="row pb-2 form-group" *ngIf="
                      selectedRegisterType == 'Project Proponent/User Agency' ||
                      selectedRegisterType == 'Auditors'
                    ">
                    <div class="col-sm-3">
                      <label for="entType" class="inputLabel">Entity Type<b class="errorMsg">*</b>
                      </label>
                    </div>
                    <div class="col-sm-6 z-index-up">
                      <select class="form-select form-control inputField" id="entType" name="entType"
                        formControlName="entityType" (change)="resetFormOnEntityChange($event)">
                        <option value="" disabled selected hidden>
                          Select
                        </option>

                        <option *ngFor="let enttype of entityType" value="{{ enttype }}">
                          {{ enttype == 'Private Limited' ? 'Company' : enttype }}
                        </option>
                      </select>
                      <div class="text-success pl-1" *ngIf="group == 'GROUP4'">
                        Specially applicable for individuals applying for CRZ
                        clearance <span class="text-danger">(Fishermen Only)</span>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="
                      group == 'GROUP1' &&
                      (selectedRegisterType == 'Project Proponent/User Agency' ||
                        selectedRegisterType == 'Auditors')
                    ">
                    <div class="row form-group">
                      <div class="row form-group">
                        <div class="col-sm-3">
                          <label for="panNumber" class="inputLabel">PAN of Entity <b style="color: red">*</b>
                          </label>
                        </div>
                        <div class="col-sm-6 autoCaptilize z-index-up">
                          <input
                            type="text"
                            class="form-control autoCaptilize inputField"
                            formControlName="panNumber"
                            placeholder="Enter PAN Number"
                            autocomplete="Off"
                            maxlength="10"
                            id="panNumber"
                            [appOnlyTextNumber]="true"
                            pattern="[a-zA-Z]{3}[cpghfatbljCPGHFATBLJ][A-Za-z]\d{4}[A-Za-z]"
                            appBlockCopyPaste
                          />
                        </div>
                      </div>

                      <div class="row form-group">
                        <div class="col-sm-3">
                          <label for="panName" class="inputLabel">NAME of Entity as per PAN <b style="color: red">*</b>
                          </label>
                        </div>
                        <div class="col-sm-6 autoCaptilize z-index-up">
                          <input
                            type="text"
                            class="form-control autoCaptilize inputField"
                            formControlName="panName"
                            placeholder="Enter Name"
                            autocomplete="Off"
                            maxlength="85"
                            id="panName"
                            appBlockCopyPaste
                          />
                        </div>
                      </div>

                      <div class="row form-group">
                        <div class="col-sm-3">
                          <label for="fathername" class="inputLabel">Father's name as per PAN (For Individual Only)
                          </label>
                        </div>
                        <div class="col-sm-6 autoCaptilize z-index-up">
                          <input
                            type="text"
                            class="form-control autoCaptilize inputField"
                            formControlName="fathername"
                            placeholder="Enter Father's Name"
                            autocomplete="Off"
                            maxlength="75"
                            id="fathername"
                            [appOnlyText]="true"
                            appBlockCopyPaste
                          />
                        </div>
                      </div>

                      <div class="row form-group">
                        <div class="col-sm-3">
                          <label for="dob" class="inputLabel">DOB or Date of Incorporation as per PAN <b style="color: red">*</b>
                          </label>
                        </div>
                        <div class="col-sm-6 autoCaptilize z-index-up">
                          <input
                            type="date"
                            class="form-control inputField"
                            formControlName="dob"
                            placeholder="Select Date"
                            autocomplete="Off"
                            id="dob"
                            [appOnlyTextNumber]="true"
                          />
                        </div>

                        <div class="col-sm-3 z-index-up" style="align-self: center">
                          <button type="button" class="btn btn-outline-secondary" (click)="verifyPan()"
                                  [disabled]="isSubmit">
                            <img src="assets/images/search.svg" alt="">
                          </button>
                        </div>
                        <div class="col-sm-8 offset-sm-3 errorMsg">
                          Click on Search button to verify PAN No. (Mandatory)
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="
                      group == 'GROUP2' &&
                      (selectedRegisterType == 'Project Proponent/User Agency' ||
                        selectedRegisterType == 'Auditors')
                    ">
                    <div>
                      <div class="form-group pt-2 pb-2 row">
                        <div class="col-sm-3">
                          <label for="entType" class="inputLabel">Is CIN available to the <br />Entity?<b
                              class="errorMsg">*</b>
                          </label>
                        </div>
                        <div class="col-sm-6 z-index-up">
                          <select #cin class="form-select form-control" (change)="cinCheck($event)">
                            <option value="Intial" disabled selected hidden>
                              Select
                            </option>

                            <option [disabled]="isSubmit" value="YES">
                              YES
                            </option>
                            <option [disabled]="isSubmit" value="NO">NO</option>
                          </select>
                        </div>
                      </div>
                      <div class="row form-group">
                        <div *ngIf="isCinChecked == 'YES'">
                          <div class="row">
                            <div class="col-sm-3">
                              <label for="CIN" class="inputLabel">CIN of Entity <b style="color: red">*</b>
                              </label>
                            </div>
                            <div class="col-sm-6 autoCaptilize z-index-up">
                              <input type="text" class="form-control autoCaptilize" formControlName="cin"
                                placeholder="Enter CIN" maxlength="21" id="CIN" appBlockCopyPaste  />
                            </div>

                            <!-- <div class="col-sm-3 z-index-up" style="align-self: center">
                              <button type="button" class="btn btn-outline-secondary" (click)="verifyCin()">
                                <i class="bi bi-search"></i>
                              </button>
                            </div> -->
                            <div class="col-sm-8 offset-sm-3 invalid-feedback">
                              Click on Search button to verify CIN (Mandatory)
                            </div>
                          </div>
                          <div class="row pt-2 pb-2">
                            <div class="col-sm-3">
                              <label for="Yoc" class="inputLabel">Year of Incorporation <b class="errorMsg">*</b>
                              </label>
                            </div>
                            <div class="col-sm-6 autoCaptilize z-index-up">
                              <input class="inputField" type="text" class="form-control autoCaptilize"
                                formControlName="Yoc" placeholder="Enter Year of Incorporation" id="Yoc"
                                appBlockCopyPaste [appOnlynumber]="true" [maxLength]="4" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row form-group">
                      <div class="row form-group">
                        <div class="col-sm-3">
                          <label for="panNumber" class="inputLabel">PAN of Entity <b style="color: red">*</b>
                          </label>
                        </div>
                        <div class="col-sm-6 autoCaptilize z-index-up">
                          <input
                            type="text"
                            class="form-control autoCaptilize inputField"
                            formControlName="panNumber"
                            placeholder="Enter PAN Number"
                            autocomplete="Off"
                            maxlength="10"
                            id="panNumber"
                            [appOnlyTextNumber]="true"
                            pattern="[a-zA-Z]{3}[cpghfatbljCPGHFATBLJ][A-Za-z]\d{4}[A-Za-z]"
                            appBlockCopyPaste
                          />
                        </div>
                      </div>

                      <div class="row form-group">
                        <div class="col-sm-3">
                          <label for="panName" class="inputLabel">NAME of Entity as per PAN <b style="color: red">*</b>
                          </label>
                        </div>
                        <div class="col-sm-6 autoCaptilize z-index-up">
                          <input
                            type="text"
                            class="form-control autoCaptilize inputField"
                            formControlName="panName"
                            placeholder="Enter Name"
                            autocomplete="Off"
                            maxlength="85"
                            id="panName"
                            appBlockCopyPaste
                          />
                        </div>
                      </div>

                      <div class="row form-group">
                        <div class="col-sm-3">
                          <label for="fathername" class="inputLabel">Father's name as per PAN (For Individual Only)
                          </label>
                        </div>
                        <div class="col-sm-6 autoCaptilize z-index-up">
                          <input
                            type="text"
                            class="form-control autoCaptilize inputField"
                            formControlName="fathername"
                            placeholder="Enter Father's Name"
                            autocomplete="Off"
                            maxlength="75"
                            id="fathername"
                            [appOnlyText]="true"
                            appBlockCopyPaste
                          />
                        </div>
                      </div>

                      <div class="row form-group">
                        <div class="col-sm-3">
                          <label for="dob" class="inputLabel">DOB or Date of Incorporation as per PAN <b style="color: red">*</b>
                          </label>
                        </div>
                        <div class="col-sm-6 autoCaptilize z-index-up">
                          <input
                            type="date"
                            class="form-control inputField"
                            formControlName="dob"
                            placeholder="Select Date"
                            autocomplete="Off"
                            id="dob"
                            [appOnlyTextNumber]="true"
                          />
                        </div>

                        <div class="col-sm-3 z-index-up" style="align-self: center">
                          <button type="button" class="btn btn-outline-secondary" (click)="verifyPan()"
                                  [disabled]="isSubmit">
                            <img src="assets/images/search.svg" alt="">
                          </button>
                        </div>
                        <div class="col-sm-8 offset-sm-3 errorMsg">
                          Click on Search button to verify PAN No. (Mandatory)
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="
                      group == 'GROUP3' &&
                      (selectedRegisterType == 'Project Proponent/User Agency' ||
                        selectedRegisterType == 'Auditors')
                    ">
                    <div class="form-group pt-2 pb-2 row">
                      <div class="col-sm-3">
                        <label for="entType" class="inputLabel">Is PAN available to the Entity?<b class="errorMsg">*</b>
                        </label>
                      </div>
                      <div class="col-sm-6 z-index-up">
                        <select #pan class="form-select form-control" (change)="panCheck($event)">
                          <option value="Intial" disabled selected hidden>
                            Select
                          </option>

                          <option [disabled]="isSubmit" value="YES">YES</option>
                          <option [disabled]="isSubmit" value="NO">NO</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group pt-2 pb-2 row">
                      <div class="col-sm-3">
                        <label for="entType" class="inputLabel">Is CIN available to the <br />
                          Entity?<b class="errorMsg">*</b>
                        </label>
                      </div>
                      <div class="col-sm-6 z-index-up">
                        <select #cin class="form-select form-control" (change)="cinCheck($event)">
                          <option value="Intial" disabled selected hidden>
                            Select
                          </option>

                          <option [disabled]="isSubmit" value="YES">YES</option>
                          <option [disabled]="isSubmit" value="NO">NO</option>
                        </select>
                      </div>
                    </div>

                    <div class="row form-group">
                      <div *ngIf="isCinChecked == 'YES'">
                        <div class="row">
                          <div class="col-sm-3">
                            <label for="CIN" class="inputLabel">CIN of Entity <b style="color: red">*</b>
                            </label>
                          </div>
                          <div class="col-sm-6 autoCaptilize z-index-up">
                            <input type="text" class="form-control autoCaptilize" formControlName="cin"
                              placeholder="Enter CIN" maxlength="21" id="CIN" appBlockCopyPaste />
                          </div>

                          <!-- <div class="col-sm-3 z-index-up" style="align-self: center">
                            <button type="button" class="btn btn-outline-secondary" (click)="verifyCin()">
                              <i class="bi bi-search"></i>
                            </button>
                          </div> -->
                          <div class="col-sm-8 offset-sm-3 invalid-feedback">
                            Click on Search button to verify CIN (Mandatory)
                          </div>
                        </div>
                        <div class="row pt-2 pb-2">
                          <div class="col-sm-3">
                            <label for="Yoc" class="inputLabel">Year of Incorporation <b class="errorMsg">*</b>
                            </label>
                          </div>
                          <div class="col-sm-6 autoCaptilize z-index-up">
                            <input class="inputField" type="text" class="form-control autoCaptilize"
                              formControlName="Yoc" placeholder="Enter Year of Incorporation" id="Yoc" appBlockCopyPaste />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="isPanChecked == 'YES'" class="row form-group">
                      <div class="row form-group">
                        <div class="col-sm-3">
                          <label for="panNumber" class="inputLabel">PAN of Entity <b style="color: red">*</b>
                          </label>
                        </div>
                        <div class="col-sm-6 autoCaptilize z-index-up">
                          <input
                            type="text"
                            class="form-control autoCaptilize inputField"
                            formControlName="panNumber"
                            placeholder="Enter PAN Number"
                            autocomplete="Off"
                            maxlength="10"
                            id="panNumber"
                            [appOnlyTextNumber]="true"
                            pattern="[a-zA-Z]{3}[cpghfatbljCPGHFATBLJ][A-Za-z]\d{4}[A-Za-z]"
                            appBlockCopyPaste
                          />
                        </div>
                      </div>

                      <div class="row form-group">
                        <div class="col-sm-3">
                          <label for="panName" class="inputLabel">NAME of Entity as per PAN <b style="color: red">*</b>
                          </label>
                        </div>
                        <div class="col-sm-6 autoCaptilize z-index-up">
                          <input
                            type="text"
                            class="form-control autoCaptilize inputField"
                            formControlName="panName"
                            placeholder="Enter Name"
                            autocomplete="Off"
                            maxlength="85"
                            id="panName"
                            appBlockCopyPaste
                          />
                        </div>
                      </div>

                      <div class="row form-group">
                        <div class="col-sm-3">
                          <label for="fathername" class="inputLabel">Father's name as per PAN (For Individual Only)
                          </label>
                        </div>
                        <div class="col-sm-6 autoCaptilize z-index-up">
                          <input
                            type="text"
                            class="form-control autoCaptilize inputField"
                            formControlName="fathername"
                            placeholder="Enter Father's Name"
                            autocomplete="Off"
                            maxlength="75"
                            id="fathername"
                            [appOnlyText]="true"
                            appBlockCopyPaste
                          />
                        </div>
                      </div>

                      <div class="row form-group">
                        <div class="col-sm-3">
                          <label for="dob" class="inputLabel">DOB or Date of Incorporation as per PAN <b style="color: red">*</b>
                          </label>
                        </div>
                        <div class="col-sm-6 autoCaptilize z-index-up">
                          <input
                            type="date"
                            class="form-control inputField"
                            formControlName="dob"
                            placeholder="Select Date"
                            autocomplete="Off"
                            id="dob"
                            [appOnlyTextNumber]="true"
                          />
                        </div>

                        <div class="col-sm-3 z-index-up" style="align-self: center">
                          <button type="button" class="btn btn-outline-secondary" (click)="verifyPan()"
                                  [disabled]="isSubmit">
                            <img src="assets/images/search.svg" alt="">
                          </button>
                        </div>
                        <div class="col-sm-8 offset-sm-3 errorMsg">
                          Click on Search button to verify PAN No. (Mandatory)
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="
                      group == 'GROUP4' &&
                      (selectedRegisterType == 'Project Proponent/User Agency' ||
                        selectedRegisterType == 'Auditors')
                    ">
                    <div class="form-group pt-2 pb-2 row">
                      <div class="col-sm-3">
                        <label for="entType" class="inputLabel">Is PAN available to the Entity?<b class="errorMsg">*</b>
                        </label>
                      </div>
                      <div class="col-sm-6 z-index-up">
                        <select #pan class="form-select form-control" (change)="panCheck($event)">
                          <option [disabled]="isSubmit" value="Intial" disabled selected hidden>
                            Select
                          </option>

                          <option [disabled]="isSubmit" value="YES">YES</option>
                          <option [disabled]="isSubmit" value="NO">NO</option>
                        </select>
                        <div class="errorMsg" *ngIf="isPanChecked == 'NO' && group == 'GROUP4'">
                          <small>“This registration application will be under
                            approval process by PARIVESH ADMIN. It can be either
                            Approved OR Rejected based on applicable rules and
                            regulations”</small>
                        </div>
                      </div>
                    </div>

                    <div class="row form-group">
                      <div *ngIf="isPanChecked == 'YES'" class="row form-group">
                        <div class="row form-group">
                          <div class="col-sm-3">
                            <label for="panNumber" class="inputLabel">PAN of Entity <b style="color: red">*</b>
                            </label>
                          </div>
                          <div class="col-sm-6 autoCaptilize z-index-up">
                            <input
                              type="text"
                              class="form-control autoCaptilize inputField"
                              formControlName="panNumber"
                              placeholder="Enter PAN Number"
                              autocomplete="Off"
                              maxlength="10"
                              id="panNumber"
                              [appOnlyTextNumber]="true"
                              pattern="[a-zA-Z]{3}[cpghfatbljCPGHFATBLJ][A-Za-z]\d{4}[A-Za-z]"
                              appBlockCopyPaste
                            />
                          </div>
                        </div>

                        <div class="row form-group">
                          <div class="col-sm-3">
                            <label for="panName" class="inputLabel">NAME of Entity as per PAN <b style="color: red">*</b>
                            </label>
                          </div>
                          <div class="col-sm-6 autoCaptilize z-index-up">
                            <input
                              type="text"
                              class="form-control autoCaptilize inputField"
                              formControlName="panName"
                              placeholder="Enter Name"
                              autocomplete="Off"
                              maxlength="85"
                              id="panName"
                              appBlockCopyPaste
                            />
                          </div>
                        </div>

                        <div class="row form-group">
                          <div class="col-sm-3">
                            <label for="fathername" class="inputLabel">Father's name as per PAN (For Individual Only)
                            </label>
                          </div>
                          <div class="col-sm-6 autoCaptilize z-index-up">
                            <input
                              type="text"
                              class="form-control autoCaptilize inputField"
                              formControlName="fathername"
                              placeholder="Enter Father's Name"
                              autocomplete="Off"
                              maxlength="75"
                              id="fathername"
                              [appOnlyText]="true"
                              appBlockCopyPaste
                            />
                          </div>
                        </div>

                        <div class="row form-group">
                          <div class="col-sm-3">
                            <label for="dob" class="inputLabel">DOB or Date of Incorporation as per PAN <b style="color: red">*</b>
                            </label>
                          </div>
                          <div class="col-sm-6 autoCaptilize z-index-up">
                            <input
                              type="date"
                              class="form-control inputField"
                              formControlName="dob"
                              placeholder="Select Date"
                              autocomplete="Off"
                              id="dob"
                              [appOnlyTextNumber]="true"
                            />
                          </div>

                          <div class="col-sm-3 z-index-up" style="align-self: center">
                            <button type="button" class="btn btn-outline-secondary" (click)="verifyPan()"
                                    [disabled]="isSubmit">
                              <img src="assets/images/search.svg" alt="">
                            </button>
                          </div>
                          <div class="col-sm-8 offset-sm-3 errorMsg">
                            Click on Search button to verify PAN No. (Mandatory)
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="p-4" class="col-sm-12 row">
                  <div class="col-sm-2 offset-sm-1">
                    <span class="btnCancel" routerLink="/home">Cancel</span>
                  </div>
                  <div class="col-sm-1 offset-sm-5 pl-7">
                    <button type="button" (click)="resetF()" class="btn btncustom z-index-up" style="position: relative"
                      *ngIf="isSubmit">
                      <p class="btntext">Reset</p>
                    </button>
                    &nbsp;&nbsp;

                    <button type="submit" class="btncustom z-index-up flex-end" style="position: relative" *ngIf="
                        !isSubmit &&
                        selectedRegisterType != 'Accredited Consultant Organization'
                      ">
                      <p class="btntext">Proceed</p>
                    </button>
                    <button type="button" class="btncustom z-index-up flex-end" style="position: relative" *ngIf="
                        !isSubmit &&
                        selectedRegisterType === 'Accredited Consultant Organization'
                      " (click)="submitConsultant()">
                      <p class="btntext">Proceed</p>
                    </button>
                  </div>
                  <div class="col-sm-1"></div>
                </div>
              </form>
            </div>
          </div>
        </mat-step>
        <mat-step class="matCol2">
          <ng-template matStepLabel><b>Basic Details</b></ng-template>
          <router-outlet *ngIf="isSubmit" id="scroller"></router-outlet>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
</div>

<ng-template #consultantDublicateModal let-modal>
  <div class="modal-body my-5">
    <div class="text-center">
      <h1 class="h3">Organization Already Exist</h1>
    </div>
    <div class="mt-5">
      <p class="text-center">
        An organization with
        <strong>{{ commonForm.controls["orgId"]?.value }}</strong> is already
        exists in PARIVESH Portal.
      </p>
      <p class="text-center">
        Name of Organization: <strong>{{ organizationDetails.name }}</strong>
      </p>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-between px-5">
    <a class="btn btn-secondary mn-w-100" href="{{ 'baseURLLogin' | env }}">
      {{ "registration.login" | translate }}
    </a>
    <button class="btn btn-primary mn-w-100" (click)="modal.close()">
      {{ "registration.edit_org_id" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #consultantExpiredModal let-modal>
  <div class="modal-body my-5">
    <div class="text-center">
      <h1 class="h3">Organization Validity Expired</h1>
    </div>
    <div class="mt-5">
      <p class="text-center">
        Your accredition validity has been expired, Kindly get that renewed in
        order to proceed.
      </p>
      <p class="text-center">
        Name of Organization: <strong>{{ organizationDetails.name }}</strong>
      </p>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-between px-5">
    <a class="btn btn-secondary mn-w-100" href="https://stgdev.parivesh.nic.in/parivesh-ua-dev/">
      {{ "registration.login" | translate }}
    </a>
    <button class="btn btn-primary mn-w-100" (click)="modal.close()">
      {{ "registration.edit_org_id" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #consultantConfirmationModal let-modal>
  <div class="modal-body my-5">
    <div class="text-center">
      <h1 class="h3">Confirm Your Organization</h1>
    </div>
    <div class="mt-5">
      <div class="row">
        <div class="col-12 col-md-10 mx-auto bg-light p-5">
          <div class="row">
            <div class="col-12 mb-3">
              <div class="row">
                <label class="col-form-label col-5 fw-normal">
                  {{ "registration.org_id" | translate }}:
                </label>
                <div class="col-7 fw-bold">
                  {{ commonForm.controls["orgId"]?.value }}
                </div>
              </div>
            </div>
            <div class="col-12 mb-3">
              <div class="row">
                <label class="col-form-label col-5 fw-normal">
                  {{ "registration.org_name" | translate }} :
                </label>
                <div class="col-7">
                  {{ organizationDetails.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-between px-5">
    <button class="btn btn-secondary mn-w-100" (click)="modal.close()">
      {{ "registration.edit_org_id" | translate }}
    </button>
    <button class="btn btn-primary mn-w-100" (click)="onConsultantConfirmation(); modal.close()">
      {{ "generic.confirm" | translate }}
    </button>
  </div>
</ng-template>
