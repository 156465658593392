export interface ProjectDetails {
  created_on: any;
  updated_on: any;
  created_by: any;
  updated_by: number;
  vers: any;
  id: number;
  project_description: any;
  name: string;
  isProposalExpansion: any;
  sw_no: string;
  total_land: number;
  total_project_cost: number;
  main_state: number;
  state: StateDistrict;
  district: StateDistrict;
  acoProjectStatus: any;
  updatedByUser: UpdatedByUser;
  commonFormDetails: any[];
  proponentApplications: any[];
  main_district: number;
  address: string;
  is_active: boolean;
  project_benefits: any;
  project_benefit_description: any;
}

export interface StateDistrict {
  created_on: Date | any;
  updated_on: Date | any;
  created_by: number | any;
  updated_by: number | any;
  vers: any;
  id: number;
  code: number;
  state_code?: number;
  state?: any;
  name: string;
  census_code_2001: string;
  census_code_2011: string;
  is_active: boolean;
  is_deleted: boolean;
  is_lwe?: boolean;
  version?: number;
  state_abbr?: string;
  state_ut_flag?: string;
  process_enable?: any;
}

export interface UpdatedByUser {
  userId: number;
  name: string;
}

export const ProjectDetailsDashboardComparer = (p1: ProjectDetails, p2: ProjectDetails) => {
  const compare: number = p1.id - p2.id;

  if (compare > 0) {
    return 1;
  } else if (compare < 0) {
    return -1;
  } else return 0;
}
