import {createAction, props} from "@ngrx/store";

export const loadUserDetails = createAction("[User Service] Load User Details");

export const loadUserDetailsSuccess = createAction("[User Service] Load User Details Success", props<{user: any}>());

export const loadRoleByUser = createAction(
  "[Role Service] Get Role By Userid",
  props<{roleData: any}>()
);

export const updateMobileVerifiedFlagSuccess = createAction(
  '[OPT Mobile Verification Popup] Update Mobile Verification Success',
  props<{isNumberVerified: boolean}>()
);
