import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { GenCodeMasterComponent } from "./gen-code-master.component";
import { GenCodeTypesComponent } from "./gen-code-types/gen-code-types.component";

const routes: Routes = [
    {
      path: 'type',
      component: GenCodeTypesComponent,
    
    },
    {
      path: 'code',
      component: GenCodeMasterComponent,
    
    }
  ];
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
  })
  export class genCodeRoutingModule {}
  