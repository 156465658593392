import { Injectable } from "@angular/core";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { AppState } from "../app-state/app-reducer";
import { DashboardActions } from "./dashboard-action-types";
import { exhaustMap, tap } from "rxjs/operators";
import { ProjectService } from "../../services/organisation/project.service";
import { JsDashboardService } from "src/app/services/department/dashboard-js.service";

@Injectable()
export class DashboardEffects {

  constructor(private actions$: Actions, private projectService: ProjectService, private store: Store<AppState>,
    private _jsDashboard: JsDashboardService
  ) {
  }

  loadAllProjects$ = createEffect(() => this.actions$.pipe(
    ofType(DashboardActions.loadAllProjects),
    exhaustMap((action) => this.projectService.getListOfProject(action.page, action.size).pipe(
      tap((response: any) => this.store.dispatch(DashboardActions.loadAllProjectsSuccess({ projects: response?.data?.content })))
    ))
  ), { dispatch: false });

  loadAllSectors$ = createEffect(() => this.actions$.pipe(
    ofType(DashboardActions.loadAllSectors),
    exhaustMap((action) => {
      return this._jsDashboard.getSectorList().pipe(
        tap((response: any) => this.store.dispatch(DashboardActions.loadAllSectorsSuccess({ sectors: response?.data })))
      )
    })
  ), { dispatch: false })

}
