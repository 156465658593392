import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseLayoutComponent } from 'src/app/layouts/base-layout/base-layout.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ConsultantFormComponent } from './consultant-form/consultant-form.component';
import { BasicGuardGuard } from './pp-form/basic-guard.guard';
import { PpFormComponent } from './pp-form/pp-form.component';
import { PpGovtDepartComponent } from './pp-form/pp-govt-depart/pp-govt-depart.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import {ApiGuard} from "../../gaurds/api.gaurd";

const routes: Routes = [
  {
    path:'',
    component: BaseLayoutComponent,
    children : [
      {
        path: 'change-password',  
        component: ChangePasswordComponent,
      }
    ]
  },
  {
    path: 'userRegistration',
    component: BaseLayoutComponent,
    children: [
      {
        path: '',
        component: UserRegistrationComponent,
        children: [
          {
            path: 'projectProponent',
            component: PpFormComponent,
            canActivate: [BasicGuardGuard],
          },
          {
            path: 'projectProponentGovtDepartment',
            component: PpGovtDepartComponent,
          },
          {
            path: 'consultant',
            component: ConsultantFormComponent,
          },
        ],
      },
      {
        path: 'employee',
        canActivate: [ApiGuard],
        loadChildren: () =>
          import(
            'src/app/components/user-registration/employee-registration/employee-registration.module'
            ).then((m) => m.EmployeeRegistrationModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserRoutingModule {}
