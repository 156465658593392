import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { District } from 'src/app/models/master/district.model';
import { State } from 'src/app/models/master/state.model';
import { ConsultantService } from 'src/app/services/consultant.service';
import { MessageService } from 'src/app/services/message.service';
import { RoleService } from 'src/app/services/role.service';
import { UserService } from 'src/app/services/user.service';
import { DATE_FORMATS, ROLE_ABBR, ROLE_IDS } from 'src/app/shared/app-constant';

@Component({
  selector: 'app-consultant-form',
  templateUrl: './consultant-form.component.html',
  styleUrls: ['./consultant-form.component.scss'],
})
export class ConsultantFormComponent implements OnInit, AfterViewInit {
  form: FormGroup = new FormGroup({});
  orgId: string = '';
  isLandline: any;
  districtList: District[] = [];
  selectedDistrictList: District[] = [];
  stateList: State[] = [];
  validationCheck: boolean = false;
  captchaErrorMsg: boolean = false;
  captchatext: any;
  isCaptcha: boolean = false;
  userEnterCaptcha: any = '';
  @ViewChild('myCanvas')
  myCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('captcha')
  captcha!: ElementRef;
  @ViewChild('successModal') successModal: any;
  role: any;
  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private consultantService: ConsultantService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private userService: UserService,
    private modalService: NgbModal,
    private roleService: RoleService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.orgId = params['org_id'];
    });
  }

  ngOnInit(): void {
    this.getConsultant();
    this.createConsultantForm();
    this.captchaErrorMsg = false;
  }

  ngOnDestroy(): void {
    this.form.reset();
  }

  ngAfterViewInit(): void {
    this.captchaErrorMsg = false;
    this.getCaptcha(6);
  }

  getCaptcha(length: any) {
    this.captchatext = '';
    this.captcha.nativeElement.value = '';
    var pen: any = this.myCanvas.nativeElement.getContext('2d');
    var randomChars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    this.captchatext = '';
    for (var i = 0; i < length; i++) {
      this.captchatext += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    pen.font = '18px Roboto ';
    pen.fillStyle = '#000000';

    pen?.fillRect(0, 0, 126, 52);
    pen.fillStyle = '#FFFFFF';
    pen?.fillText(this.captchatext, 20, 32);
  }

  captchaCaptured(captcha: any) {
    this.userEnterCaptcha = captcha.target.value;
  }

  checkCaptcha(usercaptcha: any) {
    if (usercaptcha == this.captchatext) {
      this.userEnterCaptcha = '';
      this.isCaptcha = true;
      this.captchaErrorMsg = false;
    } else {
      this.getCaptcha(6);
      this.captchaErrorMsg = true;
      this.isCaptcha = false;
      this.userEnterCaptcha = '';
    }
  }

  createConsultantForm() {
    this.form = this.fb.group({
      accreditation_no: ['', [Validators.required]],
      name: ['', [Validators.required]],
      organization_head: ['', [Validators.required]],
      designation: ['', [Validators.required]],
      address: ['', [Validators.required]],
      landline_no: ['', []],
      mobile_no: ['', [Validators.required]],
      email_id: ['', [Validators.required]],
      pan: ['', [Validators.required]],
      category: ['', [Validators.required]],
      accreditation_validity: ['', [Validators.required]],
      accreditation_sectors: ['', [Validators.required]],
      website: ['', []],
    });
  }

  getConsultant() {
    this.consultantService
      .saveQCIOrganization(this.orgId, 1)
      .subscribe((res) => {
        this.isLandline = res.data.landline_no;
        this.form.patchValue({
          accreditation_no: res.data.accreditation_No,
          name: res.data.name,
          organization_head: res.data.head,
          designation: res.data.designation,
          address: res.data.address,
          landline_no: res.data.landline_no,
          mobile_no: res.data.mobile_no,
          email_id: res.data.email,
          pan: res.data.pan,
          category: res.data.category,
          accreditation_validity: moment(
            res.data.accreditation_validity
          ).format(DATE_FORMATS.DISPLAY_FORMAT),
          accreditation_sectors: res.data.accreditation_sectors,
        });
      });
  }

  onSubmit(): void {
    this.checkCaptcha(this.userEnterCaptcha);
    if (this.form.invalid || this.isCaptcha == false) {
      this.validationCheck = true;
      this.getCaptcha(6);
      this.messageService.showError(
        this.translateService.instant('generic.required_all')
      );
      return;
    }

    this.consultantService
      .saveQCIOrganization(this.orgId, 2, this.form.value)
      .subscribe(
        (res) => {
          this.openModal();
          this.assignRole(res.data.consultantId);
        },
        (err) => {
          this.messageService.showError(
            this.translateService.instant('generic.error')
          );
        }
      );
  }

  assignRole(userId: number) {
    this.userService
      .assignRole([
        {
          entityId: null,
          role: {
            entityId: ROLE_IDS.CONSULTANT,
          },
          user: {
            entityId: userId,
          },
          workgroup: {
            entityId: 1,
          },
          disableLinkOfficer: false,
        },
      ])
      .subscribe((res) => {});
  }

  openModal() {
    this.modalService.open(this.successModal, { centered: true, size: 'lg' });
  }

  getRoleDetail() {
    this.roleService.getByAbbr(ROLE_ABBR.CONSULTANT).subscribe({
      next: (res) => {
        this.role = res;
      },
      error: () => {},
    });
  }
}
