import { createAction, props } from '@ngrx/store';

export const loadAllStates = createAction('[Shared] Load All States');

export const loadAllStatesSuccess = createAction(
  '[Shared] Load All States Success',
  props<{ states: any[] }>()
);

export const loadAllDistrict = createAction('[Shared] Load All District');

export const loadAllDistrictSuccess = createAction(
  '[Shared] Load All District Success',
  props<{ district: any[] }>()
);

export const loadAllActionsSuccess = createAction(
  '[Proposal Action Component] Load All Actions',
  props<{ actions: any[] }>()
);

export const resetLoadedActions = createAction(
  '[Proposal Action Component] Reset Loaded Actions'
);

export const addProposalByProposalIdNumber = createAction(
  '[Proposal Summary] Add Proposal By Proposal ID',
  props<{ proposal: any }>()
);

export const updateFileNumber = createAction(
  '[Proposal Action component] Update File Number',
  props<{ proposal: any }>()
)
