import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActivationPageComponent } from './activation-page.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [ActivationPageComponent],
  imports: [CommonModule, ReactiveFormsModule],
  providers: [],
  exports: [ActivationPageComponent],
})
export class ActivationPageModule {}
