import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WSO2_TOKEN_EXPIRY_DATETIME_KEY } from '../shared/app-constant';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class Wso2Service {
  constructor(
    private http: HttpClient,
    private sessionStorageService: SessionStorageService
  ) {
  }

  getToken(): Observable<any> {
    return this.http.post(
      environment.wso2URL,
      `grant_type=client_credentials`,
      {
        headers: {
          Authorization: 'Basic ' + environment.wso2Token,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
      }
    );
  }

  public isTokenExpired(): boolean {
    const expiryTime = this.sessionStorageService.get(
      WSO2_TOKEN_EXPIRY_DATETIME_KEY
    );
    const currentTime = new Date().getTime();
    const leftTime = expiryTime - currentTime;

    return leftTime <= 0;
  }
}
