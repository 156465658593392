import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {isEmpty} from 'lodash';
import {KycService} from 'src/app/services/department/kyc.service';
import {LoaderService} from 'src/app/services/loader.service';
import {MessageService} from 'src/app/services/message.service';
import {SessionStorageService} from 'src/app/services/session-storage.service';
import {UserService} from 'src/app/services/user.service';

@Component({
  selector: 'app-update-kyc',
  templateUrl: './update-kyc.component.html',
  styleUrls: ['./update-kyc.component.scss']
})
export class UpdateKycComponent implements OnInit {

  token: string = '';
  email: string = '';
  username: string = '';
  isError: boolean = false;
  check: string = '';
  isSubmit: boolean = false;
  data: any;
  update: any;
  status!: string;
  saveKyc!: string;
  userId!: string;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private router: Router,
    private kycservice: KycService,
    private sessionStorageService: SessionStorageService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.token = params['token'];
      this.email = params['email'];
      this.username = params['username'];
      this.status = params['status'];
      this.saveKyc = params['saveKyc'];
      this.sessionStorageService.set('SpringBoot_token', ('Bearer ' + this.token));
    });
  }

  ngOnInit(): void {
    if (isEmpty(this.status)) {
      this.loaderService.showLoader();
      this.getAadharSignature();
    } else if (!isEmpty(this.status)) {
      this.messageService.showSuccess(
        this.translateService.instant('registration.aadhar_verified')
      );
      this.updateStatus()
      this.router.navigate(['/verification-successfull']);
    } else {
      this.messageService.showError(
        this.translateService.instant('generic.error')
      );
    }
  }

  getAadharSignature() {
    this.userService
      .getAadharSignaturekyc(this.token, this.email, this.username)
      .subscribe({
        next: (response) => {
          this.data = response;

          setTimeout(() => {
            this.loaderService.hideLoader();
            if (isEmpty(this.saveKyc)) {
              let element: any = document.querySelector('#eSignKycForm');
              element.submit();
            } else {
              this.messageService.showSuccess(
                this.translateService.instant('generic.confirmation_sent_on_mail')
              );
              this.router.navigate(['/user/user-profile/kyc-list']);
            }
          }, 1000);

        },
        error: () => {
          this.loaderService.hideLoader();
          this.messageService.showError(
            this.translateService.instant('generic.error')
          );
          this.router.navigate(['/']);
        },
      });
  }

  updateStatus() {
    this.kycservice.updateStatus(this.token).subscribe({
      next: (response) => {
        this.update = response;

        setTimeout(() => {
          this.loaderService.hideLoader();
          this.sessionStorageService.remove('SpringBoot_token');
        }, 1000);


      },
      error: () => {
        this.loaderService.hideLoader();
        this.messageService.showError(
          this.translateService.instant('generic.error')
        );
        this.router.navigate(['/']);
      },
    });
  }

}
