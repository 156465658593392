import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {SharedServiceService} from '../../../services/shared-service.service';
import {MatDialog} from '@angular/material/dialog';
import {ModalComponent} from '../panmodal/modal.component';
import {MatStepper} from '@angular/material/stepper';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NavigationEnd, Router} from '@angular/router';
import {CountryCode} from 'src/app/models/master/country-code.model';
import {GenCode} from 'src/app/models/master/gen-code.model';
import {CommonForm} from 'src/app/models/user/common-form.model';
import {regexMap} from 'src/app/shared/regex&pattern';
import {MessageService} from 'src/app/services/message.service';
import {ConsultantService} from 'src/app/services/consultant.service';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CinModalComponent} from '../cin-modal/cin-modal.component';
import {isEmpty} from "lodash";
import {ValidationUtility} from "../../../utils/validation.utility";

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss'],
})
export class UserRegistrationComponent implements OnInit, OnDestroy {
  @ViewChild(MatStepper) stepper!: MatStepper;
  panNumber: string = '';
  isCinChecked: string = 'Intial';
  CentityType: string = '';
  registerType: GenCode[] = [];
  entityType: string[] = [];
  group2: string[] = [];
  group3: string[] = [];
  group4: string = '';
  group1: string = '';
  panErrorMsg: string = '';
  isSubmit: boolean = false;
  isPanChecked: string = 'Intial';
  entityName: string[] = [];
  group: string = 'INITIAL';
  commonForm: FormGroup = new FormGroup({});
  selectedRegisterType: string = '';
  validationCheck: boolean = false;
  organizationDetails: any;
  @ViewChild('consultantDublicateModal') consultantDublicateModal: any;
  @ViewChild('consultantConfirmationModal') consultantConfirmationModal: any;
  @ViewChild('consultantExpiredModal') consultantExpiredModal: any;
  @ViewChild('pan')
  pan!: ElementRef;
  @ViewChild('cin')
  cin!: ElementRef;

  constructor(
    private fb: FormBuilder,
    private service: SharedServiceService,
    public dialog: MatDialog,
    private route: Router,
    private messageService: MessageService,
    private consultantService: ConsultantService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    public validationUtil: ValidationUtility,
  ) {
  }

  ngOnInit(): void {
    this.route.events.subscribe((res) => {
      if (res instanceof NavigationEnd) {
        if (res.url == '/userRegistration')
          if (this.stepper.selectedIndex) {
            this.stepper.selectedIndex = 0;
          }
      }
    });
    this.panNumber = '';

    this.getRegistrationType();
    this.getEntityGroup();
    this.createCommonForm();
  }

  ngOnDestroy(): void {
    this.commonForm.reset();
  }

  get f() {
    return this.commonForm.controls;
  }

  getEntityGroup() {
    this.service.getEntityType().subscribe((res) => {
      this.entityType = [...res.data.Types];
      this.group2 = [...res.data.Group2];
      this.group3 = [...res.data.Group3];
      this.group1 = res.data.Group1;
      this.group4 = res.data.Group4;
    });
  }

  checkExistInGroup2(value: string) {
    let isPresent = this.group2.indexOf(value);
    if (isPresent != -1) {
      this.group = 'GROUP2';

      this.validationUtil.changeValidation(true, this.commonForm, ['name', 'panNumber', 'panName', 'dob']);

    }
    return isPresent;
  }

  checkExistInGroup3(value: string) {
    let isPresent = this.group3.indexOf(value);
    if (isPresent != -1) {
      this.group = 'GROUP3';
      this.validationUtil.changeValidation(true, this.commonForm, ['panNumber', 'panName', 'dob'], ['name']);
    }

    return isPresent;
  }

  createCommonForm() {
    this.commonForm = this.fb.group({
      registerType: ['', [Validators.required]],
      orgId: ['', []],
      entityType: ['', [Validators.required]],
      panNumber: ['', [Validators.required]],
      panName: ['', [Validators.required]],
      fathername: [null],
      dob: [null, [Validators.required]],
      cin: [''],
      Yoc: [''],
      name: ['', [Validators.required]],
    });
  }


  getRegistrationType() {
    this.service.getRegistrationType().subscribe((res) => {
      this.registerType = res.data;
    });
  }

  cinCheck(result: any) {
    this.isCinChecked = result.target.value;
    if (this.isCinChecked == 'YES') {
      this.commonForm.controls['cin'].setValidators(Validators.required);
      this.commonForm.controls['Yoc'].setValidators(Validators.required);
    } else if (this.isCinChecked == 'NO') {
      this.commonForm.controls['cin'].clearValidators();
      this.commonForm.controls['Yoc'].clearValidators();
    }
    this.commonForm.controls['cin'].updateValueAndValidity();
    this.commonForm.controls['Yoc'].updateValueAndValidity();
  }

  panCheck(result: any) {
    this.isPanChecked = result.target.value;

    this.validationUtil.changeValidation(this.isPanChecked == 'YES', this.commonForm, ['name', 'panNumber', 'panName', 'dob']);
  }

  verifyPan() {

    let entType = this.commonForm.get('entityType')?.value;
    let pan: string = this.commonForm.get('panNumber')?.value;
    const regexIndivid = regexMap.regexIndivid;
    const regexCompany = regexMap.regexCompany;
    const regexGovt = regexMap.regexGovt;
    const regexLLP = regexMap.regexLLP;

    if (isEmpty(pan) || isEmpty(this.commonForm.value.panName) || isEmpty(this.commonForm.value.dob)) {
      this.panErrorMsg = 'Please fill the mandatory fields';
      this.messageService.showError(this.panErrorMsg);
      return true;
    } else if (
      this.group == 'GROUP1' &&
      pan.toUpperCase().match(regexIndivid) == null
    ) {
      this.panErrorMsg = 'Please enter a valid PAN';
      this.messageService.showError(this.panErrorMsg);
      return true;
    } else if (
      this.group == 'GROUP4' &&
      pan.toUpperCase().match(regexCompany) == null
    ) {
      this.panErrorMsg = 'Please enter a valid PAN';
      this.messageService.showError(this.panErrorMsg);
      return true;
    } else if (
      this.group == 'GROUP3' &&
      pan.toUpperCase().match(regexGovt) == null
    ) {
      this.panErrorMsg = 'Please enter a valid PAN';
      this.messageService.showError(this.panErrorMsg);
      return true;
    } else if (
      this.group == 'GROUP2' &&
      this.commonForm.get('entityType')?.value == 'LLP' &&
      pan.toUpperCase().match(regexLLP) == null
    ) {
      this.panErrorMsg = 'Please enter a valid PAN';
      this.messageService.showError(this.panErrorMsg);
      return true;
    } else if (
      this.group == 'GROUP2' &&
      pan.toUpperCase().match(regexCompany) == null
    ) {
      this.panErrorMsg = 'Please enter a valid PAN';
      this.messageService.showError(this.panErrorMsg);
      return true;
    } else {
      this.openDialog();
      return true;
    }
  }

  async openDialog(): Promise<void> {
    const dialogRef = this.dialog.open(ModalComponent, {
      width: '50vw',
      height: '70vh',
      data: {
        panNumber: this.commonForm.get('panNumber')?.value,
        panName: this.commonForm.get('panName')?.value,
        fathername: this.commonForm.get('fathername')?.value,
        dob: this.commonForm.get('dob')?.value,
        entType: this.commonForm.get('entityType')?.value,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.panErrorMsg = '';

      if (result) {

        this.commonForm.patchValue({
          name: result.fullName,
          panNumber: result.panNumber,
        });

      } else {
        this.commonForm.patchValue({
          name: '',
          panNumber: '',
        });
      }
    });
  }

  async openDialogCIN(): Promise<void> {
    const dialogRef = this.dialog.open(CinModalComponent, {
      width: '50vw',
      height: '70vh',
      data: {
        cin: this.commonForm.get('cin')?.value.toUpperCase(),
        entType: this.commonForm.get('entityType')?.value,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.panErrorMsg = '';

      if (result) {
        this.commonForm.patchValue({
          cin: result.cin,
          Yoc: result.year,
          name: result.fullName
        });
      } else {
        this.commonForm.patchValue({
          cin: '',
          Yoc: ''
        });
      }
    });
  }

  verifyCin() {
    let entType = this.commonForm.get('entityType')?.value;
    let cin: string = this.commonForm.get('cin')?.value;
    const regexCin = regexMap.regexCin;
    if (cin == '') {
      this.panErrorMsg = 'Please fill the mandatory fields';
      this.messageService.showError(this.panErrorMsg);
      return;
    } else if (

      cin.toUpperCase().match(regexCin) == null
    ) {
      this.panErrorMsg = 'Please enter a valid CIN';
      this.messageService.showError(this.panErrorMsg);
      return;
    }
    this.openDialogCIN()
  }

  resetF() {
    this.commonForm.enable();
    this.commonForm.patchValue({
      entityType: '',
      panNumber: '',
      cin: '',
      Yoc: '',
      name: '',
    });
    this.isSubmit = false;
    this.group = 'INITIAL';
    this.service.updateCommonDetail(new CommonForm());
  }

  resetForm() {
    this.commonForm.patchValue({
      entityType: '',
      panNumber: '',
      cin: '',
      Yoc: '',
      name: '',
    });
    this.isCinChecked = 'Intial';
    this.isPanChecked = 'Intial';
    this.group = 'INITIAL';
  }

  resetFormOnEntityChange(event: any) {
    this.CentityType = event.target.value;
    if (this.CentityType == '') {
      this.group = 'INITIAL';
    } else if (this.CentityType == this.group1) {
      this.group = 'GROUP1';
    } else if (this.CentityType == this.group4) {
      this.group = 'GROUP4';
    } else {
      this.checkExistInGroup2(this.CentityType);
      this.checkExistInGroup3(this.CentityType);
    }
    if (this.group == 'GROUP3') {
      this.validationUtil.changeValidation(true, this.commonForm, ['name', 'panNumber', 'panName', 'dob', 'cin']);
    } else if (this.group == 'GROUP2') {
      this.validationUtil.changeValidation(true, this.commonForm, ['name', 'panNumber', 'panName', 'dob', 'cin']);
    } else if (this.group == 'GROUP4') {
      this.validationUtil.changeValidation(true, this.commonForm, ['name', 'panNumber', 'panName', 'dob'], ['cin']);
    }

    this.validationUtil.updateValueValidity(this.commonForm);

    this.commonForm.patchValue({
      panNumber: '',
      cin: '',
      Yoc: '',
      name: '',
    });
    this.isCinChecked = 'Intial';
    this.isPanChecked = 'Intial';
    if (this.pan != undefined) {
      this.pan.nativeElement.value = 'Intial';
    }
    if (this.cin != undefined) {
      this.cin.nativeElement.value = 'Intial';
    }
  }

  onSubmit() {

    this.commonForm.controls['name'].setValue(this.commonForm.controls['panName'].value);

    Object.keys(this.commonForm.controls).forEach((key) => {
      if (this.commonForm.controls[key].status === 'INVALID') console.log(key);
    });
    if (this.group == 'GROUP2' && this.isCinChecked == 'Intial') {
      this.messageService.showError('Please fill the mandatory fields');
      return false;
    } else if (
      this.group == 'GROUP3' &&
      (this.isCinChecked == 'Intial' || this.isPanChecked == 'Intial')
    ) {
      this.messageService.showError('Please fill the mandatory fields');
      return false;
    } else if (
      this.group == 'GROUP3' &&
      (this.isCinChecked == 'Intial' || this.isPanChecked == 'Intial')
    ) {
      this.messageService.showError('Please fill the mandatory fields');
      return false;
    }

    if (this.group == 'GROUP4' && this.isPanChecked == 'Intial') {
      this.messageService.showError('Please fill the mandatory fields');
      return false;
    }

    if (this.commonForm.invalid) {
      this.messageService.showError('Please fill the mandatory fields');
      return false;
    }

    this.commonForm.disable();
    localStorage.removeItem('at');
    localStorage.removeItem('ut');
    localStorage.setItem('at', JSON.stringify(this.commonForm.value));
    this.service.updateCommonDetail(this.commonForm.value as CommonForm);
    if (this.group == 'GROUP3') {
      this.route.navigateByUrl(
        'userRegistration/projectProponentGovtDepartment'
      );

      this.stepper.next();
      this.isSubmit = true;
    } else {
      this.route.navigateByUrl('userRegistration/projectProponent');
      this.stepper.next();
      this.isSubmit = true;
    }
    return true;
  }

  submitConsultant() {
    if (this.commonForm.invalid) {
      this.validationCheck = true;
      Object.keys(this.commonForm.controls).forEach((key) => {
        if (this.commonForm.controls[key].status === 'INVALID') console.log(key);
      });
      this.messageService.showError('Please fill the mandatory fields');
      return;
    }

    let orgId = this.commonForm?.value?.orgId;

    this.commonForm.patchValue({orgId: orgId?.toUpperCase()});

    this.consultantService
      .saveQCIOrganization(this.commonForm.value.orgId, 1)
      .subscribe(
        (res) => {
          this.organizationDetails = res.data;
          this.openConsulatntConfirmationModal();
        },
        (err) => {
          if (err.error.status === 409) {
            this.organizationDetails = err.error.data;
            this.openConsulatntDuplicateModal();
          } else if (err.error.status === 406) {
            this.organizationDetails = err.error.data;
            this.openConsulatntExpiredModal();
          } else {
            this.messageService.showError(
              this.translateService.instant(err.error.data || 'generic.error')
            );
          }
        }
      );
  }

  onRegisterEntityChange(event: any) {
      this.validationUtil.changeValidation(event.target.value === 'Accredited Consultant Organization', this.commonForm, ['orgId'], ['entityType', 'name', 'panNumber', 'panName', 'dob']);
  }

  openConsulatntDuplicateModal() {
    this.modalService.open(this.consultantDublicateModal, {
      centered: true,
      size: 'lg',
    });
  }

  openConsulatntConfirmationModal() {
    this.modalService.open(this.consultantConfirmationModal, {
      centered: true,
      size: 'lg',
    });
  }

  openConsulatntExpiredModal() {
    this.modalService.open(this.consultantExpiredModal, {
      centered: true,
      size: 'lg',
    });
  }

  onConsultantConfirmation() {
    this.commonForm.disable();
    localStorage.removeItem('at');
    localStorage.removeItem('ut');
    localStorage.setItem('at', JSON.stringify(this.commonForm.value));
    this.service.updateCommonDetail(this.commonForm.value as CommonForm);
    this.route.navigateByUrl(
      `userRegistration/consultant?org_id=${this.commonForm.value.orgId}`
    );
    this.stepper.next();
    this.isSubmit = true;
  }
}
