import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-verification-successfull',
  templateUrl: './verification-successfull.component.html',
  styleUrls: ['./verification-successfull.component.scss']
})
export class VerificationSuccessfullComponent implements OnInit {

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.router.navigate([`/user/user-profile/kyc-list`]);
    }, 6000);
  }

}
