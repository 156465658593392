export interface DepartmentDashboardProposals {
  application_id: number;
  current_step_id: number;
  district_name: string;
  file_no: string;
  history_start_date: string;
  id: number;
  main_district: number;
  main_state: number;
  office_id: number;
  officename: string;
  other_property: string;
  process_id: number;
  process_step_mapping_id: number;
  project_name: string;
  proponent_name: string;
  proposal_json: string;
  proposal_no: string;
  role_id: number;
  rolename: string;
  start_date: string;
  state_name: string;
  status: string;
  workgroup_id: number;
  workgroup_name: string;
}

export const departmentDashboardCompare = (p1: DepartmentDashboardProposals, p2: DepartmentDashboardProposals) => {
  const compare: number = p1.id - p2.id;

  if (compare > 0) {
    return 1;
  } else if (compare < 0) {
    return -1;
  } else return 0;
}
