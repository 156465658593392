import {
  AUTH_TOKEN_EXPIRED,
  WSO2_TOKEN_EXPIRY_DATETIME_KEY,
  WSO2_TOKEN_EXPIRY_TIME_KEY,
  WSO2_TOKEN_KEY,
  WSO2_TOKEN_WAITING_SINCE_KEY,
} from './shared/app-constant';
import { Component, Injector } from '@angular/core';
import { Observable, fromEvent, mapTo, merge, of } from 'rxjs';

import { AuthService } from './services/auth.service';
import { BroadcastService } from './services/broadcaster.service';
import { LoaderService } from './services/loader.service';
import { Router } from '@angular/router';
import { SessionStorageService } from './services/session-storage.service';
import { Wso2Service } from './services/wso2.service';
import { environment } from 'src/environments/environment';
import { isEmpty } from 'lodash';
import { AiService } from "./services/ai-engine/ai-service";

export let AppInjector: Injector;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  online$: Observable<boolean>;
  onlineStatus!: boolean;
  showDiv!: boolean;
  onLineMessage = '';

  testData: any = {
    "detailsOfProject": {
      "Name of the Project": "Godhna Thermal Power Station 2 X 800 MW",
      "Project Proposal For": "New",
      "Project ID (single Window Number)": "SW/176196/2024",
      "Description of Project": "Karnataka Power Corporation Ltd proposes to install a pit head thermal power plant with capacity of 2 x 800 MW in Godhna Village, Janjgir Champa District, Chhattisgarh State. To establish the thermal power project, KPCL entered an implementation agreement and MoU with the Chhattisgarh State Electricity Board, Government of Chhattisgarh."
    },
    "detailsOfCompany": {
      "Legal Status of the Company/Organization/User Agency": "PSU (eg. State Forest Corp., TANGEDCO, Mineral development Corp)",
      "Name of the Company/Organization/User Agency": "KARNATAKA POWER CORPORATION LIMITED"
    },
    "registeredAddress": {
      "Address": "No.82, Shakthi Bhavan Race Course Road Bangalore",
      "State": "KARNATAKA",
      "District": "BENGALURU URBAN",
      "Pin Code": "560001",
      "E-mail address": "cecdkpcl@gmail.com",
      "Mobile number": "9448290705"
    },
    "applicantDetails": {
      "Name": "A B Sudarshan",
      "Designation": "Chief Engineer"
    },
    "correspondenceAddress": {
      "Address": "No.82, Shakthi Bhavan Race Course Road Bangalore",
      "State": "KARNATAKA",
      "District": "BENGALURU URBAN",
      "Pin Code": "560001",
      "E-mail address": "cecdkpcl@gmail.com",
      "Mobile number": "9448290705"
    },
    "projectLocation": {
      "Upload KML": "Godhna_Boundary_06_04_24_final.kml",
      "Whether the project/activity falling in the state/UT sharing international borders": "NO"
    },
    "locationDetails": [
      {
        "Toposheet No": "F44Q9",
        "State/UT": "CHHATTISGARH",
        "District": "Janjgir - Champa",
        "Sub District": "Nawagarh",
        "Village": "Godhna",
        "Plot/Survey/Khasra No.": ["1", "2", "3", "4", "5", "6"]
      },
      {
        "Toposheet No": "F44Q9",
        "State/UT": "CHHATTISGARH",
        "District": "Janjgir - Champa",
        "Sub District": "Nawagarh",
        "Village": "Kukada",
        "Plot/Survey/Khasra No.": ["719", "720", "721", "722", "724"]
      },
      {
        "Toposheet No": "F44Q9",
        "State/UT": "CHHATTISGARH",
        "District": "Janjgir - Champa",
        "Sub District": "Nawagarh",
        "Village": "Salkhan",
        "Plot/Survey/Khasra No.": ["4641", "4642", "4674"]
      }
    ],
    "totalLandArea": {
      "Unit": "Hectares",
      "Value": 509.9
    },
    "landRequirement": {
      "Nature of Land involved": null,
      "Non-Forest Land": 509.9,
      "Forest Land": 0,
      "Total Land": 509.9
    },
    "projectCost": {
      "Total Cost of the Project at current price level (in Lakhs)": 1300000
    },
    "employmentGenerated": {
      "DuringConstructionPhase": {
        "PermanentEmployment": {
          "NumberOfPermanentEmployment": 200,
          "PeriodOfEmploymentInDays": 1620,
          "NumberOfManDays": 324000
        },
        "TemporaryEmployment": {
          "TemporaryContractualEmploymentInManDays": 1620000,
          "TotalManDays": 1944000
        }

      },
      "DuringOperationalPhase": {
        "PermanentEmployment": {
          "NumberOfPermanentEmployment": 600,
          "PeriodOfEmploymentInDays": 9125,
          "NumberOfManDays": 5475000
        },
        "TemporaryEmployment": {
          "TemporaryContractualEmploymentInManDays": 9125000,
          "TotalManDays": 14600000
        }
      }
    },
    "rehabilitationAndResettlement": {
      "Involved": "YES",
      "NumberOfVillages": 1,
      "NumberOfProjectDisplacedFamilies": 10,
      "NumberOfProjectAffectedFamilies": 1495,
      "StatusOfRehabilitationAndResettlement": "In-Progress"
    },
    "infrastructureShifting": {
      "Required": "YES",
      "Details": {
        "ElectricLine": "A high-tension electric line is passing through the site. This is to be diverted, survey is completed.",
        "IrrigationCanal": "One irrigation canal is passing through the site, which will be diverted with prior permission."
      }
    },
    "alternativeSiteExamination": {
      "Examined": "NO",
      "Reason": "The location of the project meets the siting guidelines for TPP."
    },
    "governmentOrderOrPolicy": {
      "RelevantOrRestricting": "NO"
    },
    "litigationPendingAgainstProjectOrLand": "NO",
    "proposalInvolvesViolationOfActRuleRegulationNotification": "NO",
    "basicInformation": {
      "categoryOfProjectActivity": {
        "multipleItemsComponent": "Yes",
        "majorActivity": {
          "itemNumber": "1(d)",
          "activityType": "Thermal Power Plants",
          "fuelType": "Coal/Lignite based plants",
          "capacityMW": 1600
        },
        "minorActivities": [
          {
            "itemNumber": "1(d)",
            "activityType": "Thermal Power Plants",
            "subActivity": "Coal/Lignite based plants",
            "capacityMW": 800
          },
          {
            "itemNumber": "1(d)",
            "activityType": "Thermal Power Plants",
            "subActivity": "Coal/Lignite based plants",
            "capacityMW": 800
          }
        ]
      },
      "Whether project/activity attracts the General Condition specified in the Schedule of EIA Notification?": "No",
      "proximityToProtectedArea": "n/a",
      "proximityToCriticallyPollutedArea": "n/a",
      "proximityToEcoSensitiveArea": "n/a",
      "proximityToInterStateAndInternationalBoundaries": "n/a",
      "proximityToSeverelyPollutedAreas": "n/a",
      "categoryOfProject": "A",
      "interlinkedInterdependentProjectsOrActivities": {
        "exists": "No",
        "reason": "The proposed project is independent."
      },
      "forestLandInvolved": "No",
      "NBWLRecommendationRequired": "No"
    },
    "projectDetails": {
      "DetailsOfCTE": {
        "ConsentUnderAirAndWaterAct": {
          "Obtained": "No",
          "Reason": "CTE will be applied on receipt of ToR"
        }
      },
      "LocatedInNotifiedIndustrialArea": "No",
      "LocatedInCRZOrICRZArea": "No",
      "ProposedLocationInTerritorialWaters": "No",
      "AttractsSpecificCondition": "No",
      "LocatedInEcoSensitiveZone": "No"
    },
    "productDetails": {
      "DetailsOfProductsAndByProducts": {
        "NameOfProduct": "Electricity",
        "ProductOrByProduct": "Product",
        "QuantityCapacity": 1600,
        "Unit": "Mega Watt (MW)",
        "ModeOfTransportTransmission": "Transmission Line",
        "Remarks": null
      }
    },
    "environmentalSensitiveArea": {
      "ExistsWithin10Km": "No",
      "Note": "Others, interalia, includes areas protected under international conventions/ Area important or sensitive ecological reasons/ Sensitive species of flora or fauna/ Inland or coastal/Tourist places/ Defence installations / Densely populated areas/ Areas containing important, high quality, or scarce resources/ Areas susceptible to natural Hazards"
    },
    "baselineDataStatus": "To be collected",
    "monitoringLocations": {
      "Meteorology": 1,
      "AmbientAirQuality": 11,
      "SurfaceWaterQuality": 11,
      "GroundWaterQuality": 10,
      "GroundWaterLevel": 1,
      "NoiseLevel": 11,
      "SoilQuality": 8
    },
    "additionalDocuments": {
      "BriefSummary": "baseline monitoring.pdf",
      "MapOfMonitoringLocations": "baseline monitoring.pdf"
    },
    "consultantDetails": {
      "QCI_NABETAccredited": "Yes",
      "AccreditationNo_OrganizationId": "ORG000837",
      "NameOfEIAConsultantOrganization": "Samrakshan",
      "Address": "Swastik Manandi Arcade, F-4, 1st Floor, S.C. Road, Sheshadripuram, (Opp. Police Station & above Planet Honda showroom), Bangalore",
      "MobileNo": "9845381819",
      "EmailId": "samrakshanblr@gmail.com",
      "CategoryOfAccreditation": "A",
      "SectorsOfAccreditation": ["4", "21", "22", "25", "32", "32A", "38", "39", "36", "1", "9"],
      "ValidityOfAccreditation": "07/01/2027"
    },
    "projectDetails1": {
      "introductionOfProjectOrActivity": {
        "needForProjectOrActivity": "Cater to the energy requirements in view of future industrial expansion",
        "importanceToCountryRegion": "To meet the power requirement of the State of Karnataka."
      },
      "socialInfrastructure": {
        "existingFacilities": "Facilities like the post office, banks/ATM, markets, Transport Centre, religious temples are existing in Seorinarayan RS town which is at 9 km towards South and District headquarters Janjgir- Champa is at 21 Km towards North.",
        "readilyAvailable": true,
        "proposedToBeDeveloped": "The facilities required for the establishment and operation of Thermal Power Plant will be developed."
      },
      "benefitsOfProject": {
        "socialBenefits": "The project will employ local people, thus increasing their standard of living and helping to strengthen the social infrastructures of the region.",
        "indirectJobOpportunities": "Indirect job opportunities like transportation, provision stores, medical facilities, local markets",
        "jobOpportunityToLocalPeople": "Job opportunity to Local people and the cost of power generation is economical."
      },
      "connectivityToProjectOrActivity": {
        "nearestRailwayStation": {
          "name": "Naila",
          "distanceInKm": 30
        },
        "nearestAirport": {
          "name": "Raipur",
          "distanceInKm": 200
        },
        "nearestTownCityDistrictHeadQuarter": {
          "name": "Shivrinarayan",
          "distanceInKm": 8.5
        }
      },
      "soilClassification": "Mainly Sandy loam and Sandy clay",
      "distanceFromHFLOfRiverInMeters": 9000,
      "projectActivityConstructionStatus": {
        "status": "To be Started",
        "likelyStartDateOfConstructionActivity": "03/03/2025",
        "likelyDateOfCompletionOfConstructionActivity": "02/09/2029"
      }
    },
    "constructionDetails": {
      "useOfResources": {
        "waterRequirement": {
          "constructionStage": {
            "source": "Surface",
            "quantityInKLD": 150,
            "quantityInKLDWithExpansion": 0,
            "methodOfWaterWithdrawal": "Jackwell",
            "distanceFromSourceInMeters": 9000,
            "modeOfTransport": "Pipeline",
            "detailsOfPermission": "Permission of Chhattisgarh Government has been obtained"
          },
          "operationalStage": {
            "source": "Surface",
            "quantityInKLD": 141076,
            "quantityInKLDWithExpansion": 0,
            "methodOfWaterWithdrawal": "Jackwell",
            "distanceFromSourceInMeters": 9000,
            "modeOfTransport": "Pipeline",
            "detailsOfPermission": "Permission of Chhattisgarh Government has been obtained"
          }
        },
        "requirementOfMineralsAndFuels": {
          "coal": {
            "quantityPerAnnumInMT": 7.5,
            "source": "Durgapur - II/ Taraimar and Durgapur - II/ Sarya coal mine",
            "modeOfTransport": "N/A",
            "distanceFromSourceInKm": "N/A",
            "detailsOfLinkageSupplyAgreement": "Coal Blocks have been allotted to KPCL from Ministry of Coal, GoI"
          }
        },
        "constructionMaterial": [
          {
            "material": "Aggregates",
            "quantityInMT": 750000,
            "source": "Local Market",
            "modeOfTransport": "Road",
            "distanceFromSourceInKm": 100
          },
          {
            "material": "Cement",
            "quantityInMT": 300000,
            "source": "Local Market",
            "modeOfTransport": "Road",
            "distanceFromSourceInKm": 100
          },
          {
            "material": "Sand",
            "quantityInMT": 350000,
            "source": "Local Market",
            "modeOfTransport": "Road",
            "distanceFromSourceInKm": 100
          },
          {
            "material": "Steel",
            "quantityInMT": 200000,
            "source": "Local Market",
            "modeOfTransport": "Road",
            "distanceFromSourceInKm": 100
          }
        ],
        "timber": "No",
        "electricPower": {
          "totalElectricityRequirementMW": 25,
          "mainSource": "Chhattisgarh State Electricity Board",
          "renewableEnergyProposedToInstallKW": 1100,
          "percentageContributionOfRenewableEnergy": 0.07,
          "standbyArrangements": "3x2 MVA",
          "stackHeightInMeters": 30,
          "energyConservationMeasures": "LED lights & sensors for lighting, VFD drive"
        },
        "otherNaturalResources": {
          "lime": {
            "quantityTPA": 75000,
            "source": "Nearest quarry will be explored",
            "modeOfTransport": "Road",
            "distanceFromSourceInKm": 100
          }
        },
        "useOfHazardousSubstances": [
          {
            "substanceOrMaterial": "NaOH",
            "hsCode": "2815",
            "quantity": "100 Cum",
            "toxicityLD50MgKg": 500,
            "remarks": "Tentative quantity"
          },
          {
            "substanceOrMaterial": "HCL",
            "hsCode": "280610",
            "quantity": "150 Cum",
            "toxicityLD50MgKg": 238,
            "remarks": "Tentative quantity"
          },
          {
            "substanceOrMaterial": "H2SO4",
            "hsCode": "2870010",
            "quantity": "100 Cum",
            "toxicityLD50MgKg": 2140,
            "remarks": "Tentative quantity"
          },
          {
            "substanceOrMaterial": "NaCIO2",
            "hsCode": "282890",
            "quantity": "70 Cum",
            "toxicityLD50MgKg": 300,
            "remarks": "Tentative quantity"
          }
        ],
        "resourceEfficiencyOptimizationRecyclingReuse": {
          "envisaged": "Yes",
          "details": "Water would be recycled and efforts would be made to optimize the available resources"
        }
      }
    },
    "physicalChanges": {
      "landUseChange": {
        "currentLandUse": [
          { "type": "Forest Land", "areaInHa": 0, "remarks": "n/a" },
          { "type": "Agriculture land", "areaInHa": 509.9, "remarks": "1260 Acres of total land area" },
          { "type": "Grazing Land", "areaInHa": 0, "remarks": "n/a" },
          { "type": "Barren Land", "areaInHa": 0, "remarks": "n/a" },
          { "type": "Waste Land", "areaInHa": 0, "remarks": "n/a" },
          { "type": "Surface water bodies", "areaInHa": 0, "remarks": "n/a" },
          { "type": "Marshy land", "areaInHa": 0, "remarks": "n/a" },
          { "type": "Mangroves", "areaInHa": 0, "remarks": "n/a" },
          { "type": "Settlements", "areaInHa": 0, "remarks": "n/a" },
          { "type": "Roads / Other infrastructure", "areaInHa": 0, "remarks": "n/a" },
          { "type": "Plantation / Green belt", "areaInHa": 0, "remarks": "n/a" },
          { "type": "Industrial use", "areaInHa": 0, "remarks": "n/a" },
          { "type": "Other land use", "areaInHa": null, "remarks": null }
        ],
        "totalAreaInHa": 550,
        "proposedPostProjectLandUse": [
          { "type": "Ash Pond", "areaInHa": 55.03, "remarks": null },
          { "type": "Township", "areaInHa": 17.8, "remarks": null },
          { "type": "Main Plant", "areaInHa": 266.11, "remarks": "Plant Area with green belt" },
          { "type": "Miscellaneous", "areaInHa": 2.66, "remarks": "Miscellaneous" },
          { "type": "Green belt", "areaInHa": 168.3, "remarks": "n/a" }
        ],
        "totalProposedAreaInHa": 509.9
      },
      "vegetationClearance": {
        "permanentOrTemporaryChange": null,
        "treesAffected": 200,
        "areaClearedInHa": null,
        "treesForTransplantation": 50,
        "remarks": "Depending upon the growing status of the plant, some of the plants need to be transplanted, and some of the overgrown species may not be able to be transplanted. For such plants, compensatory planting will be done in the project site and also outside the project site within a radius of the project."
      },
      "lossOfNativeSpecies": "No",
      "demolitionWorks": {
        "involved": "Yes",
        "details": [
          { "type": "Houses", "quantity": 10, "builtUpArea": null, "remarks": "About 10 to 20 Nos. houses in the Government land are to be demolished" }
        ],
        "totalDemolition": { "quantity": 1100 }
      },
      "linearStructuresDiversion": {
        "involved": "Yes",
        "details": [
          { "type": "Transmission line", "lengthInMeters": 3000, "areaCoveredInHa": null, "remarks": "n/a" }
        ]
      },
      "transportRouteInfrastructureChanges": "No",
      "waterBodiesDiversion": {
        "involved": "Yes",
        "details": [
          { "type": "Irrigation canal", "areaForClosureDiversion": "36000 m", "waterCoursesAffected": 1, "lengthForRealignmentDiversion": "3600 m", "remarks": "n/a" }
        ]
      },
      "dismantlingDecommissioningRestoration": "No",
      "temporaryConstructionWorks": {
        "involved": "Yes",
        "details": [
          { "type": "Housing facility", "quantity": 50, "builtUpArea": 2700, "remarks": "Workers will be provided with temporary housing facilities" }
        ],
        "total": { "quantity": 51, "totalBuiltUpAreaInSqM": 5700 }
      },
      "cutAndFillExcavations": {
        "involved": "Yes",
        "cuttingMaterialCuM": 225000,
        "utilizationDisposeOfCuttingMaterial": "Filling up of low lying area",
        "fillingMaterialCuM": 200000,
        "sourceOfFillingMaterial": "Excavated soil in the plant area"
      },
      "undergroundWorks": "No",
      "dredging": "No",
      "offshoreStructures": "No",
      "newTransportInfrastructure": {
        "involved": "Yes",
        "newRoadKm": 0,
        "newRailKm": 130,
        "jettyLengthInMeters": 0,
        "airportsHelipad": 0,
        "otherTransportFacility": "Coal will be transported through railway."
      },
      "constructionOfNewLinearStructures": {
        "involved": "Yes",
        "transmissionLineDivertedKm": 3,
        "pipelineDivertedKm": 0,
        "newTransmissionLineKm": 6.6,
        "newPipelineKm": 0,
        "remarks": "Details will be provided in the EIA report"
      },
      "storageFacilities": {
        "coveredAreaForStorageSqm": 1000,
        "openAreaForStorageSqm": 1000,
        "remarks": "Approximate quantity"
      },
      "permanentHousingForOperationalWorkers": {
        "housingUnits": 100,
        "totalBuiltUpAreaInSqM": 10000,
        "distanceFromProjectSiteInKm": 1,
        "transportFacilities": "Road",
        "remarks": "Number of houses and distance will be changed during implementation of the project"
      },
      "hydrologyChanges": "No",
      "streamCrossings": "No",
      "influxOfPeople": {
        "temporarily": 600,
        "permanently": 1000,
        "remarks": "There will be about 1000 Nos. of permanent employees and 600 Nos.—contractual employees during the operation of the plant."
      },
      "otherInformation": "No"
    },
    "pollutionDetails": {
      "airPollution": {
        "probableAirPollutantsGenerated": "Yes",
        "sources": [
          {
            "source": "Boilers",
            "pollutants": ["PM", "SO2", "NOX"],
            "mitigationMeasures": ["ESP", "FGD", "NOx control"]
          },
          {
            "source": "Material handling",
            "pollutants": ["Fugitive Dust"],
            "mitigationMeasures": ["Water Sprinkler"]
          }
        ]
      },
      "noiseVibration": {
        "generation": "Yes",
        "sourcesOfNoise": ["Boilers", "Coal crushing section", "Compressors", "Turbo generator"],
        "sourcesOfVibration": ["Turbines"],
        "mitigationMeasures": {
          "noise": "Acoustic enclosures/barriers will be provided to reduce noise. Noise generating equipment will be housed separately and regular maintenance will be done.",
          "vibration": "Periodic maintenance"
        }
      },
      "lightHeat": {
        "generation": "Yes",
        "sourcesOfLight": ["Lighting in the Main Plant Area"],
        "sourcesOfHeat": ["Boiler & Steam Pipeline", "Cooling Water System"],
        "mitigationMeasures": {
          "light": "Greenbelt around the project boundary and internal open space",
          "heat": "Cooling water supply in equipment to maintain the required temperature and Greenbelt at project boundary to restrict the heat within the plant site"
        }
      },
      "waterPollution": {
        "probableWaterPollutantsGenerated": "Yes",
        "pollutants": {
          "organic": ["BOD", "O&G from domestic sewage"],
          "inorganic": ["Acid", "Alkali", "COD", "Suspended Solids from plant utility water"],
          "heavyMetals": ["Iron", "Zinc", "Nickel", "Cr", "Pb etc"]
        },
        "sourcesOfWaterPollution": [
          "Effluents from CHP/oil storage areas",
          "Run-off from coal stockyard",
          "Boiler and Cooling tower blowdown",
          "DM plant regeneration waste",
          "Sewage Effluent"
        ],
        "reuseRecycleOfWastewater": {
          "wasteWaterGenerationPerDayKLD": 36000,
          "treatedWaterUsePerDayKLD": 34000,
          "treatedWaterDischargeOutsidePremisesKLD": 0,
          "purposeForTreatedWaterUse": "To achieve the zero liquid discharge concept, it is proposed to completely utilize the power plant effluents after treatment for plant reuse, such as cooling, ash quenching, etc."
        },
        "treatmentFacilities": {
          "onSiteSTPETP": "Yes",
          "wasteWaterTreatment": "100%",
          "typeOfTreatmentPlant": "Both ETP & STP",
          "capacity": {
            "STP": "150 KLD",
            "ETP": "40000 KLD"
          },
          "technology": {
            "STP": "Lamella clarifier/Tube settler with chemical dosing",
            "ETP": "Settling, Neutralization, Oil Removal"
          },
          "adequacyCertified": "No",
          "reasonsForAdequacy": "Adequacy will be worked out during the implementation stage of the plant. STP will be operated to meet the discharge standards prescribed in the Consent order."
        },
        "dualPlumbingSystem": {
          "proposed": "Yes",
          "details": "Separate supply lines for Fresh water & Treated water for use in Gardening, Ash Handling, Dust Suppression"
        },
        "dischargeOfTreatedEffluent": "No"
      }
    },
    "waterRequirements": {
      "groundWaterIntersection": {
        "involved": "No",
        "areaCategory": "Not Safe"
      },
      "rainwaterHarvesting": {
        "proposed": "No",
        "capacityOfFacilitiesKLD": 200,
        "descriptionOfFacilities": "Tanks to store 200 KLD of rainwater from rooftop area within the site",
        "details": "Rainwater harvesting potential and design specifications will be provided in the EIA report",
        "totalWaterRequirementsMetKLD": 200,
        "storageCapacityCubicMeters": 1000
      },
      "otherWaterConservationMeasures": {
        "proposed": "No",
        "details": "Reuse and Recycling of treated wastewater for dust suppression, horticulture and ash Handling"
      },
      "zeroLiquidDischarge": {
        "proposed": "Yes",
        "details": "Reuse and Recycling of treated wastewater for dust suppression, horticulture and ash Handling"
      }
    },
    "greenbelt": {
      "areaProposedInHa": 168.3,
      "widthInMeters": 50,
      "percentageOfTotalArea": 33,
      "speciesProposedForPlantation": "Local species in consultation with forest department",
      "numberOfTreeSaplingsToBePlanted": 420000,
      "fundsAllocatedForPlantationInLakhs": 2100
    },
    "wasteGeneration": {
      "solidWaste": {
        "generation": "Yes",
        "types": [
          {
            "name": "Boiler Bottom ash",
            "source": "Boiler",
            "quantityTPA": 900000,
            "modeOfDisposal": "By scraper conveyor system and transportation in slurry form to ash disposal area or collection",
            "modeOfTransport": "Road"
          },
          {
            "name": "Boiler Fly ash",
            "source": "Boiler",
            "quantityTPA": 2099880,
            "modeOfDisposal": "Evacuation by pneumatic means & transportation from Fly ash silo in closed rail/road tanker for use",
            "modeOfTransport": "Road"
          },
          {
            "name": "Gypsum from FGD",
            "source": "FGD",
            "quantityTPA": 388800,
            "modeOfDisposal": "To cement plant",
            "modeOfTransport": "Road"
          }
        ]
      },
      "plasticWaste": {
        "generation": "Yes",
        "types": [
          {
            "name": "General Plastic Waste",
            "source": "Packaging of raw materials and goods",
            "quantityTPA": 30,
            "modeOfDisposal": "Through authorized agencies",
            "modeOfTransport": "Road"
          }
        ]
      },
      "eWaste": {
        "generation": "Yes",
        "types": [
          {
            "name": "e-waste",
            "source": "Electronic & Electrical equipments",
            "quantityTPA": 2,
            "modeOfDisposal": "Through authorized agencies",
            "modeOfTransport": "Road"
          }
        ]
      },
      "batteryWaste": {
        "generation": "Yes",
        "types": [
          {
            "name": "Used battery",
            "source": "Power Backup",
            "quantityTPA": 2,
            "modeOfDisposal": "Authorized recyclers",
            "modeOfTransport": "Road"
          }
        ]
      },
      "bioMedicalWaste": {
        "generation": "No"
      },
      "hazardousWaste": {
        "generation": "Yes",
        "types": [
          {
            "name": "Used/spent Oil from DG and plant",
            "source": "Used/spent Oil from DG and plant",
            "quantityTPA": 300,
            "modeOfDisposal": "Authorized Recycler",
            "modeOfTransport": "Road"
          }
        ]
      },
      "constructionDemolitionWaste": {
        "generation": "Yes",
        "types": [
          {
            "name": "C&D Waste",
            "source": "Construction work",
            "quantityTPA": 500,
            "modeOfDisposal": "Approximate quantity, reused in filling in low lying area",
            "modeOfTransport": "Road"
          }
        ]
      },
      "otherWastes": {
        "generation": "No"
      },
      "surplusProducts": {
        "generation": "No"
      },
      "wasteMinimizationMeasures": {
        "proposed": "Yes"
      }
    },
    "riskAssessment": {
      "risksFromHazardousSubstances": {
        "involved": "Yes",
        "details": "Probable Hazard areas in the proposed project are the Coal storage and handling area, Fuel oil handling and oil tanks, boilers operations, Turbine Hall, and Chemical storages area"
      },
      "risksFromOtherCauses": "No",
      "naturalDisasters": {
        "couldAffectProject": "No"
      },
      "changesInDiseaseOrVectors": "No",
      "adverseEffectsOnWellbeing": "No",
      "vulnerableGroupsAffected": "No",
      "riskManagementPlan": {
        "proposed": "Yes",
        "details": "Detailed risk assessment studies will be given in the EIA report"
      },
      "impactsOnAdjacentFacilities": "No",
      "consequentialDevelopment": {
        "couldLeadToEnvironmentalEffects": "Yes",
        "details": "Infrastructural development in villages Godhna, Kukda and Salkhan is foreseen. The supporting infrastructure will be developed to facilitate indirect requirements of the Thermal Power Plant viz., roads, power supply, waste or wastewater treatment, Housing development, hospitality industry, Supply industries etc. The post office, banks/ATM, markets, and transport centre exist in Seorinarayan RS town, 9 km towards the South. These facilities will indirectly support the development of the proposal."
      },
      "afterUseImpactOnEnvironment": "No",
      "precedentForLaterDevelopments": {
        "involved": "Yes",
        "details": "The net positive impact of the surrounding area is expected"
      },
      "cumulativeEffects": "No",
      "growthOfAlienSpecies": "No",
      "threatToBiodiversity": "No",
      "obstructionOfViewScenicAmenity": "No",
      "impactOnAnthropologicalArchaeologicalSites": "No",
      "changesToDemographicStructure": "No",
      "adverseEffectOnLocalCommunities": "No"
    },
    "undertaking": {
      "statement": "I hereby give undertaking that the data and information given in the application and enclosures are true to be best of my knowledge and belief and I am aware that if any part of the data and information is found to be false or misleading at any stage, the project will be rejected and clearance given if any to the project will be revoked at our risk and cost. In addition to the above, I hereby give undertaking that no activity/construction/expansion has been taken up.",
      "name": "A B Sudarshan",
      "designation": "Chief Engineer",
      "company": "KARNATAKA POWER CORPORATION LIMITED",
      "address": "No.82, Shakthi Bhavan Race Course Road Bangalore",
      "date": "26/03/2024"
    },
    "Details of Public Consultation": {
      "Whether the Project has been exempted from Public Hearing": "No",
      "Details of Public Hearing": {
        "Venue": "Amadi",
        "Date of advertisement": "09/04/2023",
        "Date of PH": "18/05/2023",
        "State": "CHHATTISGARH",
        "District": "RAIPUR",
        "Village": "Abhanpur",
        "Distance from project boundary (Km)": "100",
        "No. of People attended": "100",
        "Designation of Preceding Officer": "Sub-Divisional Magistrate",
        "Copy of duly signed Proceedings of Public Hearing in English": "pre feasibility report.pdf",
        "No. of written comments / suggestions / objections received": "100",
        "Major issues raised": "1",
        "Response of Project Proponent": "na",
        "Whether addressed in Final EIA/EMP": "No",
        "Reference of Final EIA/EMP": "na",
        "Action Plan on issues raised during public hearing & written submissions as per MoEF&CC OM dated 30/09/2020": "S ' 'S C ° ir ° n P review",
        "PH proceeding Hindi and English": "ph proceeding hindi n english_compressed.pdf",
        "Upload documents if any": "Preview"
      }
    },
    "Baseline Details": {
      "Summary of Baseline Data": {
        "Details of Baseline data collection": {
          "Season": "Winter",
          "Period of collection": {
            "From": "01/12/2020",
            "To": "28/02/2023"
          },
          "Number of monitoring locations": {
            "Meteorology (Nos.)": 1,
            "Ambient Air Quality (Nos.)": 8,
            "Surface Water Quality (Nos.)": 2,
            "Ground Water Quality (Nos.)": 8,
            "Ground Water Level (Nos.)": 8,
            "Noise Level (Nos.)": 8,
            "Soil Quality (Nos.)": 8
          }
        }
      }
    }
  }

  constructor(
    public loaderService: LoaderService,
    public wso2Service: Wso2Service,
    public sessionStorageService: SessionStorageService,
    public authService: AuthService,
    private broadCastService: BroadcastService,
    private router: Router,
    private injector: Injector,
    private aiService: AiService
  ) {
    AppInjector = this.injector;
    this.getWso2Token();

    this.broadCastService.on(AUTH_TOKEN_EXPIRED).subscribe(() => {
      this.refreshAuthToken();
    });

    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );

    this.online$.subscribe((res) => {
      this.onlineStatus = res;
      if (this.onlineStatus) {
        this.onLineMessage = 'welcome back! you are online';
        setTimeout(() => {
          this.showDiv = false;
        }, 5000);
      } else {
        this.onLineMessage =
          'Please check your internet connection, then try again!';
        this.showDiv = true;
      }
    });

    // this.testAiApi();
  }

  testAiApi = () => {
    this.aiService.submitToAi(this.testData, '');
  }

  //Get API Token function
  public getWso2Token() {
    if (environment.wso2) {
      this.wso2Service.getToken().subscribe({
        next: (response) => {
          let currentTime = new Date();
          currentTime.setMinutes(currentTime.getMinutes() + 50);

          this.sessionStorageService.set(
            WSO2_TOKEN_EXPIRY_TIME_KEY,
            response.expires_in - 100
          );

          this.sessionStorageService.set(
            WSO2_TOKEN_EXPIRY_DATETIME_KEY,
            currentTime
          );

          this.sessionStorageService.set(
            WSO2_TOKEN_WAITING_SINCE_KEY,
            '' + new Date().getTime()
          );

          if (isEmpty(this.sessionStorageService.get(WSO2_TOKEN_KEY))) {
            setInterval(() => {
              if (this.wso2Service.isTokenExpired()) {
                this.getWso2Token();
              }
            }, 600000);
            this.router.navigate(['home']);
          }

          this.sessionStorageService.set(
            WSO2_TOKEN_KEY,
            `Bearer ${response.access_token}`
          );
        },
        error: (err) => {
          console.log(err);
        },
      });
    }
  }

  public refreshAuthToken() {
    this.authService
      .refreshToken({
        refreshToken: this.authService.REFRESH_TOKEN,
      })
      .subscribe({
        next: (response) => { },
        error: (err) => {
          console.log(err);
        },
      });
  }
}
