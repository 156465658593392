import {createAction, props} from "@ngrx/store";

export const loadDeiaaSeiaaPending = createAction("[Deiaa Service] Load pending Details",props<{stateId: any,pageNo:any,size:any}>());
//console.log(createAction("[Deiaa Service] Load pending Details",props<{stateId: any,pageNo:any,size:any}>()))
export const deiaaSeiaaPendingSuccess = createAction("[Deiaa Service] Load Pending Details Success", props<{pending: any}>());

export const loadDeiaaSeiaaInvalid = createAction("[Deiaa Service] Load invalid Details",props<{stateId: any,pageNo:any,size:any,status:any}>());
export const deiaaSeiaaInvalidSuccess = createAction("[Deiaa Service] Load invalid Success", props<{invalid: any}>());

export const loadDeiaaSeiaaValid = createAction("[Deiaa Service] Load valid Details",props<{stateId: any,pageNo:any,size:any,status:any}>());
export const deiaaSeiaaValidSuccess = createAction("[Deiaa Service] Load valid Success", props<{valid: any}>());