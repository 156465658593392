import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { MessageService } from 'src/app/services/message.service';
import { UserService } from 'src/app/services/user.service';
import { ValidationUtility } from 'src/app/utils/validation.utility';
import { environment } from 'src/environments/environment';
import {regexMap} from "../../../shared/regex&pattern";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  form: FormGroup = new FormGroup({});
  email!: string;
  isPasswordSame!: boolean;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private userService: UserService,
    private messageService: MessageService,
    public validationUtil: ValidationUtility,
    private translateService: TranslateService,
    private _authService: AuthService
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params && params['email']) this.email = params['email'];
      else this.email = this._authService.AUTH_EMAIL;
    });
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      email: [this.email, [this.validationUtil.requiredValidator()]],
      oldPassword: [null, [this.validationUtil.requiredValidator()]],
      newPassword: [null, [this.validationUtil.requiredValidator()]],
      confirmPassword: [null, [this.validationUtil.requiredValidator()]]
    });
  }

  checkIsPasswordSame() {
    if (
      this.form.controls['newPassword'].value &&
      this.form.controls['confirmPassword'].value
    )
      this.isPasswordSame = !(
        this.form.controls['newPassword'].value ===
        this.form.controls['confirmPassword'].value
      );
  }

  onSubmit(): void {

    if (!this.form.value.newPassword.toString().match(regexMap.regExPassword) || !this.form.value.confirmPassword.toString().match(regexMap.regExPassword)) {
      this.messageService.showError(
        this.translateService.instant('generic.password-string')
      );
      return;
    }

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.messageService.showError(
        this.translateService.instant('generic.required_all')
      );
      return;
    }

    if (this.form.value.oldPassword === this.form.value.newPassword) {
      this.messageService.showError(
        this.translateService.instant('generic.password_same')
      );
      return;
    }

    if (this.isPasswordSame) {
      this.messageService.showError(
        this.translateService.instant('generic.password_mismatch')
      );
      return;
    }

    let action: boolean = confirm(
      this.translateService.instant('change_password.confirmation_msg')
    );

    if (action) {
      let body: any = JSON.parse(JSON.stringify(this.form.value));
      body.oldPassword = btoa(body.oldPassword);
      body.newPassword = btoa(body.newPassword);
      this.userService.changePassword(body).subscribe({
        next: () => {
          this.messageService.showSuccess(
            this.translateService.instant('change_password.success')
          );
          window.location.href = environment.baseURLLogin;
        },
        error: (err) => {
          this.messageService.showError(
            this.translateService.instant(err.message || 'generic.error')
          );
        },
      });
    }
  }
}
