import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable , Subject} from 'rxjs';
import { API } from '../../shared/app-constant';
import { environment } from '../../../environments/environment';
import { GenericResponse } from 'src/app/models/shared/generic-response.model';

@Injectable({
  providedIn: 'root',
})
export class HomeServiceService {
  constructor(private http: HttpClient) {}

  getDate = new Subject<any>();

  getHomeApproval(): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_HOME_APPROVAL;
    const param = new HttpParams().append('active', true);
    return this.http.get<GenericResponse>(url, { params: param });
  }

  getHeroMaster(): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_HOME_HERO;
    const param = new HttpParams().append('active', true);
    return this.http.get<GenericResponse>(url, { params: param });
  }

  getKPIMaster(): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_HOME_KPI;
    const param = new HttpParams().append('active', true);
    return this.http.get<GenericResponse>(url,{ params: param });
  }

  getHomeUpdateMaster(): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_HOME_UPDATES;
    const param = new HttpParams().append('active', true);
    return this.http.get<GenericResponse>(url, { params: param });
  }

  getHomeRecentMaster(): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_HOME_RECENT_EVENTS;
    const param = new HttpParams().append('active', true);
    return this.http.get<GenericResponse>(url, { params: param });
  }

  getKpisMaster(): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_HOME_KPI;
    const param = new HttpParams().append('active', true);
    return this.http.get<GenericResponse>(url, { params: param });
  }

  getBulletinBoardMaster(): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_HOME_BULLITEN_BOARD;
    const param = new HttpParams().append('active', true);
    return this.http.get<GenericResponse>(url, { params: param });
  }

  getUsefulLinksMaster(): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_HOME_USEFUL_LINK;
    const param = new HttpParams().append('active', true);
    return this.http.get<GenericResponse>(url, { params: param });
  }

  getMediaMaster(): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_HOME_MEDIA;
    const param = new HttpParams().append('active', true);
    return this.http.post<GenericResponse>(url, {}, { params: param });
  }
}
