import {ActionReducer, ActionReducerMap, MetaReducer} from "@ngrx/store";
import {sharedReducer, SharedState} from "../shared-state/shared.reducer";
import {authReducer, AuthState} from "../auth-state/auth.reducer";
import {dashboardReducer, DashboardState} from "../dashboard-state/reducers/dashboard.reducer";
import {environment} from "../../../environments/environment";
import {ppDashboardReducer, PPDashboardState} from "../dashboard-state/reducers/pp-dashboard.reducer";
import {deiaaSieaaDashboardReducer, DeiaaSieaaDashboardState } from "../deiaa-seiaa-dashboard-state/deiaa-seiaa-dashboard-reducer";
import { JsDashboardState, jsDashboardReducer } from "../dashboard-state/reducers/js-dashboard.reducer";

export interface AppState {
  user: AuthState;
  departmentDashboardProposals: DashboardState;
  jsDashboard: JsDashboardState;
  shared: SharedState;
  ppDashboard: PPDashboardState;
  deiaaSeiaaDashboard:DeiaaSieaaDashboardState
}

export const reducers: ActionReducerMap<AppState> = {
  user: authReducer,
  departmentDashboardProposals: dashboardReducer,
  jsDashboard: jsDashboardReducer,
  shared: sharedReducer,
  ppDashboard: ppDashboardReducer,
  deiaaSeiaaDashboard:deiaaSieaaDashboardReducer
};

export const logger = (reducer: ActionReducer<any>): ActionReducer<any> => {

  return (state, action) => {
    console.log('state before: ', state);
    console.log('current action: ', action);

    return reducer(state, action);
  }

}

export const metaReducers: MetaReducer<AppState>[] = environment.dev ? [] : [];
