import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[appBlockCopyPaste]'
})
export class BlockCopyPasteDirective {
  constructor() {
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    if (!localStorage.getItem('isPreviewMode'))
      e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    if (!localStorage.getItem('isPreviewMode'))
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    if (!localStorage.getItem('isPreviewMode'))
    e.preventDefault();
  }
}
