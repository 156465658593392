import { createReducer, on } from '@ngrx/store';
import { SharedActions } from './shared-action-types';

export interface SharedState {
  actions: any[];
  states: any[];
  district: any[];
  getCafDetailsByProposalIdNumber: { [key: string]: any };
}

const initialSharedState: SharedState = {
  actions: [],
  states: [],
  district: [],
  getCafDetailsByProposalIdNumber: []
};

export const sharedReducer = createReducer<SharedState>(
  initialSharedState,
  on(SharedActions.loadAllStatesSuccess, (state, action) => ({ ...state, states: action.states })),
  on(SharedActions.loadAllDistrictSuccess, (state, action) => ({ ...state, district: action.district })),
  on(SharedActions.loadAllActionsSuccess, (state, action) => ({ ...state, actions: action.actions })),
  on(SharedActions.resetLoadedActions, (state, action) => ({ ...state, actions: [] })),
  on(SharedActions.updateFileNumber, (state, action) => {
    return {
      ...state,
      getCafDetailsByProposalIdNumber: {
        ...state.getCafDetailsByProposalIdNumber,
        [action?.proposal?.proponentApplications?.id?.toString()]: action.proposal,
        [action?.proposal?.proponentApplications?.proposal_no?.toString()]: action.proposal
      }
    };
  }),
  on(SharedActions.addProposalByProposalIdNumber, (state, action) => {
    if (state.getCafDetailsByProposalIdNumber[action?.proposal?.proponentApplications?.id]) return { ...state };
    if (action?.proposal?.proponentApplications) return {
      ...state,
      getCafDetailsByProposalIdNumber: {
        ...state.getCafDetailsByProposalIdNumber,
        [action?.proposal?.proponentApplications?.id?.toString()]: action.proposal,
        [action?.proposal?.proponentApplications?.proposal_no?.toString()]: action.proposal
      }
    };
    else return { ...state };
  })
);
