import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { API } from '../shared/app-constant';

@Injectable({
  providedIn: 'root',
})
export class VisitorService {
  constructor(private http: HttpClient) {}

  get(): Observable<any> {
    let url = `${environment.baseURL}${API.GET_VISITOR_COUNT}`;
    return this.http.post<any>(url, {});
  }
}
