import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericResponse } from 'src/app/models/shared/generic-response.model';
import { Cin } from 'src/app/models/user/cin.model';
import { API } from 'src/app/shared/app-constant';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CinService {
  constructor(private http: HttpClient) {}
  getCin(cin: string): Observable<Cin> {
    let url = environment.baseURLCin + API.GET_CIN + '?cin_no=' + cin;

    return this.http.post<Cin>(url, '');
  }
  checkDuplicateCin(cin: string, entType: string): Observable<GenericResponse> {
    let url = environment.baseURL + API.DUPLICATE_CIN
    const param = new HttpParams()
      .append('CIN', cin.trim())
      .append('entityType', entType);
    return this.http.get<GenericResponse>(url, { params: param });
  }
}
