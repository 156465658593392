import { UploadFile } from "../shared/upload-file.model";

export class User {
  name: string = '';
  address: string = '';
  email: string = '';
  mobile: string = '';
  state_ut: string = '';
  district: string = '';
  pincode: string = '';
  password: string = '';
  is_active: boolean = true;
  is_delete: boolean = false;
  security_question: string = '';
  security_answer: string = '';
  name_of_Entity: string = '';
  landline_No: number = 0;
  std_Code:number=0;
  pan: string = '';
  website: string = '';
  designation: string = '';
  id: number = 0;
  voter_id?:UploadFile | undefined;
  authority_letter?:UploadFile|undefined;
}
