import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ActivationPageModule } from './components/user-registration/activation-page/activationPage.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BaseFormComponent } from './components/base-form/base-form.component';
import { BreadcrumbModule } from './shared/components/breadcrumb/breadcrumb.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ConfirmationDialogComponent } from './shared/components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './shared/components/confirmation-dialog/confirmation-dialog.service';
import { DataTablesModule } from 'angular-datatables';
import { DialogBoxComponent } from './shared/components/dialog-box/dialog-box.component';
import { ErrorNotFoundComponent } from './components/error-not-found/error-not-found.component';
import { GenCodeModule } from './components/gen-code-master/gen-code.module';
import { HomeLegacyModule } from './components/home-legacy/home-legacy.module';
import { HomeModule } from './components/home/home.module';
import { HttpInterceptorClass } from './interceptors/http.interceptor';
import { InternationalizationModule } from './internationalization/internationalization.module';
import { LayoutsModule } from './layouts/layouts.module';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { LoaderService } from './services/loader.service';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MessageService } from './services/message.service';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UserRegistrationModule } from './components/user-registration/user-registration.module';
import { UndertakingInterceptor } from './interceptors/undertaking.interceptor';
import { ErrorDialogComponent } from './shared/components/error-dialog/error-dialog.component';
import { ErrorDialogService } from './shared/components/error-dialog/error-dialog.service';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { metaReducers, reducers } from './state/app-state/app-reducer';
import { AuthEffects } from './state/auth-state/auth.effects';
import { SharedEffects } from './state/shared-state/shared.effects';
import { DashboardEffects } from './state/dashboard-state/dashboard.effects';
import { DeiaaSeiaaDashboardEffects } from './state/deiaa-seiaa-dashboard-state/deiaa-seiaa-dashboard-effects';
import { LegalNgtFormSubmitSuccessComponent } from './components/legal/legal-ngt-form-submit-success/legal-ngt-form-submit-success.component';
import { LegalSignupComponent } from './components/legal-signup/legal-signup.component';

/**
 * The http loader factory
 * @param {HttpClient} http
 * @returns {TranslateHttpLoader}
 * @constructor
 */
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `..${environment.host}assets/locales/`,
    '.json'
  );
}

@NgModule({
  declarations: [
    AppComponent,
    ErrorNotFoundComponent,
    ConfirmationDialogComponent,
    BaseFormComponent,
    DialogBoxComponent,
    ErrorDialogComponent,
  ],
  imports: [
    BrowserModule,
    HomeModule,
    HomeLegacyModule,
    UserRegistrationModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ActivationPageModule,
    MatToolbarModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    GenCodeModule,
    NgbModule,
    LayoutsModule,
    DataTablesModule,
    ToastrModule.forRoot({
      enableHtml: true,
      preventDuplicates: true,
      closeButton: true,
    }),
    InternationalizationModule.forRoot({ locale_id: 'en-US' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MatTooltipModule,
    BreadcrumbModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictActionSerializability: false,
        strictStateSerializability: false,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([
      AuthEffects,
      SharedEffects,
      DashboardEffects,
      DeiaaSeiaaDashboardEffects,
    ]),
  ],
  providers: [
    ConfirmationDialogService,
    ErrorDialogService,
    LoaderService,
    MessageService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorClass, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UndertakingInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [ConfirmationDialogComponent, ErrorDialogComponent],
})
export class AppModule {}
