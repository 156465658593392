import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AI_APIS, API } from 'src/app/shared/app-constant';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AiService {
  constructor(private http: HttpClient) { }

  getDataFromAi = (proposal_no: any): Observable<any> => this.http.get(`${environment.baseURLProcessEngine}${API.EDQ_QUERIES}`, { params: { proposalNumber: proposal_no } });

  getComplianceSummary = (): Observable<any> => this.http.get('assets/json/ai/complianceSummary.json');

  submitToAi = (input_Str: any, proposalNumber: string) => this.http.post(environment.baseUrlAi + AI_APIS.SAVE_TO_AI, { input_Str }).subscribe(response => this.saveToPariveshAi({ id: 0, is_active: true, is_deleted: false, proposalNumber, responseText: JSON.stringify(response) }));

  saveToPariveshAi = (data: { id: 0, proposalNumber: string, responseText: string, is_active: true, is_deleted: false }) => this.http.post(environment.baseURLProcessEngine + AI_APIS.SAVE_TO_PARIVESH_AI, data).subscribe();
}
