import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from 'src/app/layouts/main-layout/main-layout.component';
import { environment } from 'src/environments/environment';
import { HomeLegacyComponent } from '../home-legacy/home-legacy.component';
import { HomeComponent } from './home.component';

const routesProd: Routes = [{ path: '', component: HomeLegacyComponent }];
const routesDev: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [{ path: '', component: HomeComponent }],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(environment.production ? routesProd : routesDev),
  ],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
