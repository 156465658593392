import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[OnlyAlphabetAndSpace]',
})
export class OnlyAlphabetAndSpaceDirective {
  @Input()
  OnlyAlphabetAndSpace!: boolean;

  pattern!: string;

  private regexMap = {
    // add your own

    words: '([a-zA-Z\\s])',

  };

  constructor(public el: ElementRef) {
  }

  @HostListener('keypress', ['$event']) onKeyDown($event: any) {
    let e = <KeyboardEvent>event;
    if (this.OnlyAlphabetAndSpace) {
      this.pattern = this.regexMap.words;
      const inputChar = e.key;


      if (inputChar.match(this.pattern) != null) {
        // success
        return;
      } else {
        //do something her to indicate invalid character

        if (!localStorage.getItem('isPreviewMode'))
          e.preventDefault();
      }
    }
  }
}
