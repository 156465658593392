import {createFeatureSelector, createSelector, MemoizedSelector, Store} from "@ngrx/store";
import {AppState} from "../app-state/app-reducer";
import {AuthState} from "./auth.reducer";
import {AuthActions} from "./auth-action-types";

const selectAuthState: MemoizedSelector<AppState, AuthState> = createFeatureSelector('user');

export const selectLoggedInUser = (store: Store) => createSelector(
  selectAuthState,
  authState => {
    if (authState.isLoaded) return authState.user;
    else return store.dispatch(AuthActions.loadUserDetails())
  }
);

export const isLoggedIn = createSelector(
  selectAuthState,
  authState => authState.isLoaded
);

export const selectSelectedRole = createSelector(
  selectAuthState,
  authState => authState.user?.selected_role
);

export const selectRoleData = createSelector(
  selectAuthState,
  authState => authState.userRoleData
);

export const selectRoleName = createSelector(
  selectAuthState,
  authState => ({
    data: authState?.user?.selectedRoleDto || {
      designation: undefined,
      jurisdiction: undefined,
      role: undefined
    }
  })
);
