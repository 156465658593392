import { Pipe, PipeTransform } from '@angular/core';

import { ToWords } from 'to-words';

@Pipe({
  name: 'amountinwords',
})
export class AmountinwordsPipe implements PipeTransform {
  transform(
    value: string,
    isCrore: boolean = false,
    isLakhs: boolean = false,
    isLakhsWord: boolean = false
  ): any {
    const toWords = new ToWords({
      localeCode: 'en-IN',
      converterOptions: {
        currency: true,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
        doNotAddOnly: false,
        currencyOptions: {
          // can be used to override defaults for the selected locale
          name: 'Rupee',
          plural: 'Rupees',
          symbol: '₹',
          fractionalUnit: {
            name: 'Paisa',
            plural: 'Paise',
            symbol: '',
          },
        },
      },
    });

    if (!+value) {
      return ('Zero Only');
    }

    return (isLakhs
      ? toWords.convert(+value, { currency: false }) +
          (isLakhsWord ? ' Lakh(s) Only' : ' Only')
      : (isCrore
      ? toWords.convert(+value, { currency: false }) + ' Crore(s) Only'
      : toWords.convert(+value, { currency: true })));
  }
}
