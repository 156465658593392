import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { DirectivesModule } from '../../../../directives/directives.module';
import { InputComponent } from './input.component';
import { MatIconModule } from '@angular/material/icon';
import {MatTooltipModule} from "@angular/material/tooltip";
import { NgModule } from '@angular/core';
import {PipeModule} from "../../../pipes/pipe.module";
import { TranslateModule } from '@ngx-translate/core';
import {MatAutocompleteModule} from "@angular/material/autocomplete";

@NgModule({
  declarations: [InputComponent],
  exports: [InputComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        DirectivesModule,
        MatTooltipModule,
        PipeModule,
        MatIconModule,
        MatAutocompleteModule
    ],
})
export class InputModule {}
