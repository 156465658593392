import {Directive, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appOnlyTextNumber]'
})
export class OnlyTextNumberDirective {

  @Input('appOnlyTextNumber') onlyTextNumber!: boolean;

  constructor() {
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: any) {

    if (event.shiftKey) {
      return (event.keyCode >= 65 && event.keyCode <= 90) || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 32 || event.keyCode == 37 || event.keyCode == 38 || event.keyCode == 39 || event.keyCode == 40 || event.keyCode == 46;
    } else {
      return (event.keyCode >= 65 && event.keyCode <= 90) || (event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 96 && event.keyCode <= 105) || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 32 || event.keyCode == 37 || event.keyCode == 38 || event.keyCode == 39 || event.keyCode == 40 || event.keyCode == 46;
    }
  }

}
