import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

interface DialogData {
  entityType: string;
  isScrutiny:boolean;
}
@Component({
  selector: 'app-thank-you-page',
  templateUrl: './thank-you-page.component.html',
  styleUrls: ['./thank-you-page.component.scss'],
})
export class ThankYouPageComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ThankYouPageComponent>,
    private route: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {

  }
  onNoClick(): void {
    this.route.navigate(['home']);
    this.dialogRef.close();
  }
}
