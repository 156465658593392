import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class LoaderService {
  public isLoading = new BehaviorSubject<Boolean>(false);

  constructor() {}

  // show loader
  showLoader() {
    this.isLoading.next(true);
  }

  //hide loader
  hideLoader() {
    this.isLoading.next(false);
  }
}
