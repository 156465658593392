<div class="container-fluid">
  <div>
    <div class="modal-content" style="text-align: center">
      <!-- Modal Header -->
      <div class="modal-header">
        <h4
          class="modal-title"
          style="font-size: 30px; text-align: center; width: 100%"
        >
          Registration Successful
        </h4>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <br /><br />
        Your details have been successfully submitted for Registration as
        <b>{{ data.entityType }}</b
        > Project Proponent<br />
        <div class="alert alert-primary" role="alert" *ngIf="!data.isScrutiny">
          An Email has been sent to your registered email id <br />
          with <strong>Login credential and Activation link</strong>
        </div>
        <div class="alert alert-primary" role="alert" *ngIf="data.isScrutiny">
        This application is under Scrutiny process,
          Once approved<br /> email will be sent to registered email id<br> with  <strong>Login credential and Activation link</strong>
        </div>
        <br /><br /><br /><br />
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="onNoClick()">
          Done
        </button>
      </div>
    </div>
  </div>
</div>
