import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {EcPartBBasicDetail} from 'src/app/models/environment-clearance-part-B/basic-details.model';
import {ConstructionDetail} from 'src/app/models/environment-clearance-part-B/construction-detail.model';
import {EcResource} from 'src/app/models/environment-clearance-part-B/resource.model';
import {IndustrialProposal} from 'src/app/models/environment-clearance-part-B/industrial-proposal.model';
import {RiverValley} from 'src/app/models/environment-clearance-part-B/river-valley.model';
import {WasteDetails} from 'src/app/models/environment-clearance-part-B/waste-details.model';
import {WasteProduction} from 'src/app/models/environment-clearance-part-B/waste-production.model';
import {GenericResponse} from 'src/app/models/shared/generic-response.model';
import {API} from 'src/app/shared/app-constant';
import {environment} from 'src/environments/environment';

import {AirportProposal} from 'src/app/models/environment-clearance-part-B/airport-proposal.model';

import {MiningProposal} from 'src/app/models/environment-clearance-part-B/mining-proposal.model';
import {PollutionDetails} from 'src/app/models/environment-clearance-part-B/pollution-details.model';

import {BuildingConstruction} from '../../models/building-construction.model';
import {
  PhysicalChanges,
  PhysicalChangesRelationalData,
} from '../../models/environment-clearance-part-B/physical-changes.model';
import {RiskFactor} from 'src/app/models/environment-clearance-part-B/RiskFactor.model';
import {GreenBelt} from 'src/app/models/environment-clearance-part-B/green-belt.model';
import {TsdfProposal} from 'src/app/models/environment-clearance-part-B/tsdf-proposal.model';
import {CbwtfProposal} from 'src/app/models/environment-clearance-part-B/cbwtf-proposal.model';
import {CetpProposal} from 'src/app/models/environment-clearance-part-B/cetp-proposal.model';
import {CmswmfProposal} from 'src/app/models/environment-clearance-part-B/cmswmf-proposal.model';
import {WaterDetails} from '../../models/environment-clearance-part-B/water-details.model';
import {AreaSubmerge} from 'src/app/models/environment-clearance-part-B/area-submerged.model';
import {CroppingPattern} from 'src/app/models/environment-clearance-part-B/cropping-patten.model';
import {IrrigationCapacityVillage} from 'src/app/models/environment-clearance-part-B/irrigation-project-capacity.model';
import {AirPollutionMitigation} from 'src/app/models/environment-clearance-part-B/air-pollution-mitigation.model';

@Injectable({
  providedIn: 'root',
})
export class EnvionmentClearancePartBService {
  constructor(private http: HttpClient) {
  }

  saveBasicDetails(
    body: EcPartBBasicDetail,
    ec_id: any
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_EC_PARTB;
    let param = new HttpParams().append('ec_id', ec_id);
    return this.http.post<any>(url, body, {params: param});
  }

  saveWasteDetails(
    body: WasteDetails[],
    ecpartB_id: any
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_EC_WASTE_DETAILS;
    let param = new HttpParams().append('ecPartBId', ecpartB_id);
    return this.http.post<any>(url, body, {params: param});
  }

  saveEcWasteProduction(
    body: WasteProduction,
    id: any
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_EC_WASTE_PRODUCTION;
    let param = new HttpParams().append('ec_partb_id', id);
    return this.http.post<any>(url, body, {params: param});
  }

  saveIndustrialProposalDetails(body: IndustrialProposal, id: any) {
    let url = environment.baseURL + API.SAVE_INDUSTRIAL_PROPOSAL;
    let param = new HttpParams().append('ec_partb_id', id);
    return this.http.post<any>(url, body, {params: param});
  }

  deleteWasteDetails(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_EC_WASTE_DETAILS;
    let param = new HttpParams().append('ecWasteDetailId', id);
    return this.http.post<any>(url, null, {params: param});
  }

  getECBDetail(ecbId: any, url?: string): Observable<GenericResponse> {
    let param = new HttpParams().append(url ? 'ec_id' : 'ec_partb_id', ecbId);
    return this.http.post<GenericResponse>(
      url
        ? environment.baseURL + url
        : environment.baseURL + API.GET_ECB_DETAILS,
      {},
      {params: param}
    );
  }

  saveWaterDetails(body: WaterDetails, ecId: any) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API.SAVE_WATER_DETAILS,
      body,
      {
        params: {ec_partb_id: ecId},
      }
    );
  }

  saveResource(body: EcResource[], ecbId: any) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API.SAVE_RESOURCE_DETAILS,
      body,
      {
        params: {ecPartBId: ecbId},
      }
    );
  }

  deleteResourceDetails(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_RESOURCE_DETAILS;
    let param = new HttpParams().append('EcResourceId', id);
    return this.http.post<any>(url, {}, {params: param});
  }

  saveBuildingConstruction(body: BuildingConstruction, ecId: any) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API.SAVE_BUILDING_CONSTRUCTION,
      body,
      {
        params: {ec_partb_id: ecId},
      }
    );
  }

  saveDeletePhysicalChangesAddRow(
    isSave: boolean,
    endPoint: string,
    body: PhysicalChangesRelationalData,
    id: any
  ) {
    if (isSave)
      return this.http.post<GenericResponse>(
        `${environment.baseURL}${endPoint}`,
        body,
        {params: {ecPhysicalChangesId: id}}
      );
    else
      return this.http.post<GenericResponse>(
        `${environment.baseURL}${endPoint}`,
        {},
        {params: {id: id}}
      );
  }

  savePhysicalChangesDetails(body: PhysicalChanges, id: any) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API.SAVE_PHYSICAL_CHANGES,
      body,
      {
        params: {ecPartBId: id},
      }
    );
  }

  savePollutionDetails(body: PollutionDetails, ecId: any) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API.SAVE_POLLUTION_DETAILS,
      body,
      {
        params: {ecPartBId: ecId},
      }
    );
  }

  saveAirPollutionMitigation(body: AirPollutionMitigation[], ecId: any) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API.SAVE_AIR_POLLUTION_MITIGATION,
      body,
      {
        params: {ecPartBId: ecId},
      }
    );
  }

  saveAirportProposal(body: AirportProposal, ecId: number) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API.SAVE_AIRPORT_PROPOSAL,
      body,
      {
        params: {ecPartBId: ecId},
      }
    );
  }

  saveMiningProposal(body: MiningProposal, ecId: number) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API.SAVE_MINING_PROPOSAL,
      body,
      {
        params: {ec_partb_id: ecId},
      }
    );
  }

  deleteECPollutionDetails(ec_id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_EC_ACTIVITY_DETAIL;
    let param = new HttpParams().append('id', ec_id);
    return this.http.post<any>(url, null, {params: param});
  }

  deleteAirPollutionMitigation(ec_id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_EC_AIR_POLLUTION_MITIGATION;
    let param = new HttpParams().append('ecMitigationId', ec_id);
    return this.http.post<any>(url, null, {params: param});
  }

  deleteMiningMineralsMined(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_EC_MINING_MINERALS_MINED;
    let param = new HttpParams().append('id', id);
    return this.http.post<any>(url, null, {params: param});
  }

  deleteMiningMineralsProductionDetails(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_EC_MINING_PRODUCTION_DETAIL;
    let param = new HttpParams().append('id', id);
    return this.http.post<any>(url, null, {params: param});
  }

  deleteMiningMineralsReserve(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_EC_MINING_MINERALS_RESERVES;
    let param = new HttpParams().append('id', id);
    return this.http.post<any>(url, null, {params: param});
  }

  saveConstructionDetail(
    body: ConstructionDetail,
    ecpartB_id: any
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_CONSTRUCTION_DETAILS;
    let param = new HttpParams().append('ec_partb_id', parseInt(ecpartB_id));
    return this.http.post<any>(url, body, {params: param});
  }

  saveRiverValleyProject(body: RiverValley, ecid: number) {
    let url = environment.baseURL + API.SAVE_RIVER_VALLEY;
    let param = new HttpParams().append('ecPartBId', ecid);
    return this.http.post<any>(url, body, {params: param});
  }

  saveRiskFactor(body: RiskFactor, ecId: any) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API.SAVE_RISK_FACTOR,
      body,
      {
        params: {ec_partb_id: ecId},
      }
    );
  }

  getFormDetails(id: any) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API.GET_EC_DETAILS,
      {},
      {
        params: {ec_id: id},
      }
    );
  }

  saveGreenBelt(body: GreenBelt, ecId: any) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API.SAVE_GREEN_BELT,
      body,
      {
        params: {ecPartBId: ecId},
      }
    );
  }

  saveTsdfProposal(body: TsdfProposal, ecId: number) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API.SAVE_TSDF_PROPOSAL,
      body,
      {
        params: {ecPartBId: ecId},
      }
    );
  }

  saveCbwtfProposal(body: CbwtfProposal, ecId: number) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API.SAVE_CBWTF_PROPOSAL,
      body,
      {
        params: {ecPartBId: ecId},
      }
    );
  }

  saveCetpProposal(body: CetpProposal, ecId: number) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API.SAVE_CETP_PROPOSAL,
      body,
      {
        params: {ecPartBId: ecId},
      }
    );
  }

  saveCmswmfProposal(body: CmswmfProposal, ecId: number) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API.SAVE_CMSWMF_PROPOSAL,
      body,
      {
        params: {ecPartBId: ecId},
      }
    );
  }

  saveEcAreaSubMerged(
    body: AreaSubmerge[],
    id: any
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_EC_AREA_SUBMERGED;
    let param = new HttpParams().append('ecRiverValleyId', id);
    return this.http.post<any>(url, body, {params: param});
  }

  deleteEcAreaSubmerged(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_EC_AREA_SUBMERGED;
    let param = new HttpParams().append('id', id);
    return this.http.post<any>(url, null, {params: param});
  }

  saveEcIrrigationVillage(
    body: IrrigationCapacityVillage[],
    id: any
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_EC_IRRIGATABLE_VILLAGE;
    let param = new HttpParams().append('ecRiverValleyId', id);
    return this.http.post<any>(url, body, {params: param});
  }

  deleteEcIrrigationVillage(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_EC_IRRIGATABLE_VILLAGE;
    let param = new HttpParams().append('id', id);
    return this.http.post<any>(url, null, {params: param});
  }

  saveEcCroppingPattern(
    body: CroppingPattern[],
    id: any
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_EC_CROPPING_PATTERN;
    let param = new HttpParams().append('ecRiverValleyId', id);
    return this.http.post<any>(url, body, {params: param});
  }

  deleteEcCroppingPattern(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_EC_CROPPING_PATTERN;
    let param = new HttpParams().append('id', id);
    return this.http.post<any>(url, null, {params: param});
  }

  getApplicationName(clearence: string) : Observable<GenericResponse> {
    let url = environment.baseURL + API.APPLICATION_NAME;
    let param = new HttpParams().append('Id', clearence);
    return this.http.post<any>(url, {}, { params: param });
  }
}
