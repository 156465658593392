import {createReducer, on} from "@ngrx/store";
import {AuthActions} from "./auth-action-types";

export interface AuthState {
  isLoaded: boolean;
  user: any;
  userRoleData: any;
}

const initialAuthState: AuthState = {
  isLoaded: false,
  user: undefined,
  userRoleData: undefined
}

export const authReducer = createReducer<AuthState>(
  initialAuthState,
  on(AuthActions.loadUserDetailsSuccess, (state: AuthState, action) => ({...state, user: action.user, isLoaded: true})),
  on(AuthActions.loadRoleByUser, (state: AuthState, action) => ({...state, userRoleData: action.roleData})),
  on(AuthActions.updateMobileVerifiedFlagSuccess, (state: AuthState, action) => (
    {
      ...state,
      user : {
        ...state.user,
        isNumberVerified: action.isNumberVerified
      }
    }
  )));
