import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[appOnlynumber]',
})
export class OnlynumberDirective implements OnInit {
  @Input() appOnlynumber!: boolean;
  @Input() isMinusAllowed!: boolean;
  @Input() isSpecialCharacterAllowed!: boolean;
  private regEx!: RegExp;

  numbers: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

  specialCharacters: string[] = [
    '.', ',', ':', ';', '\'', '"',
    '/', '-', '(', ')', '[', ']',
    '{', '}', '&', '\\', '|', '+'
  ];

  keyboardKeys: string[] = [
    'Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp',
    'ArrowDown', 'Delete', 'Alt', 'Tab', 'Enter',
    'Home'
  ];

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    if (this.isMinusAllowed) this.regEx = /^(\-)?(\d)?(\d+)$/;
  }

  @HostListener('keydown', ['$event']) onKeyDown($event: any) {
    let e = <KeyboardEvent>event;

    let value!: any;

    if (this.isMinusAllowed) value = this.el.nativeElement?.querySelector('.form-control')?.value;
    if (this.isSpecialCharacterAllowed) {
      if (this.numbers.includes(e.key) || this.specialCharacters.includes(e.key) || this.keyboardKeys.includes(e.key)) {
        // e.preventDefault();
        // return;
      } else e.preventDefault()
    }

    if (this.appOnlynumber && !this.isSpecialCharacterAllowed) {
      if (e.key === '.') e.preventDefault();
      if (
        [46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+V
        (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)
      ) {
        // let it happen, don't do anything
        return;
      }

      if (this.isMinusAllowed && !value.includes('-') && !value && (e.key === '-' || e.key === '0' || e.key === '1' || e.key === '2' || e.key === '3' || e.key === '4' || e.key === '5' || e.key === '6' || e.key === '7' || e.key === '8' || e.key === '9')) {
        return;
      }

      // Ensure that it is a number and stop the keypress
      if (
        ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105))
      ) {
        if (!localStorage.getItem('isPreviewMode'))
          e.preventDefault();
      }
    }
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    if (!localStorage.getItem('isPreviewMode'))
      e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    if (!localStorage.getItem('isPreviewMode'))
      e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    if (!localStorage.getItem('isPreviewMode'))
      e.preventDefault();
  }
}

