<div class="content-wrapper p-4">
  <h1 class="h4 form-heading text-center">
    {{ "change_password.change_password" | translate }}
  </h1>
  <!-- Main content -->
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card form-card mb-4 bg-white">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 mb-3">
                <app-input
                  id="email"
                  type="email"
                  label="Email"
                  placeholder="Enter your email"
                  [control]="form.get('email')"
                  [formGroup]="form"
                  [readonly]="true"
                ></app-input>
              </div>
              <div class="col-md-12 mb-3">
                <app-input
                  id="password"
                  type="password"
                  label="Old Password"
                  [control]="form.get('oldPassword')"
                  [placeholder]="'Old Password'"
                  [formGroup]="form"
                ></app-input>
              </div>
              <div class="col-md-12 mb-3">
                <app-input
                  id="newPassword"
                  type="password"
                  label="New Password"
                  [control]="form.get('newPassword')"
                  [placeholder]="'New Password'"
                  [formGroup]="form"
                  (input)="checkIsPasswordSame()"
                ></app-input>
              </div>
              <div class="col-md-12 mb-3">
                <app-input
                  id="confirmPassword"
                  type="password"
                  label="Confirm Password"
                  [control]="form.get('confirmPassword')"
                  [placeholder]="'Confirm Password'"
                  [formGroup]="form"
                  (input)="checkIsPasswordSame()"
                ></app-input>
                <div class="row">
                  <div class="col-5"></div>
                  <div class="col-7 mt-3"
                       *ngIf="isPasswordSame"
                  >
                    <label class="error-msg">{{ "generic.password_mismatch" | translate }}</label>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mb-3">
                <button class="btn btn-primary w-100">
                  {{ "generic.submit" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <!-- /.content -->
</div>
