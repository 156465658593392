<div
  class="container-fluid align-item-center pt-4"
  *ngIf="statusCode == 'intial'"
>
  <div class="row">
    <div class="col-md-8 offset-md-2 text-center pt-10">
      <h3>Verifying CIN Details..</h3>
    </div>
  </div>
</div>

<div
  class="container-fluid align-item-center pt-4"
  *ngIf="statusCode != 'intial'"
>
  <div class="row" *ngIf="isDuplicate == 'intial'">
    <div class="col-md-8 offset-md-2 text-center pt-10">
      <h3>Verifying CIN Details ...</h3>
    </div>
  </div>
  <div *ngIf="isDuplicate == 'duplicate'" class="pt-4">
    <h2 mat-dialog-title>Details</h2>
    <div mat-dialog-content>
      <div class="formFooter">
        <div class="col-md-8 offset-1 ">
        <strong>Details of CIN No. {{ data.cin |uppercase}}</strong>
        </div>
      </div>
      <div class="col-md-12 text-center">
        User already exists, you are requested to LOGIN .
      </div>
    </div>
    <div mat-dialog-actions class="row offset-6" align="end">
      <div class="col-md-6">
        <button mat-button (click)="onNoClick()" class="text-success">
          Edit CIN No.
        </button>
      </div>
      <div class="col-md-6">
       <a  href="https://stgdev.parivesh.nic.in/parivesh-ua-dev/"> <button mat-button class="btn btn-success text-white">
        LOGIN
        </button></a>
      </div>
    </div>
  </div>
  <div *ngIf="isDuplicate == 'notDuplicate'" class="pt-4">
    <div *ngIf="statusCode == 'E'" style="font-family: 'Lato'">
      <h1
        mat-dialog-title
        class="text-center"
        style="font-family: 'Lato'; font-size: 38px; font-weight: 700"
      >
        CIN Details
      </h1>
      <div mat-dialog-content>
        <div class="formFooter">
          <div class="row">
            <div class="col-sm-6 text-start heading">
              <span>Details of CIN No:</span>
            </div>
            <div class="col-sm-6 text-center">
              <div class="headingText">
                <span class="pan">{{ data.cin |uppercase}}</span>
              </div>
            </div>
            <div class="col-sm-6 text-center heading">
              <span>Entity Name as per CIN:</span>
            </div>
            <div class="col-sm-6 text-start headingText">
              <span style="padding: 7px">{{ data.fullName | uppercase }}</span>
            </div>
          </div>
        </div>
        <div style="padding: 13px 39px; text-align: start">
          Do you want to Register as <b>{{ data.entType }}</b> on portal ?
        </div>
      </div>
      <div mat-dialog-actions align="end">
        <button
          style="font-family: 'Lato'"
          mat-button
          (click)="onNoClick()"
          class="text-success"
        >
          Edit CIN Details
        </button>
        <button
          mat-button
          [mat-dialog-close]="data"
          cdkFocusInitial
          class="btn-success btn-continue"
        >
          Continue
        </button>
      </div>
    </div>

    <div *ngIf="statusCode == 'N'">
      <h2 mat-dialog-title>Details</h2>
      <div mat-dialog-content class="d-flex align-items-md-center">
        <div class="col-md-10 text-center pt-10 errMsg alignc">
          <h2>CIN Details Doesn't Exist .</h2>
        </div>
      </div>
      <div mat-dialog-actions class="row offset-4">
        <div class="col-md-4">
          <button mat-button (click)="onNoClick()">Back</button>
        </div>
      </div>
    </div>
    <div *ngIf="statusCode == 'F'">
      <h2 mat-dialog-title>Details</h2>
      <div mat-dialog-content class="d-flex align-items-md-center">
        <div class="col-md-10 text-center pt-10 errMsg alignc">
          <h2>CIN is Fake .</h2>
        </div>
      </div>
      <div mat-dialog-actions class="row offset-4">
        <div class="col-md-4">
          <button mat-button (click)="onNoClick()">Back</button>
        </div>
      </div>
    </div>
    <div *ngIf="statusCode == null">
      <h2 mat-dialog-title>Details</h2>
      <div mat-dialog-content>
        <div class="formFooter">
          <div class="col-md-12"><h3>Internal Server Error ...</h3></div>
        </div>
      </div>
      <div mat-dialog-actions class="row offset-4">
        <div class="col-md-4">
          <button mat-button (click)="onNoClick()" class="text-success">
            Back
          </button>
        </div>
      </div>
    </div>








    <div></div>
  </div>
</div>
