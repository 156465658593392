import {createReducer, on} from "@ngrx/store";
import {DeiaaSeiaaDashboardActions} from "../deiaa-seiaa-dashboard-state/deiaa-seiaa-dashboard-action-types";

export interface DeiaaSieaaDashboardState {
  pending: any;
  invalid:any;
  valid:any;
  isLoaded: boolean;
}

const initialDeiaaSieaaDashboardState: DeiaaSieaaDashboardState = {
    isLoaded: false,
    pending: null,
    invalid:null,
    valid:null
}

export const deiaaSieaaDashboardReducer = createReducer<DeiaaSieaaDashboardState>(
    initialDeiaaSieaaDashboardState,
    on(DeiaaSeiaaDashboardActions.deiaaSeiaaPendingSuccess, (state, action) => {
        console.log(state)
        console.log(action.pending)
        console.log('---------')
      return {
        ...state,
        pending: action.pending,
        isLoaded: true,
      };
    }),

    on(DeiaaSeiaaDashboardActions.deiaaSeiaaValidSuccess, (state, action) => {
        console.log('valid')
        console.log(action.valid)
        return ({
          ...state,
          valid: action.valid,
          isLoaded: true,
        })
      }),
      
  
    on(DeiaaSeiaaDashboardActions.deiaaSeiaaInvalidSuccess, (state, action) => {
      return {
        ...state,
        invalid: action.invalid,
        isLoaded: true,
      };
    }),
  );