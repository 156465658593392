import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { SharedServiceService } from '../../../services/shared-service.service';
import { PpFormService } from './pp-form.service';
import { ThankYouPageComponent } from '../success-message-dialog/thank-you-page.component';
import { ErrorMessageComponent } from '../error-message/error-message.component';
import { User } from 'src/app/models/user/user.model';
import { CountryCode } from 'src/app/models/master/country-code.model';
import { State } from 'src/app/models/master/state.model';
import { District } from 'src/app/models/master/district.model';
import { regexMap } from 'src/app/shared/regex&pattern';
import { UserService } from 'src/app/services/user.service';
import { MessageService } from 'src/app/services/message.service';
import {
  ROLE_ABBR, ROLE_IDS, DOCUMENT_REF_TYPES,
  DOCUMENT_TYPE, MODULE_ID
} from 'src/app/shared/app-constant';
import { debounceTime } from 'rxjs';
import { RoleService } from 'src/app/services/role.service';
import { OKMService } from 'src/app/services/okm.services';
import { DocumentType } from 'src/app/models/okm/document_type.model';
import { ConfirmationDialogService } from "../../../shared/components/confirmation-dialog/confirmation-dialog.service";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pp-form',
  templateUrl: './pp-form.component.html',
  styleUrls: ['./pp-form.component.css'],
})
export class PpFormComponent implements OnInit, OnDestroy, AfterViewInit {
  _commonDetail: any;

  @ViewChild('myCanvas')
  myCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('captcha')
  captcha!: ElementRef;

  @ViewChild('file')
  file!: ElementRef;
  isCaptcha: boolean = false;
  check: boolean = false;
  stdCode: string = '';
  cEntityType: string = 'Intial';
  captchaErrorMsg: boolean = false;
  captchatext: any;
  userEnterCaptcha: any = '';
  errorMsg: string = '';
  registersUser: User;
  FileErrorMsg: string = '';
  registerUserForm: FormGroup = new FormGroup({});
  // isFilePresent: boolean = false;
  districtList: District[] = [];
  selectedDistrictList: District[] = [];
  stateList: State[] = [];
  countryCode: CountryCode[] = [];
  duplicateEmail: string = 'INTIAL';
  // filetobeUpload: any;
  isSuccess: boolean = false;
  validationCheck: boolean = false;
  checkDeclaration: boolean = false;
  errorMsgStdCode: string = '';
  errorMsgLandLineNumber: string = '';
  role: any;
  public documentTypes: DocumentType[] = [];
  DOCUMENT_REF_TYPES = DOCUMENT_REF_TYPES;
  DOCUMENT_TYPE = DOCUMENT_TYPE;
  isReadonly: boolean = false;
  environment = environment;
  constructor(
    private fb: FormBuilder,
    public sharedService: SharedServiceService,
    public dialog: MatDialog,
    private service: PpFormService,
    private messageService: MessageService,
    private userService: UserService,
    private roleService: RoleService,
    private okmService: OKMService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    this.registersUser = new User();
  }

  ngOnInit(): void {
    // this.getRoleDetail();
    this.createForm();
    this.captchaErrorMsg = false;
    this.getState();
    this.getDistrict();
    this.registerUserForm.controls['email'].valueChanges.pipe(
      debounceTime(1000)
    );
    this.getDocumentType()
  }

  checkEmail(email: any) {
    this.sharedService
      .getDuplicateEmail(email.target.value?.toLowerCase(), this.cEntityType)
      .subscribe((resp) => {
        if (resp.data) {
          this.duplicateEmail = 'YES';
        } else {
          this.duplicateEmail = 'NO';
        }
      });
  }

  getCountryCode() {
    this.sharedService.getCountryCode().subscribe((res) => {
      this.countryCode = res.data;
    });
  }

  ngAfterViewInit(): void {
    this.captchaErrorMsg = false;
    this.getCaptcha(6);
  }

  ngOnDestroy(): void {
    this.registerUserForm.reset();
  }

  get f() {
    return this.registerUserForm.controls;
  }

  checkMobile(event: any) {
    if (this.check == true) {
      this.registerUserForm.patchValue({ mobile: '' });
    }
    this.check = !this.check;
  }

  createForm() {
    let rname;
    let rcin;
    let rRegisterType;
    let rentityName: string = '';
    let rpan = null;
    let ryoc = ''
    this.sharedService.updatedCommonDetail.subscribe((res) => {
      rname = res.name;
      rcin = res.cin;
      ryoc = res.Yoc
      rRegisterType = res.registerType;
      rentityName = res.entityType;

      if (res.panNumber == '') {
        rpan = null;
      } else {
        rpan = res.panNumber;
      }
    });
    this.cEntityType = rentityName;

    if (rentityName == 'Individual') {

      this.registerUserForm = this.fb.group({
        name_of_Entity: [rname, [Validators.required]],
        address: [this.registersUser.address, [Validators.required]],
        email: [
          this.registersUser.email,
          [Validators.required, Validators.pattern(regexMap.regexUserEmail)],
        ],
        mobile: [
          this.registersUser.mobile,
          [Validators.required, Validators.pattern(regexMap.regexMobile)],
        ],
        state_ut: [this.registersUser.state_ut, [Validators.required]],
        district: [this.registersUser.district, [Validators.required]],
        pincode: [
          this.registersUser.pincode,
          [Validators.required, Validators.pattern(regexMap.regexPinCode)],
        ],

        is_active: [false, []],
        is_delete: [false, []],
        security_question: ['', []],
        security_answer: ['', []],
        landline_No: [null, []],
        pan_no: [rpan, [Validators.required]],
        website: ['', []],
        is_organisation: [false, [Validators.required]],
        org_address: [''],
        org_state_ut: [''],
        org_district: [''],
        org_pincode: [''],
        id: [0, []],
        voter_id: [null],
        authority_letter: [null]
      });
    } else if (rentityName == 'Others') {
      let pan;
      if (rpan == null) {
        pan = null;
      } else {
        pan = rpan;
      }
      this.registerUserForm = this.fb.group({
        name_of_Entity: [rname, [Validators.required]],
        address: [this.registersUser.address, [Validators.required]],
        email: [
          this.registersUser.email,
          [Validators.required, Validators.pattern(regexMap.regexUserEmail)],
        ],
        mobile: [
          this.registersUser.mobile,
          [Validators.required, Validators.pattern(regexMap.regexMobile)],
        ],
        state_ut: [this.registersUser.state_ut, [Validators.required]],
        district: [this.registersUser.district, [Validators.required]],
        pincode: [
          this.registersUser.pincode,
          [Validators.required, Validators.pattern(regexMap.regexPinCode)],
        ],

        is_active: [false, []],
        is_delete: [false, []],
        security_question: ['', []],
        security_answer: ['', []],
        landline_No: [null, []],
        pan_no: [pan],
        website: ['', []],
        is_organisation: [false, [Validators.required]],
        org_address: [null],
        org_state_ut: [null],
        org_district: [null],
        org_pincode: [null],
        id: [0, []],
        voter_id: [null, [Validators.required]],
        authority_letter: [null]
      });
    } else if (rentityName == 'Trust/Association/Society') {
      this.registerUserForm = this.fb.group({
        name_of_Entity: [rname, [Validators.required]],
        is_pan_company_name_same: ['true', [Validators.required]],
        name_of_Entity_roc: [''],
        name_of_Contact_Person: ['', [Validators.required]],
        designation: ['', [Validators.required]],
        role: ['Super User'],
        address: [this.registersUser.address, [Validators.required]],
        email: [
          this.registersUser.email,
          [Validators.required, Validators.pattern(regexMap.regexUserEmail)],
        ],
        mobile: [
          this.registersUser.mobile,
          [Validators.required, Validators.pattern(regexMap.regexMobile)],
        ],
        state_ut: [this.registersUser.state_ut, [Validators.required]],
        district: [this.registersUser.district, [Validators.required]],
        pincode: [
          this.registersUser.pincode,
          [Validators.required, Validators.pattern(regexMap.regexPinCode)],
        ],

        is_active: [false, []],
        is_delete: [false, []],
        security_question: ['', []],
        security_answer: ['', []],
        landline_No: [null, []],
        pan_no: [rpan, [Validators.required]],
        website: ['', []],
        id: [0, []],
        cin_no: [rcin],
        year_of_inc: [ryoc],
        voter_id: [null],
        authority_letter: [null]
      });
    } else {
      this.registerUserForm = this.fb.group({
        name_of_Entity: [rname, [Validators.required]],
        name_of_Contact_Person: ['', [Validators.required]],
        designation: ['', [Validators.required]],
        address: [this.registersUser.address, [Validators.required]],
        email: [
          this.registersUser.email,
          [Validators.required, Validators.pattern(regexMap.regexUserEmail)],
        ],
        mobile: [
          this.registersUser.mobile,
          [Validators.required, Validators.pattern(regexMap.regexMobile)],
        ],
        state_ut: [this.registersUser.state_ut, [Validators.required]],
        district: [this.registersUser.district, [Validators.required]],
        pincode: [
          this.registersUser.pincode,
          [Validators.required, Validators.pattern(regexMap.regexPinCode)],
        ],

        is_active: [false, []],
        is_delete: [false, []],
        security_question: ['', []],
        security_answer: ['', []],
        landline_No: [null, []],
        pan_no: [rpan, [Validators.required]],
        website: ['', []],
        id: [0, []],
        cin_no: [rcin],
        year_of_inc: [ryoc],
        voter_id: [null],
        authority_letter: [null]
      });
    }
  }

  onChangePanCompany(event: any) {
    let value = event.target.value;

    if (value == 'true') {
      this.registerUserForm.get('name_of_Entity_roc')?.clearValidators(); // 5.Set Required Validator
      this.registerUserForm.get('name_of_Entity_roc')?.updateValueAndValidity();
    } else {
      // this.registerUserForm.addValidators(Validators.required)
      this.registerUserForm.get('name_of_Entity_roc')?.setValidators([Validators.required]); // 5.Set Required Validator
      this.registerUserForm.get('name_of_Entity_roc')?.updateValueAndValidity();
    }

    // if(countrySelected === 'B') {
    //   this.dummyForm.get('county').setValidators([Validators.required]); // 5.Set Required Validator
    //   this.dummyForm.get('county').updateValueAndValidity();
    // } else {
    //   this.dummyForm.get('county').clearValidators(); // 6. Clear All Validators
    //   this.dummyForm.get('county').updateValueAndValidity();
    // }

  }

  captchaCaptured(captcha: any) {
    this.userEnterCaptcha = captcha.target.value;
  }

  resetError() {
    if (this.captchaErrorMsg) {
      this.captchaErrorMsg = false;
      this.isCaptcha = false;
    }
  }

  checkCaptcha(usercaptcha: any) {
    if (usercaptcha == this.captchatext) {
      this.userEnterCaptcha = '';
      this.isCaptcha = true;
      this.captchaErrorMsg = false;
    } else {
      this.getCaptcha(6);
      this.captchaErrorMsg = true;
      this.isCaptcha = false;
      this.userEnterCaptcha = '';
    }
  }

  checkStdCode(event: any) {
    let val = event.target.value;

    if (val.length == 0) {
      this.errorMsgStdCode = '';
      this.stdCode = val;
      return;
    }
    if (val.length == 1 || val.length > 6) {
      this.errorMsgStdCode = 'Please Enter Valid Std Code';
      this.stdCode = val;
    } else {
      this.errorMsgStdCode = '';

      this.stdCode = val;

      this.LandLineValidation();
    }
  }

  LandLineValidation() {
    let landLineNo: string = this.registerUserForm.get('landline_No')?.value;

    if (landLineNo == null || landLineNo.length == 0) {
      this.errorMsgLandLineNumber = '';
      this.errorMsgStdCode = '';
      return;
    }
    if (this.stdCode == '') {
      this.errorMsgStdCode = 'Please Enter Valid Std Code';
    } else if (!(landLineNo.length >= 6 && landLineNo.length < 9)) {
      this.errorMsgLandLineNumber = 'Enter Valid LandLine Number';
    } else {
      this.errorMsgLandLineNumber = '';
    }
  }

  checkDeclare(event: any) {
    this.checkDeclaration = !this.checkDeclaration;
  }

  async ErrorDialog(): Promise<void> {
    const dialogRef = this.dialog.open(ErrorMessageComponent, {
      width: '50%',
      height: 'auto',
      data: {
        errMsg: this.errorMsg,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.errorMsg = '';
    });
  }

  async openDialog(): Promise<void> {
    let val;
    if (this.registerUserForm.get('pan_no')?.value == null) {
      val = true;
    } else {
      val = false;
    }
    const dialogRef = this.dialog.open(ThankYouPageComponent, {
      width: '50%',
      disableClose: true,
      height: 'auto',
      data: {
        entityType: this.cEntityType,
        isScrutiny: val,
      },
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }

  getCaptcha(length: any) {
    this.captchatext = '';
    this.captcha.nativeElement.value = '';
    var pen: any = this.myCanvas.nativeElement.getContext('2d');
    var randomChars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    this.captchatext = '';
    for (var i = 0; i < length; i++) {
      this.captchatext += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    pen.font = '18px Roboto ';
    pen.fillStyle = '#000000';

    pen?.fillRect(0, 0, 126, 52);
    pen.fillStyle = '#FFFFFF';
    // pen.textAlign="center";
    pen?.fillText(this.captchatext, 20, 32);
  }


  getDistrict() {
    this.sharedService.getDistrict().subscribe((dist) => {
      this.districtList = dist.data;
    });
  }

  getDocumentType() {
    this.okmService
      .getDocumentTypeByModuleID(MODULE_ID.USER_REGISTRATION, 2)
      .subscribe((res: DocumentType[]) => {
        this.documentTypes = res;
      });
  }

  getDocumentTypeMapping(type: string) {
    return this.documentTypes.find((val) => val.abbr === type) || {};
  }

  receivedMessageHandler(data: any) {
    // console.log('document data', data);
    this.registerUserForm.controls[data.controlName].setValue({
      id: 0,
      ref_id: data.refId,
      type: data.refType,
      proposal_no: data.proposal_no,
      document_mapping_id: data.docTypeMappingId,
      document_name: data.name,
      version: data.version,
      uuid: data.uuid,
    });
  }

  getSelectedDist(event: any) {
    let stateCode = event.target.value;
    this.selectedDistrictList = this.districtList.filter((dis) => {
      return dis.state_code == stateCode;
    });
  }

  getState() {
    this.sharedService.getStates().subscribe((state) => {
      this.stateList = state.data;
    });
  }

  async onSubmit() {

    Object.keys(this.registerUserForm.controls).forEach((key) => {
      if (this.registerUserForm.controls[key].status === 'INVALID') console.log(key)
    });

    if (this.errorMsgLandLineNumber != '' || this.errorMsgStdCode != '') {
      this.messageService.showError('Please Fill Correct Landline Number');
      return;
    }
    this.checkCaptcha(this.userEnterCaptcha);
    localStorage.setItem('ut', JSON.stringify(this.registerUserForm.value));
    this.registersUser = { ...this.registerUserForm.value, pan_no: this.registerUserForm.value.pan_no ? this.registerUserForm.value?.pan_no?.toUpperCase() : this.registerUserForm.value.pan_no, email: this.registerUserForm.value?.email?.toLowerCase() } as User;
    if (this.cEntityType == 'Individual') {
      if (this.registerUserForm.invalid || this.isCaptcha == false) {
        this.validationCheck = true;
        this.getCaptcha(6);
        this.messageService.showError('Please fill the mandatory fields');
        this.FileErrorMsg = '';
        return false;
      } else {
        this.validationCheck = false;
      }
      // return;
      this.service.postUserForm(this.registersUser, this.cEntityType).subscribe(
        (msg) => {
          this.openDialog();
          this.assignRole(msg.data.UserId);
          this.registerUserForm.disable();
        },
        (resp) => {
          let err: HttpErrorResponse = resp;
          if (err.status == 200) {
            this.openDialog();
            this.assignRole(resp.data.UserId);
            this.registerUserForm.disable();
          } else if (err.status != 200) {
            this.errorMsg = JSON.stringify(err.error.message);

            this.ErrorDialog();
          }
        }
      );
    } else if (this.cEntityType == 'Others') {
      // if (this.registerUserForm.controls['pan_no'].value != null) {
      //   this.filetobeUpload = {} as File;
      //   // this.isFilePresent = true;
      // }


      if (
        this.registerUserForm.invalid ||
        // this.isFilePresent == false ||
        this.isCaptcha == false ||
        this.checkDeclaration == false
      ) {

        Object.keys(this.registerUserForm.controls).forEach((key) => {
          if (this.registerUserForm.controls[key].status === 'INVALID') console.log(key);
        });

        this.validationCheck = true;
        this.messageService.showError('Please fill the mandatory fields');
        this.FileErrorMsg = '';
        this.getCaptcha(6);
        return false;
      } else {
        this.validationCheck = false;
      }

      this.service
        .postUserOther(
          this.registerUserForm.value,
          // this.filetobeUpload,
          this.cEntityType
        )
        .subscribe(
          (msg) => {
            this.openDialog();
            this.submitForApproval(msg.data.UserId);
            this.assignRole(msg.data.UserId);
            this.registerUserForm.disable();
          },
          (resp) => {
            let err: HttpErrorResponse = resp;

            let errMsg: string = '';
            if (err.status == 200) {
              this.submitForApproval(resp.data.UserId);
              this.assignRole(resp.data.UserId);
              this.openDialog();
              this.registerUserForm.disable();
            } else if (err.status != 200) {
              this.errorMsg = JSON.stringify(err.error.message);

              this.ErrorDialog();
            }
          }
        );
    } else {
      if (
        this.registerUserForm.invalid ||
        // this.isFilePresent == false ||
        this.isCaptcha == false ||
        this.checkDeclaration == false
      ) {
        this.validationCheck = true;
        this.messageService.showError('Please fill the mandatory fields');
        this.getCaptcha(6);
        return false;
      } else {
        this.validationCheck = false;
      }

      let confirm = await this.confirmationDialogService.confirm(
        'Alert',
        `Registration done by the person who will also act as a super user.`,
        'Submit',
        'Cancel'
      );
      if (confirm) {
        this.service
          .postUserFormEntity(
            this.registerUserForm.value,
            // this.filetobeUpload,
            this.cEntityType
          )
          .subscribe(
            (msg) => {
              this.openDialog();
              this.assignRole(msg.data.UserId);
              this.registerUserForm.disable();
            },
            (resp) => {
              let err: HttpErrorResponse = resp;

              let errMsg: string = '';
              if (err.status == 200) {
                this.openDialog();
                this.registerUserForm.disable();
                this.assignRole(resp.data.UserId);
              } else if (err.status != 200) {
                this.errorMsg = JSON.stringify(err.error.message);
                this.ErrorDialog();
              }
            }
          );
      }
    }

    return true;
  }

  onIsOrganisationChange(e: any) {
    if (e.target.value == true) {
      this.registerUserForm.controls['org_address'].setValidators(
        Validators.required
      );
      this.registerUserForm.controls['org_state_ut'].setValidators(
        Validators.required
      );
      this.registerUserForm.controls['org_district'].setValidators(
        Validators.required
      );
      this.registerUserForm.controls['org_pincode'].setValidators(
        Validators.required
      );
    } else {
      this.registerUserForm.controls['org_address'].setValidators(null);
      this.registerUserForm.controls['org_state_ut'].setValidators(null);
      this.registerUserForm.controls['org_district'].setValidators(null);
      this.registerUserForm.controls['org_pincode'].setValidators(null);
    }
    this.registerUserForm.updateValueAndValidity();
  }

  submitForApproval(userId: number) {
    this.userService.submitForApproval(userId).subscribe((res) => { });
  }

  assignRole(userId: number) {
    this.userService
      .assignRole([
        {
          entityId: null,
          role: {
            entityId: ROLE_IDS.PROJECT_PROPONENT,
          },
          user: {
            entityId: userId,
          },
          workgroup: {
            entityId: 1,
          },
          disableLinkOfficer: false,
        },
      ])
      .subscribe((res) => { });
  }

  addressSame(event: any) {
    event.target.checked
      ? this.registerUserForm.controls['org_state_ut'].setValue(
        this.registerUserForm.controls['state_ut'].value
      )
      : this.registerUserForm.controls['org_state_ut'].reset();

    event.target.checked
      ? this.registerUserForm.controls['org_district'].setValue(
        this.registerUserForm.controls['district'].value
      )
      : this.registerUserForm.controls['org_district'].reset();

    event.target.checked
      ? this.registerUserForm.controls['org_address'].setValue(
        this.registerUserForm.controls['address'].value
      )
      : this.registerUserForm.controls['org_address'].reset();

    event.target.checked
      ? this.registerUserForm.controls['org_pincode'].setValue(
        this.registerUserForm.controls['pincode'].value
      )
      : this.registerUserForm.controls['org_pincode'].reset();
  }

  getRoleDetail() {
    this.roleService.getByAbbr(ROLE_ABBR.PROJECT_PROPONENT).subscribe({
      next: (res) => {
        this.role = res;
      },
      error: () => { },
    });
  }
}
