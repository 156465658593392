
import { Component, OnInit } from '@angular/core';
import { HomeServiceService } from 'src/app/components/home/home-service.service';
import { VisitorService } from 'src/app/services/visitor.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  lastUpdatedDate:any | undefined;
  visitorCount: any;
  constructor(private visitorService: VisitorService, private homeService: HomeServiceService) {}

  ngOnInit(): void {
    this.getVisitorCount();
    this.getLatUpdatedDate();
  }

  getLatUpdatedDate() {
    this.homeService.getDate.subscribe((latestDate) => {
      this.lastUpdatedDate = latestDate;
  
    });
  }

  getVisitorCount() {
    this.visitorService.get().subscribe({
      next: (res) => {
        this.visitorCount = res.data[0];
      },
      error: () => {},
    });
  }
}
