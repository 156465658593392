<!-- Header-->
<header class="header header-fixed border-0 shadow-sm">
  <app-header-top></app-header-top>
  <app-header-logo [fluid]="true"></app-header-logo>
</header>
<!-- Load Component -->
<aside class="main-sidebar">
  <app-sidebar-menu></app-sidebar-menu>
</aside>
<main class="main-content">
  <div class="main-content-inner">
    <router-outlet></router-outlet>
  </div>
  <!-- Footer -->
  <!-- <app-footer></app-footer> -->
  <a href="https://parivesh.nic.in/complain/mobileNumberForm?page=issuer" target="_blank" class="float bg-primary">
    <i class="fa fa-question my-icon"></i>
    <span class="hover-text">Raise the ticket</span>
  </a>
</main>
