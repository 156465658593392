import { Component, OnInit } from '@angular/core';
import { LocalizationService } from 'src/app/internationalization/localization.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html',
  styleUrls: ['./header-top.component.scss'],
})
export class HeaderTopComponent implements OnInit {
  language: string = 'en-US';

  constructor(
    private localizationService: LocalizationService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void { }

  onLanguageChange(lang: string): void {
    this.localStorageService.set('language', lang);
    this.localizationService.initService();
  }

  public changeSize(size: number): void {
    document.body.style.fontSize = size + "px";
  }

  skipMainContent() {   
    let elemId: any = document.querySelector('#main-content');
    if(elemId){
      var headerOffset = 112;
      var elementPosition = elemId.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }
}
