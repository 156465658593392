import { createReducer, on } from "@ngrx/store";
import { DashboardActions } from "../dashboard-action-types";

export interface JsDashboardState {
  jsDashboardProposals: any;
  isLoaded: boolean;
  sectors: any[]
}

const initialState: JsDashboardState = {
  jsDashboardProposals: undefined,
  isLoaded: false,
  sectors: []
}

export const jsDashboardReducer = createReducer<JsDashboardState>(
  initialState,
  on(DashboardActions.loadDepartmentDashboardProposalList, (state: JsDashboardState, action) => ({ ...state })),
  on(DashboardActions.loadAllSectorsSuccess, (state: JsDashboardState, action) => ({ ...state, sectors: action.sectors })),
)
