import { ActivatedRoute, Router } from '@angular/router';
import {
  CAF_PROPOSAL_FOR,
  CAF_STATUS,
  DATE_FORMATS,
  EXTRA_STEPS,
  FORM_STATUS,
} from '../../shared/app-constant';
import { BehaviorSubject } from 'rxjs';
import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RouterUtility } from 'src/app/utils/router.utility';
import { StepsModal } from '../../models/steps-modal';
import { TorcertificateService } from '../services/certificates/torcertificate.service';
import { ValidationUtility } from '../../utils/validation.utility';
import { environment } from 'src/environments/environment';
import moment from 'moment';
import { omit } from 'lodash';

@Component({
  selector: 'app-base-form',
  templateUrl: './base-form.component.html',
})
export class BaseFormComponent {
  imagePath = `${environment.frontendURL}/assets/images/`;
  processStepId!: string;
  processHistroyId!: string;
  processMappingId!: string;
  actionId!: string;
  projectId!: string;
  cafId!: string;
  fileNo!: string;
  crzId!: any;
  oldCafId!: string;
  clearence!: string;
  formHeading!: string;
  mainFormData!: any;
  id!: any;
  oldId!: any;
  formFor!: string;
  amendmentFormName!: string;
  isAmendmentEdit!: boolean;
  ecId!: string;
  fcFormId!: string;
  form_name!: string;
  oldEcId!: string;
  formFiveId!: string;
  validationCheck: boolean = false;
  is_add_demand: boolean = false;
  isSirUploaded: boolean = false;
  isReadonly: boolean = false;
  isLockAllowed: boolean = false;
  isAmendment!: boolean;
  routeBasePath!: string;
  queryParams: any = {};
  currentStep!: number;
  formModule!: string;
  moduleSteps!: StepsModal[];
  componentForm!: FormGroup;
  isForOldProposal!: boolean;
  isReadOnly$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  params: any;
  oldProposalId!: string;
  newProposalId!: string;
  amendmentProposal!: string;
  DATE_FORMATS = DATE_FORMATS;
  environment = environment;
  loggerIsTestedUser: boolean = false;
  ADDITIONAL_INFORMATION = 'Additional Information';
  OTHER_DETAILS = 'Other Details';
  isAgendaMomHistoryDataExists: boolean = true;
  totalYearsCount: number = moment().diff('2021-01-01', 'years');

  dropDownOptionsyn: { label: string; value: string }[] = [
    { label: 'Yes', value: 'YES' },
    { label: 'No', value: 'NO' },
  ];
  dropDownOptions: { label: string; value: boolean }[] = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  maxDate = new Date().toISOString().split('T')[0];
  isPreview: boolean = false;
  fcProposalId!: string;
  responseData!: any;

  modelDisableCloseConfiguration: any = {
    backdrop: 'static',
    keyboard: false,
  };

  fragmentURI: any = '';

  draftData: any;
  applicationId: any;
  jsDashboard: boolean = false;
  isFormHistory!: boolean;
  getServerError = false;
  isFromPartA: boolean = false;
  roleNameData!: any;
  stateFileNo!: any;
  moefccCertFileNo!: any;

  constructor(
    public router: Router,
    public routerUtility: RouterUtility,
    public route: ActivatedRoute,
    public torService?: TorcertificateService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.queryParams = omit(params, ['project', 'caf']);
      this.getApprovingAuthority(params['proposal_id'], params['proposal_no']);
    });
    this.route.fragment.subscribe((value) => {
      this.fragmentURI = value;
    });
    this.routeBasePath = this.routerUtility.getRoutePath(this.router.url);
    this.isPreview = this.routeBasePath.includes('department/proposal-detail');
    if (this.router.url.includes('department/proposal-detail'))
      this.isReadonly = true;
  }

  isReadOnlyCheck(status: string, isFromHistory: boolean = false) {
    this.isReadonly = isFromHistory ||
      !FORM_STATUS.includes(status) ||
      this.router.url.includes('department/proposal-detail');
    this.isReadOnly$.next(this.isReadonly);
  }

  isEditableCheck(status: string, mode: string) {
    this.isReadonly =
      mode == 'view' &&
      (status === CAF_STATUS.SUBMITTED ||
        this.router.url.includes('department/proposal-detail'));
    this.isReadOnly$.next(this.isReadonly);
  }

  getAllParams() {
    return this.router.routerState.snapshot.root?.queryParams;
  }

  getFilteredRange(value: number, array: any[], key: string): any[] {
    return array.filter((item) => item[key] == value);
  }

  next(
    routePath: string,
    params?: Object,
    omitParams?: any,
    isNotRelative?: boolean
  ) {
    const url =
      routePath.includes('dashboard') ||
        routePath.includes('clearance-process') ||
        routePath.includes('certificate') ||
        isNotRelative
        ? routePath
        : `${this.routeBasePath}${routePath}`;
    this.router.navigate([url], {
      queryParams: omit({ ...this.queryParams, ...params }, omitParams),
    });
  }

  prev(routePath: string, params?: Object, omitParams?: any) {
    const url =
      routePath.includes('dashboard') ||
        routePath.includes('clearance-process') ||
        routePath.includes('certificate')
        ? routePath
        : `${this.routeBasePath}${routePath}`;

    this.router.navigate([url], {
      queryParams: omit({ ...this.queryParams, ...params }, omitParams),
    });
  }

  toNavigate(routePath: string, params?: Object, omitParams?: any) {
    const url = `${this.routeBasePath}${routePath}`;
    this.router.navigate([url]);
  }

  isFormValidForDisable(status?: string, isFromHistory: boolean = false) {
    return new Promise<boolean>((resolve) => {
      if (isFromHistory) resolve(true)
      if (this.router.url.includes('department/proposal-detail')) resolve(true);
      if (status && !FORM_STATUS.includes(status)) resolve(true);
      resolve(false);
    });
  }

  isWLCFormValidForDisable(status: string, mode: string) {
    return new Promise<boolean>((resolve) => {
      if (mode == 'edit') resolve(false);
      if (this.router.url.includes('department/proposal-detail')) resolve(true);
      if (status && !FORM_STATUS.includes(status)) resolve(true);
      resolve(false);
    });
  }

  isForAmendment(form_for: string) {
    if (form_for == CAF_PROPOSAL_FOR.AMENDMENT) this.isAmendment = true;
    return this.isAmendment;
  }

  saveRemarks(validationUtil: ValidationUtility) {
    validationUtil.saveRemarks(
      this.formModule,
      this.moduleSteps,
      this.currentStep,
      this.componentForm,
      true
    );
  }

  patchRemarks(validationUtil: ValidationUtility) {
    setTimeout(() => {
      validationUtil.patchRemarks(
        this.formModule,
        this.moduleSteps,
        this.currentStep,
        this.componentForm
      );
      validationUtil.updateValueValidity(this.componentForm);
    }, 100);
  }

  printLogs(message: string, extraKey?: string) {
    if (extraKey)
      console.log(
        `%c${extraKey} : ${message}`,
        `color:yellow; background-color: #111; padding: 4px 10px; margin: 1px; border-radius: 5px`
      );
    else
      console.log(
        `%c${message}`,
        `color:yellow; background-color: #111; padding: 4px 10px; margin: 1px; border-radius: 5px`
      );
  }

  getApprovingAuthority(proposal_id: any, proposal_no: any) {
    if (proposal_no?.startsWith('FP')) {
      this.torService?.getApprovingAuthorityV2(proposal_id).subscribe((res) => {
        this.isLockAllowed = res.data;
      });
    } else {
      this.torService?.getApprovingAuthority(proposal_id).subscribe((res) => {
        this.isLockAllowed = res.data;
      });
    }
  }

  getStateNameBase(states: any[], code: number): string {
    return states.filter((item) => item.value == code)[0]?.label;
  }

  getDistrictNameBase(districtByState: any[], code: number): string {
    return districtByState.filter((item) => item.value == code)[0]?.label;
  }

  getSubDistrictNameBase(subDistrict: any[], code: number): string {
    return subDistrict.filter((item) => item.value == code)[0]?.label;
  }

  getVillageNameBase(village: any[], code: number): string {
    return village.filter((item) => item.value == code)[0].label;
  }

  getGenericDateFormatCustom(date: string, character: string): string {
    let date_arr = date.split(character);
    return `${date_arr[2]}-${date_arr[1]}-${date_arr[0]}`;
  }

  getGenericDateFormat(date: string | Date): string {
    return date ? moment(date).format(DATE_FORMATS.GENERIC) : '';
  }

  getDdMmYyyyDateFormat(date: string | Date): string {
    return date ? moment(date).format(DATE_FORMATS.DD_MM_YYYY) : '';
  }

  getDisplayDateFormat(date: string | Date): string {
    return date ? moment(date).format(DATE_FORMATS.DISPLAY_FORMAT) : '';
  }

  get12HrTimeFormat(time: string): string {
    return time
      ? moment(time, DATE_FORMATS.HH_MM_A).format(DATE_FORMATS.HH_MM_A)
      : '';
  }

  getDateIn(format: string, date: string) {
    return date ? moment(date).format(format) : '';
  }

  getDatesBetweenTwoDates(
    meetingStartDate: Date,
    meetingEndDate: Date
  ): string[] {
    let dates = [];
    let currDate = moment(meetingStartDate).startOf('day');
    let lastDate = moment(meetingEndDate).startOf('day');
    while (currDate.isSameOrBefore(lastDate)) {
      dates.push(currDate.format('YYYY-MM-DD'));
      currDate.add(1, 'days');
    }
    return dates;
  }

  invalidFormControls(form: FormGroup) {
    Object.keys(form.controls).forEach((key) => {
      if (form.controls[key].status === 'INVALID') this.printLogs(key);
    });
  }

  addExtraStepAfterAdditionalInformation(
    steps: StepsModal[],
    stepText: string
  ) {
    steps.forEach((item: StepsModal) => {
      if (item.name == stepText) {
        steps = [...new Set(steps)];
        return;
      }
    });

    let stepRoute = '';

    if (stepText == EXTRA_STEPS.EDS_QUERY_RESPONSE)
      stepRoute = '/eds-query-response';
    else if (stepText == EXTRA_STEPS.AMENDMENT_LOGS)
      stepRoute = '/amendment-logs';

    steps.forEach((item: StepsModal, index: number) => {
      if (item.name == this.ADDITIONAL_INFORMATION)
        steps.splice(index + 1, 0, {
          name: stepText,
          route: stepRoute,
          id: stepText,
        });
      if (steps.length == index + 1) steps = [...new Set(steps)];
    });
  }

  addExtraStepAfterOther(steps: StepsModal[], stepText: string) {
    steps.forEach((item: StepsModal) => {
      if (item.name == stepText) {
        steps = [...new Set(steps)];
        return;
      }
    });

    let stepRoute = '';

    if (stepText == EXTRA_STEPS.EDS_QUERY_RESPONSE)
      stepRoute = '/eds-query-response';
    else if (stepText == EXTRA_STEPS.AMENDMENT_LOGS)
      stepRoute = '/amendment-logs';

    steps.forEach((item: StepsModal, index: number) => {
      if (item.name == this.OTHER_DETAILS)
        steps.splice(index + 1, 0, {
          name: stepText,
          route: stepRoute,
          id: stepText,
        });
      if (steps.length == index + 1) steps = [...new Set(steps)];
    });
  }

  deleteUploadedDocument(form: FormGroup, formControlName: string) {
    form.controls[formControlName].setValue(null);
  }

  getJsonData(jsonString: string) {
    return jsonString ? JSON.parse(jsonString) : [];
  }

  comparerFunction(list: any, field: string) {
    return list.sort((a: any, b: any) => a[field] - b[field]);
  }

  isKeyExists(jsonString: string, key: string, value: string): boolean {
    return this.getJsonData(jsonString).some((item: any) => item[key] == value);
  }

  isValueExists(jsonString: string, key: string): boolean {
    return this.getJsonData(jsonString).every((item: any) => !!item[key]);
  }

  hideAgendaMomHistory(data: boolean) {
    this.isAgendaMomHistoryDataExists = data;
  }

  getAllYearsSinceLaunch = () => {
    let years: number[] = [];
    const launchYear = 2022;
    for (let i = 0; i < this.totalYearsCount; i++) years.push(launchYear + i);
    return years;
  };

  updateRouteParams(params: any) {
    this.router.navigate([], {
      queryParams: { ...this.getAllParams(), ...params },
      replaceUrl: true,
      queryParamsHandling: 'merge',
    });
  }

  // url
  async getBase64ImageFromUrl(imageUrl: any) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result);
        },
        false
      );

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }

  // addClassToTable(element: any) {
  //   let ele = document.createElement('div');
  //   ele.innerHTML = element;
  //   console.log(ele);
  //   let tables: any = ele.getElementsByTagName('table');
  //   for (var i = 0; i < tables.length; i++) {
  //     tables[i].className = tables[i].className + " table-bordered";
  //   }
  //   return ele.outerHTML;
  // }
}
