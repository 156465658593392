  <div class="header-top-bar skp-gradient text-white">
  <div class="container-fluid">
    <div
      class="row align-items-center justify-content-center justify-content-md-end"
    >
      <div class="col">
        <img src="./assets/images/top-logo-new.svg" alt="">
      </div>
      <div class="col-auto">
        <div class="header-top-buttons">
          <!-- <div class="d-sm-inline-block">
            <span
              class="fw-bold cursor-pointer d-inline-block"
              (click)="skipMainContent()"
            >
              Skip To Main Content
            </span>
            <span class="divider"></span>
            <span
              class="fw-bold cursor-pointer d-inline-block"
              routerLink="/cms/screen-reader"
            >
              Screen Reader Access
            </span>
          </div> -->
          <!-- <span class="divider"></span>
          <span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.875 18.75C15.2242 18.75 18.75 15.2242 18.75 10.875C18.75 6.52576 15.2242 3 10.875 3C6.52576 3 3 6.52576 3 10.875C3 15.2242 6.52576 18.75 10.875 18.75Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.4431 16.4438L20.9994 21.0002"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span class="divider"></span>
          <span class="btn-font-size">A-</span>
          <span class="btn-font-size">A</span>
          <span class="btn-font-size" (click)="changeSize(100)">A+</span> -->
          <!-- <span class="divider"></span> -->
          <div ngbDropdown class="d-inline-block">
            <button type="button" class="btn btn-sm text-white" ngbDropdownToggle>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-miterlimit="10"
                />
                <path
                  d="M3.51245 9H20.4873"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.51294 15H20.4877"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 20.7584C14.0711 20.7584 15.75 16.8371 15.75 12C15.75 7.16285 14.0711 3.24158 12 3.24158C9.92893 3.24158 8.25 7.16285 8.25 12C8.25 16.8371 9.92893 20.7584 12 20.7584Z"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-miterlimit="10"
                />
              </svg>
              English
            </button>
            <div ngbDropdownMenu>
              <button ngbDropdownItem (click)="onLanguageChange('en-US')">
                English
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
