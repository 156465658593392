import {
  API,
  REFRESH_TOKEN_KEY,
  ROLES_KEY,
  TOKEN_EXPIRY_KEY,
  USER_EMAIL_KEY,
  USER_INFO_KEY,
} from '../shared/app-constant';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MenuService } from './menu-service';
import { Observable } from 'rxjs';
import { SessionStorageService } from './session-storage.service';
import { environment } from 'src/environments/environment';
import { isEmpty } from 'lodash';
import { servicesVersion } from 'typescript';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private sessionStorageService: SessionStorageService
  ) {}

  public isAuthenticated(): boolean {
    const token = this.sessionStorageService.get(USER_INFO_KEY);

    return !isEmpty(token);
  }

  public isTokenExpired(): boolean {
    const expiryTime = this.sessionStorageService.get(TOKEN_EXPIRY_KEY);
    const currentTime = new Date().getTime();
    const leftTime = expiryTime - currentTime;

    return leftTime <= 0;
  }

  public get AUTH_TOKEN(): string {
    return this.sessionStorageService.get(USER_INFO_KEY);
  }

  public get ROLES(): string {
    return this.sessionStorageService.get(ROLES_KEY);
  }

  public get REFRESH_TOKEN(): string {
    return this.sessionStorageService.get(REFRESH_TOKEN_KEY);
  }

  public get AUTH_EMAIL(): string {
    return this.sessionStorageService.get(USER_EMAIL_KEY);
  }

  refreshToken(body: any): Observable<any> {
    let url = `${environment.baseURLMis}${API.REFRESH_TOKEN}`;
    return this.http.post<any>(url, body);
  }
}
