import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Injectable} from "@angular/core";
import {AppState} from "../app-state/app-reducer";
import {select, Store} from "@ngrx/store";
import {isLoggedIn} from "./auth.selectors";
import {filter, finalize, first, tap} from "rxjs/operators";
import {AuthActions} from "./auth-action-types";

@Injectable({providedIn: 'root'})
export class AuthResolver implements Resolve<any> {
  isLoading: boolean = false;

  constructor(private store: Store<AppState>) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this.store.pipe(
      select(isLoggedIn),
      tap(isLoggedIn => {
        if (!this.isLoading && !isLoggedIn) {
          this.isLoading = true;
          this.store.dispatch(AuthActions.loadUserDetails());
        }
      }),
      filter(isLoggedIn => isLoggedIn),
      first(),
      finalize(() => this.isLoading = false)
    )
  }
}
