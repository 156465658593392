import { CanActivate, Router } from '@angular/router';

import { Injectable } from '@angular/core';
import { Wso2Service } from '../services/wso2.service';

@Injectable({
  providedIn: 'root',
})
export class ApiGuard implements CanActivate {
  constructor(private wso2Service: Wso2Service, private router: Router) {}

  canActivate(): boolean {
    if (this.wso2Service.isTokenExpired()) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
