import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';

import { Message } from 'primeng/api';
import { SharedServiceService } from '../../../../services/shared-service.service';
import { PpFormService } from '../pp-form.service';
import { ThankYouPageComponent } from '../../success-message-dialog/thank-you-page.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorMessageComponent } from '../../error-message/error-message.component';
import { User } from 'src/app/models/user/user.model';
import { CentralDepartment } from 'src/app/models/master/central-department.model';
import { District } from 'src/app/models/master/district.model';
import { State } from 'src/app/models/master/state.model';
import { Department } from 'src/app/models/master/department.model';
import { regexMap } from 'src/app/shared/regex&pattern';
import { MessageService } from 'src/app/services/message.service';
import { UserService } from 'src/app/services/user.service';
import { DOCUMENT_REF_TYPES, DOCUMENT_TYPE, MODULE_ID, ROLE_ABBR, ROLE_IDS } from 'src/app/shared/app-constant';
import { debounceTime } from 'rxjs';
import { RoleService } from 'src/app/services/role.service';
import { OKMService } from 'src/app/services/okm.services';
import { DocumentType } from 'src/app/models/okm/document_type.model';
import {isEmpty} from "lodash";

@Component({
  selector: 'app-pp-govt-depart',
  templateUrl: './pp-govt-depart.component.html',
  styleUrls: ['./pp-govt-depart.component.scss'],
})
export class PpGovtDepartComponent implements OnInit, OnDestroy, AfterViewInit {

  selectedStateDepartment: Department[] = [];

  @ViewChild('myCanvas')
  myCanvas!: ElementRef<HTMLCanvasElement>;

  @ViewChild('captcha')
  captcha!: ElementRef;

  isCaptcha: boolean = false;
  emailError: string = '';

  checkDeclaration: boolean = false;
  panNumber: string = '';
  check: boolean = false;
  FileErrorMsg: string = '';
  captchaErrorMsg: boolean = false;
  captchatext: any;
  userEnterCaptcha: any = '';
  landline: FormArray = new FormArray([]);
  msgs1: Message[] = [];
  errorMsg: string = '';
  registersUser: User;
  stateName: string = 'Select State';
  registerUserForm: FormGroup = new FormGroup({});
  enableSection: boolean = false;
  districtList: District[] = [];
  selectedDistrictList: District[] = [];
  stateList: State[] = [];
  centralDepartmentList: CentralDepartment[] = [];
  duplicateEmail: string = 'INTIAL';
  cEntityType: string = '';
  validationCheck: boolean = false;
  errorMsgLandLineNumber: string = '';
  stdCode: string = '';
  errorMsgStdCode: string = '';
  @ViewChild('file')
  file!: ElementRef;
  role: any;

  public documentTypes: DocumentType[] = [];
  DOCUMENT_REF_TYPES = DOCUMENT_REF_TYPES;
  DOCUMENT_TYPE = DOCUMENT_TYPE;
  isReadonly: boolean = false;

  constructor(
    private fb: FormBuilder,
    private service: SharedServiceService,
    public dialog: MatDialog,
    private messageService: MessageService,
    private pservice: PpFormService,
    private userService: UserService,
    private roleService: RoleService,
    private okmService: OKMService,
  ) {
    this.registersUser = new User();
  }

  ngOnInit(): void {
    this.createForm();
    // this.getRoleDetail();
    this.captchaErrorMsg = false;

    this.getState();
    this.getDistrict();
    this.getCentralDepartment();
    this.registerUserForm.controls['email'].valueChanges.pipe(
      debounceTime(1000)
    );

    this.getDocumentType()
  }

  async ErrorDialog(): Promise<void> {
    const dialogRef = this.dialog.open(ErrorMessageComponent, {
      width: '50%',
      height: 'auto',
      data: {
        errMsg: this.errorMsg,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.errorMsg = '';
    });
  }

  getStateDepartmentByCode(stateCode: any) {
    let code = stateCode.target.value;

    this.pservice.getStateDepartmentCentre(code).subscribe((resp) => {
      this.selectedStateDepartment = resp.data;
    });
    this.getSelectedDist(stateCode);

    this.stateList.forEach((arr) => {
      if (arr.code == code) {
        this.stateName = arr.name.toString();
      }
    });
    this.registerUserForm.patchValue({ state: code });
    this.registerUserForm.updateValueAndValidity();
    this.getSelectedDist(stateCode);
  }

  getCentralDepartment() {
    this.pservice.getCentralDepartment().subscribe((resp) => {
      this.centralDepartmentList = resp.data;
    });
  }

  ngAfterViewInit(): void {
    this.captchaErrorMsg = false;
    this.getCaptcha(6);
  }

  ngOnDestroy(): void {
    this.panNumber = '';
    this.registerUserForm.reset();
  }

  get f() {
    return this.registerUserForm.controls;
  }

  checkMobile(event: any) {
    if (this.check == true) {
      this.registerUserForm.patchValue({ mobile: '' });
    }
    this.check = !this.check;
  }

  checkDeclare(event: any) {
    this.checkDeclaration = !this.checkDeclaration;
  }

  getDocumentType() {
    this.okmService
      .getDocumentTypeByModuleID(MODULE_ID.USER_REGISTRATION, 2)
      .subscribe((res: DocumentType[]) => {
        this.documentTypes = res;
      });
  }

  getDocumentTypeMapping(type: string) {
    return this.documentTypes.find((val) => val.abbr === type) || {};
  }

  receivedMessageHandler(data: any) {
    this.registerUserForm.controls[data.controlName].setValue({
      id: 0,
      ref_id: data.refId,
      type: data.refType,
      proposal_no: data.proposal_no,
      document_mapping_id: data.docTypeMappingId,
      document_name: data.name,
      version: data.version,
      uuid: data.uuid,
    });
  }

  createForm() {
    let rname;
    let rcin;
    let rRegisterType;
    let rentityName: string = '';
    let rpan;
    let ryoc = '';
    this.service.updatedCommonDetail.subscribe((res) => {
      if (res.name == '') {
        this.enableSection = false;
      } else {
        this.enableSection = true;
      }
      rname = res.name;


      if (res.cin == '') {
        rcin = null;
      } else {
        rcin = res.cin;
      }
      ryoc = res.Yoc
      rRegisterType = res.registerType;
      rentityName = res.entityType;
      if (res.panNumber == '') {
        rpan = null;
      } else {
        rpan = res.panNumber;
      }
    });
    this.cEntityType = rentityName;
    this.registerUserForm = this.fb.group({
      name_of_Contact_Person: ['', [Validators.required]],
      address: [this.registersUser.address, [Validators.required]],
      email: [
        this.registersUser.email,
        [Validators.required, Validators.pattern(regexMap.regexEmailGovt)],
      ],
      mobile: [
        this.registersUser.mobile,
        [Validators.required, Validators.pattern(regexMap.regexMobile)],
      ],
      state_ut: [this.registersUser.state_ut, [Validators.required]],
      district: [this.registersUser.district, [Validators.required]],
      pincode: [
        this.registersUser.pincode,
        [Validators.required, Validators.pattern(regexMap.regexPinCode)],
      ],

      is_active: [true, []],
      is_delete: [false, []],
      security_question: ['', []],
      security_answer: ['', []],
      name_of_Entity: [rname, rname ? [Validators.required] : []],
      landline_No: [null, []],
      pan_no: [rpan],
      website: ['', []],
      id: [0, []],
      cin_no: [rcin],
      year_of_inc: [ryoc],
      state: [null, []],
      authority_letter: [null, [Validators.required]]
    });
  }

  checkEmail(email: any) {
    const regexgov = regexMap.regexEmailGovt;
    if (email.target.value.match(regexgov) == null) {
      this.emailError =
        'For this entity type Email Should end with jcboseust.ac/hpmail/gov/ac/nic.in';
    } else {
      this.emailError = '';
      this.service
        .getDuplicateEmail(email.target.value?.toUpperCase(), this.cEntityType)
        .subscribe((resp) => {
          if (resp.data) {
            this.duplicateEmail = 'YES';
          } else {
            this.duplicateEmail = 'NO';
          }
        });
    }
  }

  checkCaptcha(usercaptcha: any) {
    if (usercaptcha == this.captchatext) {
      this.userEnterCaptcha = '';
      this.isCaptcha = true;
      this.captchaErrorMsg = false;
    } else {
      this.getCaptcha(6);
      this.captchaErrorMsg = true;
      this.isCaptcha = false;
      this.userEnterCaptcha = '';
    }
  }

  captchaCaptured(captcha: any) {
    this.userEnterCaptcha = captcha.target.value;
  }

  getCaptcha(length: any) {
    this.captchatext = '';
    this.captcha.nativeElement.value = '';
    var pen: any = this.myCanvas.nativeElement.getContext('2d');
    var randomChars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    this.captchatext = '';
    for (var i = 0; i < length; i++) {
      this.captchatext += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    pen.font = '18px Roboto ';
    pen.fillStyle = '#000000';

    pen?.fillRect(0, 0, 126, 52);
    pen.fillStyle = '#FFFFFF';
    pen?.fillText(this.captchatext, 20, 32);
  }

  async openDialog(): Promise<void> {
    let val;
    if (this.registerUserForm.get('pan_no')?.value == null) {
      val = true;
    } else {
      val = false;
    }
    const dialogRef = this.dialog.open(ThankYouPageComponent, {
      width: '50%',
      disableClose: true,
      data: {
        entityType: this.cEntityType,
        isScrutiny: val,
      },
      height: 'auto',
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  getDistrict() {
    this.service.getDistrict().subscribe((dist) => {
      this.districtList = dist.data;
    });
  }

  getSelectedDist(event: any) {
    let stateCode = event.target.value;
    this.selectedDistrictList = this.districtList.filter((dis) => {
      return dis.state_code == stateCode;
    });
  }

  getState() {
    this.service.getStates().subscribe((resp) => {
      this.stateList = resp.data;
    });
  }

  checkStdCode(event: any) {
    let val = event.target.value;

    if (val.length == 0) {
      this.errorMsgStdCode = '';
      this.stdCode = val;
      return;
    }
    if (val.length == 1 || val.length > 6) {
      this.errorMsgStdCode = 'Please Enter Valid Std Code';
      this.stdCode = val;
    } else {
      this.errorMsgStdCode = '';
      this.stdCode = val;
      this.LandLineValidation();
    }
  }

  LandLineValidation() {
    let landLineNo: string = this.registerUserForm.get('landline_No')?.value;

    if (landLineNo == null || landLineNo.length == 0) {
      this.errorMsgLandLineNumber = '';
      this.errorMsgStdCode = '';
      return;
    }
    if (this.stdCode == '') {
      this.errorMsgStdCode = 'Please Enter Valid Std Code';
    } else if (!(landLineNo.length >= 6 && landLineNo.length < 9)) {
      this.errorMsgLandLineNumber = 'Enter Valid LandLine Number';
    } else {
      this.errorMsgLandLineNumber = '';
    }
  }

  onSubmit() {
    Object.keys(this.registerUserForm.controls).forEach((key) => {
      if (this.registerUserForm.controls[key].status === 'INVALID') console.log(key);
    });

    if (isEmpty(this.registerUserForm.value?.name_of_Entity)) {
      this.messageService.showError('Please add the name of project proponent entity');
      return;
    }

    if (this.errorMsgLandLineNumber != '' || this.errorMsgStdCode != '') {
      this.messageService.showError('Please Fill Correct Landline Number');
      return;
    }
    this.checkCaptcha(this.userEnterCaptcha);

    if (
      this.registerUserForm.invalid || !this.isCaptcha || !this.checkDeclaration
    ) {
      this.messageService.showError('Please fill the mandatory fields');
      this.validationCheck = true;
      this.getCaptcha(6);
      this.FileErrorMsg = '';
      return false;
    } else {
      this.validationCheck = false;
    }


    this.pservice
      .postUserFormGovt(
        { ...this.registerUserForm.value, pan_no: this.registerUserForm.value.pan_no ? this.registerUserForm.value?.pan_no?.toUpperCase() : this.registerUserForm.value.pan_no, email: this.registerUserForm.value?.email?.toLowerCase() },
        this.cEntityType
      )
      .subscribe(
        (msg) => {
          this.openDialog();
          this.submitForApproval(msg.data.UserId);
          this.assignRole(msg.data.UserId);
          this.registerUserForm.disable();
        },
        (error) => {
          let err: HttpErrorResponse = error;

          let errMsg: string = '';
          if (err.error instanceof Error) {
            errMsg = err.error.message;
          } else if (err.status == 200) {
            this.openDialog();
            this.registerUserForm.disable();
            this.submitForApproval(error.data.UserId);
            this.assignRole(error.data.UserId);
          } else if (err.status != 200) {
            this.errorMsg = JSON.stringify(err.error.message);
            this.isCaptcha = false;
            this.ErrorDialog();
          }
        }
      );

    this.getCaptcha(6);

    this.panNumber = '';
    this.msgs1 = [];

    return true;
  }

  submitForApproval(userId: number) {
    this.userService.submitForApproval(userId).subscribe((res) => {
    });
  }

  assignRole(userId: number) {
    this.userService
      .assignRole([
        {
          entityId: null,
          role: {
            entityId: ROLE_IDS.PROJECT_PROPONENT,
          },
          user: {
            entityId: userId,
          },
          workgroup: {
            entityId: 1,
          },
          disableLinkOfficer: false,
        },
      ])
      .subscribe((res) => {
      });
  }
}
