import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Pan } from 'src/app/models/master/pan.model';
import { GenericResponse } from 'src/app/models/shared/generic-response.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PanServiceService {
  constructor(private http: HttpClient) {}
  checkDuplicatePan(pan: string, entType: string): Observable<GenericResponse> {
    let url = environment.baseURL + 'user/duplicatePan';
    const param = new HttpParams()
      .append('pan', pan.trim())
      .append('entityType', entType);
    return this.http.get<GenericResponse>(url, { params: param });
  }

  checkDuplicatePanKyc(pan: string, entType: string): Observable<GenericResponse> {
    let url = environment.adminUrl + 'duplicatePan';
    const param = new HttpParams()
      .append('pan', pan.trim())
      .append('entityType', entType);
    return this.http.get<GenericResponse>(url, { params: param });
  }

  getPan = (data: any): Observable<any> => this.http.post<Pan>(environment.baseUrlPanNew, data);
}
