<div class="card shadow" style="padding-top: 42px;">
    <div class="card-header py-3 d-flex align-items-center justify-content-between">
        <h1 class="m-0 font-weight-bold h3">GEN CODE MASTER</h1>
    </div>
    <div class="card-body">
        <p-table [value]="parentList" [rows]="10" [paginator]="true" [rowHover]="true" dataKey="id"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [showCurrentPageReport]="true" [globalFilterFields]="['parent_grp']">
            #table

            >
            <ng-template pTemplate="header">
                <tr>
                    <th>Gen Code Types</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product>
                <tr>
                    <td>
                        <a routerLink="/GenCode/code" [queryParams]="{type: product}">
                            {{product}}
                        </a>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>