import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { GenericResponse } from '../models/shared/generic-response.model';
import { API_EDS_QUERY } from '../shared/app-constant';

@Injectable({
  providedIn: 'root',
})
export class EdsQueryService {
  constructor(private http: HttpClient) {}

  updateEdsQuery(body: any) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API_EDS_QUERY.UPDATE_EDS_QUERY,
      body
    );
  }

  edsReport(type: number) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API_EDS_QUERY.EDS_REPORT,
      {},
      { params: { status: type } }
    );
  }

  getEdsQueries(id: number) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API_EDS_QUERY.GET_EDS_QUERIES,
      {},
      { params: { id } }
    );
  }

  submitEdsQueries(body: any, clearance_id: string) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API_EDS_QUERY.SUBMIT_EDS_QUERIES,
      body,
      { params: { clearance_id } }
    );
  }

  getQueries(application_id: number, eds_form_id?: any) {
    let params: any = { application_id };
    if (eds_form_id) params = { ...params, eds_form_id };
    return this.http.post<GenericResponse>(
      environment.baseURL + API_EDS_QUERY.GET_QUERIES,
      {},
      { params: params }
    );
  }

  deleteQuery(id: number) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API_EDS_QUERY.DELETE_EDS_QUERY,
      {},
      { params: { id } }
    );
  }

  getQueriesForHistory(eds_form_id: number) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API_EDS_QUERY.GET_QUERIES_FROM_HISTORY,
      {},
      { params: { eds_form_id } }
    );
  }
}
