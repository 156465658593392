import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, shareReplay } from 'rxjs';

import { API } from '../shared/app-constant';
import { GenCode } from 'src/app/models/master/gen-code.model';
import { GenericResponse } from '../models/shared/generic-response.model';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GenCodeServiceService {
  constructor(private http: HttpClient) {}

  getGenCode(): Observable<GenCode[]> {
    let url = environment.baseURL + API.GET_GEN_CODE;
    return this.http.get<GenCode[]>(url);
  }

  getDistinctGenCode(): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_DISTINCT_GEN_CODE;
    return this.http.post<GenericResponse>(url, null);
  }

  getGenderGenCode(body: any): Observable<GenCode[]> {
    let param = new HttpParams().append('type', body.type);
    let url = environment.baseURL + API.GET_GENDER_GEN_CODE;
    return this.http.post<GenCode[]>(url, null, { params: param }).pipe(shareReplay(1));
  }

  getAllGenderGenCode(body: any): Observable<GenCode[]> {
    let param = new HttpParams()
      .append('type', body.type)
      .append('active', false);
    let url = environment.baseURL + API.GET_GENDER_GEN_CODE;
    return this.http.post<GenCode[]>(url, null, { params: param });
  }

  deleteGenCode(body: any): Observable<GenCode> {
    let url = environment.baseURL + API.DELETE_GEN_CODE;
    return this.http.post<GenCode>(url, body, {
      responseType: 'text' as 'json',
    });
  }

  updateGenCode(body: any): Observable<GenCode> {
    let url = environment.baseURL + API.UPDATE_GEN_CODE;
    return this.http.put<GenCode>(url, body, {
      responseType: 'text' as 'json',
    });
  }

  createGenCode(body: any): Observable<GenCode> {
    let url = environment.baseURL + API.ADD_GEN_CODE;
    return this.http.post<GenCode>(url, body, {
      responseType: 'text' as 'json',
    });
  }

}
