import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderTopComponent } from './header-top/header-top.component';
import { HeaderLogoComponent } from './header-logo/header-logo.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { SidebarMenuComponent } from './sidebar-menu/sidebar-menu.component';
import { FooterComponent } from './footer/footer.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { BaseLayoutComponent } from './base-layout/base-layout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '../services/menu-service';
import { PipeModule } from '../shared/pipes/pipe.module';

@NgModule({
  imports: [CommonModule, RouterModule.forChild([]), NgbModule, PipeModule],
  exports: [MainLayoutComponent, AdminLayoutComponent, BaseLayoutComponent],
  declarations: [
    MainLayoutComponent,
    AdminLayoutComponent,
    BaseLayoutComponent,
    HeaderTopComponent,
    HeaderLogoComponent,
    MainMenuComponent,
    SidebarMenuComponent,
    FooterComponent,
  ],
  providers: [MenuService],
})
export class LayoutsModule {}
