import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from 'src/app/layouts/main-layout/main-layout.component';
import { environment } from 'src/environments/environment';
import { HomeComponent } from '../home/home.component';
import { HomeLegacyComponent } from './home-legacy.component';

const routesProd: Routes = [{ path: '', component: HomeLegacyComponent }];
const routesDev: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [{ path: '', component: HomeComponent }],
  },
];

@NgModule({
  imports: [RouterModule.forChild((environment.production ? routesProd : routesDev))],
  exports: [RouterModule],
})
export class HomeLegacyRoutingModule {}
