<footer class="footer">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-lg-auto text-center">
        <img
          class="footer-logo"
          src="./assets/images/footer-logo.svg"
          alt="parivesh moefcc"
        />
      </div>
      <div class="col-lg">
        <div class="row">
          <div class="col-md text-center text-sm-start">
            <a class="footer-link" routerLink="/cms/about">About</a
            ><a class="footer-link" routerLink="/cms/contact">Contact Us</a
            ><a class="footer-link" routerLink="/cms/faq">FAQs</a>
            <div class="total-visitors">
              Total Visitors
              <span class="visitors-count">{{ visitorCount?.total }}</span>
            </div>
          </div>
          <div class="col-md-auto">
            <div class="social-medias text-center text-md-end">
              <a
                class="social-media-link"
                href="https://www.facebook.com/moefcc"
                target="_blank"
              >
                <img src="./assets/images/Vector2.svg" />
              </a>
              <a
                class="social-media-link"
                href="https://twitter.com/moefcc"
                target="_blank"
              >
                <img src="./assets/images/Vector1.svg" /> </a
              ><a
                class="social-media-link"
                href="https://www.instagram.com/moefccgoi/"
                target="_blank"
              >
                <img src="./assets/images/instagram.svg" />
              </a>
            </div>
          </div>
        </div>
        <div class="footer-info-links col-sm-12">
          <a class="footer-link" routerLink="/cms/privacy-policy"
            >Privacy Policy</a
          ><a class="footer-link" routerLink="/cms/terms-of-use">Terms of Use</a
          ><a class="footer-link" routerLink="/cms/hyperlink-policy"
            >Hyperlink Policy</a
          ><a class="footer-link" routerLink="/cms/copyright-policy"
            >Copyright Policy</a
          ><a class="footer-link" routerLink="/cms/accessibility-statement"
            >Accessibility Statement</a
          ><a class="footer-link" routerLink="/cms/disclaimer">Disclaimer</a>
        </div>
      </div>
    </div>
    <div class="footer-last-update text-white mx-auto">
      Last Updated on <br />
      {{lastUpdatedDate | date}}
    </div>
    <div class="footer-end text-white mx-auto text-center">
      For any Technical support, Please Contact EFCCID, NIC, New Delhi,
      <a href="mailto:cpc-parivesh@gov.in" class="text-white"
        >cpc-parivesh@gov.in</a
      >
    </div>
  </div>
</footer>
