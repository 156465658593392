import { createAction, props } from "@ngrx/store";

export const loadJsDashboardProposalList = createAction("[JS Dashboard] Load Dashboard Proposal List");

export const loadJsDashboardProposalListSuccess = createAction("[JS Dashboard] Load Dashboard Proposal List Success",
  props<{ proposals: any }>());


/*Department Dashboard*/

export const loadDepartmentDashboardProposalList = createAction("[Department Dashboard] Load Department Dashboard Proposal List");

export const loadDepartmentDashboardProposalListSuccess = createAction("[Department Dashboard] Load Department Dashboard Proposal List Success", props<{ proposals: any }>());
export const updateIsLoadedFlagToFalseOnTabChange = createAction("[IsLoaded] Update IsLoaded Flag To False OnTab Change");

export const getOneDepartmentDashboardProposal = createAction("[Department Dashboard] Get One department Dashboard");

export const loadAllProjects = createAction("[Organisation Dashboard] Load All Projects", props<{ page: number, size: number }>());

export const loadAllProjectsSuccess = createAction("[Organisation Dashboard] Load All Projects Success",
  props<{ projects: any[] }>());

export const addProject = createAction("[Organisation Add Project] Add Project",
  props<{ project: any }>());

export const loadAllSectors = createAction('[Js Dashboard] Load All Sectors');

export const  loadAllSectorsSuccess = createAction('[JS Dashboard] Load All Sectors Success',props<{sectors : any[]}>())

