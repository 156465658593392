import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup } from '@angular/forms';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageService } from 'src/app/services/message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OKMService } from 'src/app/services/okm.services';
import { TranslateService } from '@ngx-translate/core';
import { isEmpty } from 'lodash';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss'],
})
export class UploadDocumentComponent {
  @ViewChild('uploadDocument') uploadDocument!: ElementRef;
  @Input() formGroup!: FormGroup;
  @Input() control: any;
  @Input() type: string = 'file';
  @Input() placeholder = 'Browse or drop your file here';
  @Input() errorMessage!: string | boolean;
  @Input() extraClass: string | string[] = '';
  @Input() documentMapping: any = {};
  @Input() refId: any = 0;
  @Input() uuid: string = '';
  @Input() version: string = '';
  @Input() refType: string = '';
  @Input() name: string = '';
  @Input() isRequired: boolean = true;
  @Input() isReadOnly: boolean = false;
  @Input() isOnlyButtonVisible: boolean = false;
  @Output() onBlur: EventEmitter<boolean>;
  @Output() shareData = new EventEmitter();
  @Output() deleteData = new EventEmitter();
  preview = true;
  previewPopup: any;
  @Input() isImage: boolean = false;

  objectFn = Object;

  tempFileName: string | undefined = '';
  isFilePresent: boolean = false;
  isLoading = true;
  @ViewChild('previewModel') previewModel: any;
  @ViewChild('kmlmodal') kmlmodal: any;
  showView: boolean = false;
  currentGisUrl: any;
  imageExtensions: any[] = ['png', 'jpg', 'jpeg'];

  constructor(
    private ref: ChangeDetectorRef,
    private messageService: MessageService,
    private service: OKMService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private loaderService: LoaderService
  ) {
    this.onBlur = new EventEmitter();
  }

  blur() {
    this.onBlur.emit(true);
  }

  saveFile(event: any) {
    let tempfile = event[0];
    if (tempfile == undefined) {
      tempfile = event.files[0];
    }

    if (tempfile == undefined) {
      this.isFilePresent = false;
      this.tempFileName = '';
    } else {
      this.tempFileName = tempfile.name;
      this.isFilePresent = true;

      this.uploadFile(tempfile);
    }
  }

  uploadFile(file: File) {
    if (file.name?.includes('docx')) this.preview = false;
    else this.preview = true;
    this.service
      .upload(
        this.refId,
        file,
        file.name,
        this.documentMapping.documentTypeId,
        this.refType
      )
      .subscribe(
        (res) => {
          this.shareData.emit({
            ...res,
            controlName: this.name,
          });
        },
        (err) => {
          this.messageService.showError(
            this.translateService.instant(
              err.error?.message || 'generic.upload_failed'
            )
          );
        }
      );
  }

  downloadFile() {
    this.service
      .downloadDocument(
        this.getUUID(this.control?.value),
        this.getVersion(this.control?.value),
        this.documentMapping.documentTypeId,
        this.refId,
        this.refType
      )
      .subscribe(
        (res: any) => {
          let url = window.URL.createObjectURL(res);
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = this.getFileName(this.control?.value);
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        },
        (err) => {
          this.messageService.showError(
            this.translateService.instant('generic.download_failed')
          );
        }
      );
  }

  deleteUploadFile() {
    this.isFilePresent = false;
    this.control.value = null;
    this.uploadDocument.nativeElement.value = null;
    this.deleteData.emit({ formGroup: this.formGroup, status: true });
  }

  getFileName(file: any) {
    this.isFilePresent = true;
    return file?.document_name;
  }

  getUUID(file: any) {
    return file?.uuid;
  }

  getVersion(file: any) {
    return file?.version;
  }

  getFilePresent(file: any) {
    return !isEmpty(file);
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  closePreviewModal() {
    this.previewPopup.dismiss();
  }

  ngOnDestroy() {
    this.modalService.dismissAll();
  }

  previewFile() {
    console.log(this.control?.value);
    let imageName = this.control?.value?.document_name;
    let isKML = this.control?.value?.document_name.split('.')[1] == 'kml';
    if (this.imageExtensions.includes(imageName.split('.')[imageName.split('.').length - 1]))
      this.isImage = true;
    if (isKML) {
      this.previewPopup = this.modalService.open(this.kmlmodal, {
        centered: true,
        size: 'xl',
      });
      this.currentGisUrl = `${environment.baseURLCafGis}?uuid=${this.getUUID(
        this.control?.value
      )}&version=${this.getVersion(this.control?.value)}&documenttypeamapid=${this.documentMapping.documentTypeId
        }&uploaded_name=${this.control?.value?.document_name}&ref_id=${this.refId
        }&ref_type=${this.refType}`;
      return;
    } else {
      this.loaderService.showLoader();
      this.previewPopup = this.modalService.open(this.previewModel, {
        centered: true,
        size: 'xl',
      });
      this.showView = true;
    }
    this.service
      .downloadDocument(
        this.getUUID(this.control?.value),
        this.getVersion(this.control?.value),
        this.documentMapping.documentTypeId,
        this.refId,
        this.refType
      )
      .subscribe(
        (res) => {
          let blob: any;
          if (this.isImage) blob = new Blob([res], { type: 'image/png' });
          else blob = new Blob([res], { type: 'application/pdf' });
          let iframe: any = document.querySelector('iframe');
          iframe.src = URL.createObjectURL(blob) + '#toolbar=0';
          if (document.readyState == 'complete') {
            // this.text = "completed"
          }
          iframe.loading = 'lazy';
        },
        (err) => {
          this.messageService.showError(
            this.translateService.instant('generic.preview_failed')
          );
          this.closeModal();
        }
      );
  }
}
