import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CinService } from 'src/app/services/user-registration/cin.service';
import { ModalComponent } from '../panmodal/modal.component';

interface DialogData {
  cin: string;
  fullName: string;
  entType: string;
  year: string;
}
@Component({
  selector: 'app-cin-modal',
  templateUrl: './cin-modal.component.html',
  styleUrls: ['./cin-modal.component.scss'],
})
export class CinModalComponent implements OnInit {
  constructor(
    private cinService: CinService,
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}
  statusCode: string = 'intial';
  isDuplicate: string = 'intial';

  cin: string = '';
  entityType: string = '';

  ngOnInit(): void {
    this.cin = this.data.cin.toUpperCase();
    this.checkDuplicateCin(this.cin)
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  checkDuplicateCin(cin:string){
    this.cinService.checkDuplicateCin(cin,this.data.entType).subscribe({
      next:(res)=>{
        if(res.data){
          this.isDuplicate='duplicate'
          this.statusCode='NA'
        }else{
          this.isDuplicate='notDuplicate'
          this.getCinDetails(this.cin);
        }
      }
    
    })
  }
  getCinDetails(cin: string) {
    this.cinService.getCin(cin).subscribe((res) => {
    
        if (res.data.getCINInfoResponse._return.cin == '') {
          this.statusCode = 'N';
          this.isDuplicate = 'notDuplicate';
          return;
        }
        this.statusCode = 'E';
        this.isDuplicate = 'notDuplicate';
        this.data.fullName = res.data.getCINInfoResponse._return.companyName;
        this.data.year =
          res.data.getCINInfoResponse._return.incorpdate.split('/')[2];
      
    });
  }
}
