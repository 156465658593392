import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { PpFormComponent } from './pp-form/pp-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './user-registration/material.module';
import { ModalComponent } from './panmodal/modal.component';
import { UserRoutingModule } from './user-routing.module';
import { PpGovtDepartComponent } from './pp-form/pp-govt-depart/pp-govt-depart.component';
import { ThankYouPageComponent } from './success-message-dialog/thank-you-page.component';
import { MatStepperModule } from '@angular/material/stepper';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { ConsultantFormComponent } from './consultant-form/consultant-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { InputModule } from 'src/app/shared/components/form-elements/input/input.module';
import { PipeModule } from 'src/app/shared/pipes/pipe.module';
import { CinModalComponent } from './cin-modal/cin-modal.component';
import { UploadDocumentModule } from 'src/app/shared/components/form-elements/upload-document/upload-document.module';

@NgModule({
  declarations: [
    UserRegistrationComponent,
    PpFormComponent,
    ModalComponent,
    PpGovtDepartComponent,
    ThankYouPageComponent,
    ErrorMessageComponent,
    ConsultantFormComponent,
    ChangePasswordComponent,
    CinModalComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    MatStepperModule,
    UserRoutingModule,
    DirectivesModule,
    TranslateModule,
    InputModule,
    PipeModule,
    UploadDocumentModule
  ],
  exports: [UserRegistrationComponent],
})
export class UserRegistrationModule {}
