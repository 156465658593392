<div class="card1" style="font-family: 'Lato'">
  <div class="form-horizontal container-fluid">
    <form [formGroup]="form">
      <ng-template><b>Details</b></ng-template>
      <div class="card-body">
        <div class="pb-2 pt-2 row">
          <div class="col-sm-3">
            <label for="accreditation_no" class="inputLabel"
              >Accreditation No<b class="errorMsg">*</b></label
            >
          </div>
          <div class="col-sm-6 form-group z-index-up">
            <input
              type="text"
              class="form-control inputField"
              formControlName="accreditation_no"
              id="accreditation_no"
              name="accreditation_no"
              readonly
            />
          </div>
        </div>
        <div class="pb-2 pt-2 row">
          <div class="col-sm-3">
            <label for="name" class="inputLabel"
              >Organization Name<b class="errorMsg">*</b></label
            >
          </div>
          <div class="col-sm-6 form-group z-index-up">
            <input
              type="text"
              class="form-control inputField"
              formControlName="name"
              id="name"
              name="name"
              readonly
            />
          </div>
        </div>
        <div class="pb-2 pt-2 row">
          <div class="col-sm-3">
            <label for="organization_head" class="inputLabel"
              >Head of the Organization<b class="errorMsg">*</b></label
            >
          </div>
          <div class="col-sm-6 form-group z-index-up">
            <input
              type="text"
              class="form-control inputField"
              formControlName="organization_head"
              id="organization_head"
              name="organization_head"
              readonly
            />
          </div>
        </div>
        <div class="pb-2 pt-2 row">
          <div class="col-sm-3">
            <label for="designation" class="inputLabel"
              >Designation<b class="errorMsg">*</b></label
            >
          </div>
          <div class="col-sm-6 form-group z-index-up">
            <input
              type="text"
              class="form-control inputField"
              formControlName="designation"
              id="designation"
              name="designation"
              readonly
            />
          </div>
        </div>
        <div class="pb-2 pt-2 row">
          <div class="col-sm-3">
            <label for="address" class="inputLabel"
              >Address<b class="errorMsg">*</b></label
            >
          </div>
          <div class="col-sm-6 form-group z-index-up">
            <textarea
              class="form-control inputField"
              formControlName="address"
              id="address"
              name="address"
              readonly
            ></textarea>
          </div>
        </div>
        <div class="pb-2 pt-2 row">
          <div class="col-sm-3">
            <label for="email_id" class="inputLabel"
              >Email Id<b class="errorMsg">*</b></label
            >
          </div>
          <div class="col-sm-6 form-group z-index-up">
            <input
              type="text"
              class="form-control inputField"
              formControlName="email_id"
              id="email_id"
              name="email_id"
              readonly
            />
          </div>
        </div>
        <div class="pb-2 pt-2 row">
          <div class="col-sm-3">
            <label for="landline_no" class="inputLabel">Landline No</label>
          </div>
          <div class="col-sm-6 form-group z-index-up">
            <input
              type="text"
              class="form-control inputField"
              formControlName="landline_no"
              id="landline_no"
              name="landline_no"
              [maxLength]="13"
              placeholder="Enter Landline Number"
              [readonly]="isLandline.length"
            />
          </div>
        </div>
        <div class="pb-2 pt-2 row">
          <div class="col-sm-3">
            <label for="mobile_no" class="inputLabel"
              >Mobile No<b class="errorMsg">*</b></label
            >
          </div>
          <div class="col-sm-6 form-group z-index-up">
            <input
              type="text"
              class="form-control inputField"
              formControlName="mobile_no"
              id="mobile_no"
              name="mobile_no"
              readonly
            />
          </div>
        </div>
        <div class="pb-2 pt-2 row">
          <div class="col-sm-3">
            <label for="pan" class="inputLabel"
              >Pan No<b class="errorMsg">*</b></label
            >
          </div>
          <div class="col-sm-6 form-group z-index-up">
            <input
              type="text"
              class="form-control inputField"
              formControlName="pan"
              id="pan"
              name="pan"
              readonly
            />
          </div>
        </div>
        <div class="pb-2 pt-2 row">
          <div class="col-sm-3">
            <label for="category" class="inputLabel"
              >Category<b class="errorMsg">*</b></label
            >
          </div>
          <div class="col-sm-6 form-group z-index-up">
            <input
              type="text"
              class="form-control inputField"
              formControlName="category"
              id="category"
              name="category"
              readonly
            />
          </div>
        </div>
        <div class="pb-2 pt-2 row">
          <div class="col-sm-3">
            <label for="accreditation_sectors" class="inputLabel"
              >Sectors Of Accreditation<b class="errorMsg">*</b></label
            >
          </div>
          <div class="col-sm-6 form-group z-index-up">
            <input
              type="text"
              class="form-control inputField"
              formControlName="accreditation_sectors"
              id="accreditation_sectors"
              name="accreditation_sectors"
              readonly
            />
          </div>
        </div>
        <div class="pb-2 pt-2 row">
          <div class="col-sm-3">
            <label for="accreditation_validity" class="inputLabel"
              >Validity Of Accreditation<b class="errorMsg">*</b></label
            >
          </div>
          <div class="col-sm-6 form-group z-index-up">
            <input
              type="text"
              class="form-control inputField"
              formControlName="accreditation_validity"
              id="accreditation_validity"
              name="accreditation_validity"
              readonly
            />
          </div>
        </div>
        <div class="pb-2 pt-2 row">
          <div class="col-sm-3">
            <label for="website" class="inputLabel">Website</label>
          </div>
          <div class="col-sm-6 form-group z-index-up">
            <input
              type="text"
              class="form-control inputField"
              formControlName="website"
              id="website"
              name="website"
              appBlockCopyPaste
              autocomplete="Off"
              formControlName="website"
              placeholder="www.example.com"
            />
          </div>
        </div>

        <!-- captcha start -->

        <div class="col-sm-4"></div>
        <div class="row">
          <div class="col-sm-3">
            <label for="email">Captcha Code<b class="text-red">*</b> </label>
          </div>
          <div class="col-sm-2 z-index-up">
            <canvas
              style="border-radius: 4px"
              width="126px"
              height="52px"
              #myCanvas
            ></canvas>
          </div>
          <div class="col-sm-2 z-index-up">
            <button (click)="getCaptcha(6)" type="button" class="btnCaptcha">
              <span class="btnCaptchatext">&#10226; Refresh</span>
            </button>
          </div>
          <div class="col-sm-3 z-index-up">
            <input
              class="captcha-input form-control"
              appBlockCopyPaste
              type="text"
              aria-describedby="error-captcha"
              #captcha
              placeholder="Enter Captcha"
              (blur)="captchaCaptured($event)"
              maxlength="6"
            />
          </div>
          <div class="col-sm-2"></div>
          <div class="col-sm-3 offset-sm-3">
            <p *ngIf="isCaptcha" class="text-success">&#10004;</p>
            <span
              *ngIf="captchaErrorMsg"
              id="error-captcha"
              class="p-error text-danger"
              >Captcha mismatch .</span
            >
          </div>
        </div>

        <!-- captcha end -->
      </div>

      <div class="p-4" class="col-sm-12 row">
        <div class="col-sm-2 offset-sm-1">
          <span class="btnCancel" routerLink="/home">Cancel</span>
        </div>
        <div class="col-sm-1 offset-sm-5 pl-7">
          &nbsp;&nbsp;
          <button
            type="button"
            class="btncustom z-index-up flex-end"
            style="position: relative"
            (click)="onSubmit()"
          >
            <p class="btntext">Proceed</p>
          </button>
        </div>
      </div>
      <br />
      <div class="alert alert-warning form-note" role="alert">
        <div>
          <i class="bi bi-exclamation-triangle" style="font-size: 18px"></i>
          <b>&nbsp;&nbsp;&nbsp;Note:</b>
        </div>
        <div>
          <i class="bi bi-arrow-right-circle-fill" style="font-size: 18px"></i
          >&nbsp;&nbsp;&nbsp; If any of the detail populated above are found
          mismatched/missed, Please contact QCI for correction.
        </div>
        <div>
          <i class="bi bi-arrow-right-circle-fill" style="font-size: 18px"></i
          >&nbsp;&nbsp;&nbsp; Once Registration application is submitted the
          activation mail will be sent to the registered Email Id, Click on the
          activation link within 24* hours to activate your login.
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #successModal let-modal>
  <div class="modal-body my-5">
    <div class="text-center">
      <h1 class="h3">Registration Successful</h1>
    </div>
    <div>
      <p class="text-center">
        Your details have been successfully submitted for Registration as
        <strong>Accredited Consultant Organization</strong>.
      </p>
      <div class="alert alert-primary text-center" role="alert">
        An Email has been sent to your registered email id <br />
        with <strong>Login credential and Activation link</strong>.
      </div>
      <p class="text-center">
        Once activated you will log in and proceed for your Individual
        Consultants activation.
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <a
      class="btn btn-primary mn-w-100"
      (click)="modal.close()"
      routerLink="/home"
    >
      {{ "generic.done" | translate }}
    </a>
  </div>
</ng-template>
