<!-- Banners Section -->
<div class="card shadow" style="padding-top: 42px;">
  <div class="card-header py-3 d-flex align-items-center justify-content-between">
    <h1 class="m-0 font-weight-bold h3">{{registrationType}}</h1>
    <div>
      <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success p-mr-2"
        (click)="openNew()"></button>

    </div>
  </div>
  <div class="card-body">
    <p-table [value]="genCodeList" [rows]="10" [paginator]="true" [rowHover]="true" dataKey="id"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true"
      [globalFilterFields]="['parent_grp']">
      #table

      >
      <ng-template pTemplate="header">
        <tr>
          <th>Id</th>
          <th>Label</th>
          <th>Abbreviation</th>
          <th>Status</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td>
            {{ product.id }}
          </td>
          <td>{{product.name}}</td>
          <td>{{product.abbr}}</td>
          <td>{{ product.is_active? 'Active' :'Inactive'}}</td>
          <td>
            <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editGenCodeSection(product)"></button>
            &nbsp;&nbsp;
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<p-dialog [(visible)]="productDialog" [style]="{ width: '450px' }" header="Add New" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <form class="col-sm-12" [formGroup]="genCodeForm">
      <div class="mb-3">
        <label for="name" class="form-label">
          Name
        </label>
        <div>
          <input type="text"
          class="form-control"
          id="name"
          formControlName="name" maxlength="1000" />
          <div
            class="errorMsg"
            *ngIf="
            genCodeForm.controls['name'].invalid &&
              validationCheck
            "
            >{{ "generic.required" | translate }}
        </div>
        </div>
      </div>

      <div class="mb-3">
        <label for="abbreviation" class="form-label">
          Abbreviation
        </label>
        <div>
          <input
          type="text"
          class="form-control"
          id="abbr"
          formControlName="abbr" maxlength="1000" />
          <span
          class="errorMsg"
          *ngIf="
          genCodeForm.controls['abbr'].invalid &&
            validationCheck
          "
          >{{ "generic.required" | translate }}
        </span>
        </div>
      </div>
      <div class="mb-3">
        <label for="value" class="form-label">Value</label>
        <div>
          <input
          type="text"
          class="form-control"
          id="val"
          formControlName="val"
          maxlength="1000" />
          <span
            class="errorMsg"
            *ngIf="
            genCodeForm.controls['val'].invalid &&
              validationCheck
            "
            >{{ "generic.required" | translate }}
          </span>
        </div>
      </div>
      <div class="mb-3">
        <label for="parent_grp" class="form-label">Parent Group </label>
        <div>
          <input
          type="text"
          class="form-control"
          id="parent_grp"
          formControlName="parent_grp"
          maxlength="255" />
          <span
            class="errorMsg"
            *ngIf="
            genCodeForm.controls['parent_grp'].invalid &&
              validationCheck
            "
            >{{ "generic.required" | translate }}
          </span>
        </div>
      </div>

      <div class="mb-3">
        <label class="form-label">Status</label>
        <div>
          <p-selectButton
          [options]="stateOptions"
          optionLabel="label"
          optionValue="value"
          formControlName="is_active">
          </p-selectButton>
          <span
            class="errorMsg"
            *ngIf="
            genCodeForm.controls['is_active'].invalid &&
              validationCheck
            "
            >{{ "generic.required" | translate }}
          </span>
        </div>
      </div>


    </form>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-success"
    (click)="saveForm()"></button>
  </ng-template>
</p-dialog>
