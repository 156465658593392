import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { isEmpty } from 'lodash';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageService } from 'src/app/services/message.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-activation-page',
  templateUrl: './activation-page.component.html',
  styleUrls: ['./activation-page.component.scss'],
})
export class ActivationPageComponent implements OnInit {
  token: string = '';
  email: string = '';
  username: string = '';
  isError: boolean = false;
  check: string = '';
  isSubmit: boolean = false;
  data: any;
  status!: number;
  isTokenExpired: boolean = false;
  isMailSent: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private router: Router,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.token = params['token'];
      this.email = params['email'];
      this.username = params['username'];
      this.status = params['status'];
    });
  }

  ngOnInit(): void {
    this.process();
  }

  async process() {
    if (await this.checkIfTokeExpired()) {
      this.userService.resendEmail(this.email).subscribe({
        next: () => this.isMailSent = true,
      });
    } else {
      if (isEmpty(this.status)) {
        this.loaderService.showLoader();
        this.getAadharSignature();
      } else if (this.status == 1) {
        this.messageService.showSuccess(
          this.translateService.instant('registration.aadhar_verified')
        );
        this.router.navigate(['/userRegistration/change-password'], {
          queryParams: {
            email: this.email,
          },
        });
      } else {
        this.messageService.showError(
          this.translateService.instant('generic.error')
        );
      }
    }
  }

  checkIfTokeExpired() {
    return new Promise<boolean>((resolve) => {
      this.userService.verifyToken(this.token).subscribe({
        next: (response) => {
          this.isTokenExpired = response?.data == 'Token has been expired';
          resolve(response?.data == 'Token has been expired');
        },
      });
    });
  }

  getAadharSignature() {
    this.userService
      .getAadharSignature(this.token, this.email, this.username)
      .subscribe({
        next: (response) => {
          this.data = response;
          setTimeout(() => {
            this.loaderService.hideLoader();
            let element: any = document.querySelector('#eSignForm');
            element.submit();
          }, 1000);
        },
        error: () => {
          this.loaderService.hideLoader();
          this.messageService.showError(
            this.translateService.instant('generic.error')
          );
          this.router.navigate(['/']);
        },
      });
  }
}
