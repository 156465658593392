<div
  class="row"
  [formGroup]="formGroup"
  [ngClass]="extraClass"
  [class.invalid]="control.touched && control.invalid"
>
  <label
    class="col-form-label col-5" [ngClass]="{'d-flex': tooltip}"
    [class.field-required]="(isRequired || isRequiredAddRow) && !isHideRequiredSign"
    *ngIf="label"
    id="{{ 'label_' + id }}"
    >{{ label | translate }}
    <span>
      <mat-icon
      *ngIf="tooltip"
      [matTooltip]="tooltip"
      [matTooltipDisabled]="!tooltip"
      matTooltipPosition="right"
      class="ms-1"
      >help</mat-icon
    >
    </span>
    </label
  >
  <div [class]="label ? 'col-7' : 'col-12'">
    <div class="input-group">
      <div class="w-100" [ngClass]="{ 'row': isSearchAllowed }">
        <div [ngClass]="{ 'col-9': isSearchAllowed }">
          <input
            class="form-control {{ class }}"
            [type]="type"
            [attr.name]="name"
            [attr.id]="id"
            [formControl]="control"
            [maxlength]="maxLength"
            [max]="max"
            [minLength]="minLength"
            [min]="min"
            [step]="step"
            [readonly]="readonly"
            placeholder="{{ placeholder | translate }}"
            autocomplete="off"
            (blur)="blur()"
            (keydown)="keyDownEvent($event, type)"
            [ngClass]="{ 'd-none': showPreview }"
            [value]="value"
            [checked]="checked"
            [disabled]="disabled"
            (change)="appendZero()"
          />
        </div>
        <div class="col-3 d-flex align-items-center" *ngIf="isSearchAllowed">
          <button
          [disabled]="isButtonDisable"
            class="btn btn-primary" [ngClass]="{'btn-sm': isSmallSearchButton, 'w-100': !isRemoveW100}"
            type="button"
            (click)="searchProposal()"
          >
            Search
          </button>
        </div>
      </div>

      <p class="mt-3 mb-0" *ngIf="isAmountInWords && control?.value">
        <span class="">Amount in Words : </span>
        <span class="normal fw-bold">{{
          control?.value
            | amountinwords : isAmountInCrore : isAmountInLakhs : isLakhsWord
        }}</span>
      </p>

      <!-- Preview Control -->
      <div class="form-control input-preview" readonly *ngIf="showPreview">
        {{ control.value }}
      </div>
      <!-- Remark Checkbox  -->
      <div
        class="input-group-text remark"
        title="Add Remark"
        *ngIf="showPreview"
      >
        <input
          class="form-check-input mt-0"
          type="checkbox"
          id="{{ 'checkbox_' + id }}"
          (change)="onChangeCheckbox($event, 'checkbox_' + id)"
          aria-label="Checkbox for remark text input"
        />
      </div>
    </div>

    <div class="error-msg err_sec" *ngIf="control.errors && control.touched">
      <!-- {{ control.errors[objectFn.keys(control.errors)[0]] | translate }} -->
      {{
        (control.errors["required"]
          ? "generic.required"
          : control.errors["max"]
          ? "generic.maxValue"
          : ""
        ) | translate
      }}
      {{ (control.errors["pattern"] ? "generic.invalid_url" : "") | translate }}
      {{
        control.errors["max"]
          ? max
          : ((control.errors["min"] ? "generic.minZero" : "") | translate)
      }}
      {{
        control.errors["invalid_pan"]
          ? (control.errors["invalid_pan"] | translate)
          : ""
      }}
      {{
        control.errors["invalid_pincode"]
          ? (control.errors["invalid_pincode"] | translate)
          : ""
      }}
      {{
        control.errors["invalid_mobile"]
          ? (control.errors["invalid_mobile"] | translate)
          : ""
      }}
      {{
        control.errors["invalid_email"]
          ? (control.errors["invalid_email"] | translate)
          : ""
      }}
      {{
        control.errors["invalid_yymm"]
          ? (control.errors["invalid_yymm"] | translate)
          : ""
      }}
      {{
        control.errors["invalid_yyyy"]
          ? (control.errors["invalid_yyyy"] | translate)
          : ""
      }}
      {{
        control.errors["invalid_yyyy_invalid_date"]
          ? (control.errors["invalid_yyyy_invalid_date"] | translate)
          : ""
      }}
      {{
        control.errors["invalid_ratio"]
          ? (control.errors["invalid_ratio"] | translate)
          : ""
      }}
      {{
        control.errors["digits_special_character"]
          ? (control.errors["digits_special_character"] | translate)
          : ""
      }}
      {{
        control.errors["minWithZero"]
          ? (control.errors["minWithZero"] | translate)
          : ""
      }}
      {{
        control.errors["maxWithEqualTo"]
          ? (control.errors["maxWithEqualTo"] | translate) + " " + max
          : ""
      }}
      {{
        control.errors["equalTo"]
          ? (control.errors["equalTo"] | translate) + " " + max
          : ""
      }}
      {{
        control.errors["minWithEqualTo"]
          ? (control.errors["minWithEqualTo"] | translate) + " " + min
          : ""
      }}
      {{
        control.errors["invalid_yyyyYyyy"]
          ? (control.errors["invalid_yyyyYyyy"] | translate)
          : ""
      }}
      {{
        control.errors["invalid_year_range"]
          ? (control.errors["invalid_year_range"] | translate)
          : ""
      }}
    </div>
    <!-- Remark Control -->
    <div class="input-group remark-input-group mt-2" *ngIf="showPreview">
      <textarea
        id="{{ 'remarks_' + id }}"
        class="form-control border d-none"
        placeholder="Add remark here..."
        aria-label="Add Remark here"
      ></textarea>
    </div>
  </div>
</div>
