import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {catchError, EMPTY, exhaustMap, map, of} from 'rxjs';
import {SharedServiceService} from 'src/app/services/shared-service.service';
import {AppState} from '../app-state/app-reducer';
import {SharedActions} from './shared-action-types';

@Injectable({providedIn: 'root'})
export class SharedEffects {
  constructor(
    private actions$: Actions,
    private sharedService: SharedServiceService,
    private store: Store<AppState>
  ) {
  }

  loadAllStates$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SharedActions.loadAllStates),
        exhaustMap(() =>
          this.sharedService.getStates().pipe(
            map((states) =>
              this.store.dispatch(
                SharedActions.loadAllStatesSuccess({states: states.data})
              )
            ),
            catchError(() => {
              this.store.dispatch(
                SharedActions.loadAllStatesSuccess({states: []})
              );
              return of(EMPTY);
            })
          )
        )
      ),
    {dispatch: false}
  );

  loadAllDistrict$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SharedActions.loadAllDistrict),
        exhaustMap(() =>
          this.sharedService.getDistrict().pipe(
            map((district) =>
              this.store.dispatch(
                SharedActions.loadAllDistrictSuccess({
                  district: district.data,
                })
              )
            ),
            catchError(() => {
              this.store.dispatch(
                SharedActions.loadAllDistrictSuccess({district: []})
              );
              return of(EMPTY);
            })
          )
        )
      ),
    {
      dispatch: false,
    }
  );
}
