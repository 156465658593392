<div class="container" style="font-family: 'Lato'">
  <div class="card1">
    <div id="headingTwo"></div>
    <form [formGroup]="registerUserForm" (ngSubmit)="onSubmit()">
      <div class="text-center">
        <h1
          class="heading mt-3 text-capitalize"
          *ngIf="registerUserForm.controls['name_of_Entity'].value != ''"
        >
          Welcome,
          <b class="text-capitalize">{{
            registerUserForm.controls["name_of_Entity"].value | titlecase
          }}</b>
        </h1>
        <h1
          class="heading mt-3 text-capitalize"
          *ngIf="registerUserForm.controls['name_of_Entity'].value == ''"
        >
          Welcome
        </h1>
      </div>
      <div
        class="card-body"
        *ngIf="cEntityType != 'Individual' && cEntityType != 'Others'"
      >
        <div class="row">
          <div class="col-sm-12 heading3">Enter your details</div>
          <div class="col-sm-3">
            <label for="userNAme"
              >Name of Project Proponent Entity<b class="text-red">*</b>
            </label>
          </div>
          <div class="col-sm-5 z-index-up">
            <div class="form-group">
              <input
                type="text"
                id="userNAme"
                class="form-control inputfield"
                id="usr"
                placeholder="Enter name"
                value="{{ registerUserForm.controls['name_of_Entity'].value }}"
                readonly
              />
            </div>
          </div>
          <div class="col-sm-4"></div>
          <ng-container *ngIf="cEntityType == 'Trust/Association/Society'">
            <div class="col-sm-3">
              <label for="is_pan_company_name_same"
                >Whether the name of PAN holder and Company name is same<b
                  class="text-red"
                  >*</b
                >
              </label>
            </div>
            <div class="col-sm-5 z-index-up">
              <div class="form-group">
                <select
                  name="ddl_regstate"
                  id="ddl_regstate"
                  class="form-select form-control"
                  formControlName="is_pan_company_name_same"
                  (change)="onChangePanCompany($event)"
                >
                  <option value="">{{ "generic.select" | translate }}</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
                <span
                  class="errorMsg"
                  *ngIf="
                    registerUserForm.controls['is_pan_company_name_same']
                      .value == '' && validationCheck
                  "
                  >{{ "generic.required" | translate }}
                </span>
              </div>
            </div>

            <div class="col-sm-4"></div>
            <ng-container
              *ngIf="
                registerUserForm.get('is_pan_company_name_same')?.value ==
                'false'
              "
            >
              <div class="col-sm-3">
                <label for="userNAme"
                  >Name of Entity as Registered with ROC<b class="text-red"
                    >*</b
                  >
                </label>
              </div>
              <div class="col-sm-5 z-index-up">
                <div class="form-group">
                  <input
                    type="text"
                    id="userNAme"
                    class="form-control inputfield"
                    id="usr"
                    placeholder="Enter name"
                    formControlName="name_of_Entity_roc"
                  />

                  <span
                    class="errorMsg"
                    *ngIf="
                      registerUserForm.controls['name_of_Entity_roc'].value ==
                        '' && validationCheck
                    "
                    >{{ "generic.required" | translate }}
                  </span>
                </div>
              </div>
              <div class="col-sm-4"></div>
            </ng-container>
          </ng-container>
<!--          <div class="col-sm-4"></div>-->
          <div class="col-sm-3">
            <label for="txt_regaddress ">
              First Line of Address<b class="text-red">*</b>
            </label>
          </div>
          <div class="col-sm-5 z-index-up">
            <div class="form-group">
              <textarea
                name="txt_regaddress"
                id="txt_regaddress"
                class="form-control"
                rows="3"
                style="height: 120px"
                placeholder="Max 250 characters only"
                autocomplete="Off"
                formControlName="address"
                appBlockCopyPaste
                maxlength="250"
              ></textarea>
              <span
                class="errorMsg"
                *ngIf="
                  registerUserForm.controls['address'].errors && validationCheck
                "
              >
                {{ "generic.required" | translate }}
              </span>
            </div>
          </div>
          <div class="col-sm-4"></div>
          <div class="col-sm-3">
            <label for="ddl_regstate">State/UT<b class="text-red">*</b> </label>
          </div>
          <div class="col-sm-4 z-index-up">
            <div class="form-group">
              <select
                name="ddl_regstate"
                id="ddl_regstate"
                class="form-select form-control"
                formControlName="state_ut"
                (change)="getSelectedDist($event)"
              >
                <option value="">{{ "generic.select" | translate }}</option>
                <option value="{{ st.code }}" *ngFor="let st of stateList">
                  {{ st.name.toString() | titlecase }}
                </option>
              </select>
              <span
                class="errorMsg"
                *ngIf="
                  registerUserForm.controls['state_ut'].value == '' &&
                  validationCheck
                "
                >{{ "generic.required" | translate }}
              </span>
            </div>
          </div>
          <div class="col-sm-5"></div>
          <div class="col-sm-3">
            <label for="ddl_regdistrict"
              >District<b class="text-red">*</b>
            </label>
          </div>
          <div class="col-sm-4 z-index-up">
            <div class="form-group">
              <select
                name="ddl_regdistrict"
                id="ddl_regdistrict"
                class="form-select form-control"
                formControlName="district"
              >
                <option value="">{{ "generic.select" | translate }}</option>
                <option
                  value="{{ dist.code }}"
                  *ngFor="let dist of selectedDistrictList"
                >
                  {{ dist.name.toString() | titlecase }}
                </option>
              </select>
              <span
                class="errorMsg"
                *ngIf="
                  registerUserForm.controls['district'].value == '' &&
                  validationCheck
                "
                >{{ "generic.required" | translate }}
              </span>
            </div>
          </div>
          <div class="col-sm-5"></div>
          <div class="col-sm-3">
            <label for="txt_regpincode"
              >Pincode<b class="text-red">*</b>
            </label>
          </div>
          <div class="col-sm-5 z-index-up">
            <div class="form-group">
              <input
                name="txt_regpincode"
                type="text"
                maxlength="6"
                id="txt_regpincode"
                class="form-control inputfield"
                pattern="[0-9]{6}"
                formControlName="pincode"
                [appOnlynumber]="true"
                placeholder="Enter PIN code"
                appBlockCopyPaste
              />
              <div
                class="errorMsg"
                *ngIf="(registerUserForm.controls['pincode'].value=='')&& registerUserForm.controls['pincode'].errors?.['required'] &&validationCheck"
              >
                {{ "generic.required" | translate }}
              </div>
              <div
                class="errorMsg"
                *ngIf="(registerUserForm.controls['pincode'].value!='')&& registerUserForm.controls['pincode'].errors?.['pattern']"
              >
                Please enter valid Pincode
              </div>
            </div>
          </div>
          <div class="col-sm-4"></div>
          <div class="col-sm-3">
            <label for="txt_regwebsite">Website </label>
          </div>
          <div class="col-sm-5 z-index-up">
            <div class="form-group">
              <input
                name="txt_regwebsite"
                appBlockCopyPaste
                type="text"
                id="txt_regwebsite"
                class="form-control inputfield"
                autocomplete="Off"
                formControlName="website"
                placeholder="www.example.com"
              />
            </div>
          </div>
          <div class="col-sm-4"></div>
          <div class="col-sm-3">
            <label for="txtcontactemail">
              Organisation Email Id
              <b class="text-red">*</b>
            </label>
          </div>
          <div class="col-sm-5 z-index-up">
            <div class="form-group">
              <input
                name="txtcontactemail"
                type="email"
                id="txtcontactemail"
                class="form-control inputfield"
                autocomplete="Off"
                appBlockCopyPaste
                formControlName="email"
                (change)="checkEmail($event)"
                placeholder="Enter Organisation Email ID"
                maxlength="319"
              />
              <div
                class="errorMsg"
                *ngIf="registerUserForm.controls['email'].errors?.['required']&&validationCheck"
              >
                {{ "generic.required" | translate }}
              </div>
              <div
                class="errorMsg"
                *ngIf="registerUserForm.controls['email'].errors?.['pattern']"
              >
                Please enter valid Email Id
              </div>
              <div class="errorMsg" *ngIf="duplicateEmail == 'YES'">
                *Mail Address is already registered with Parivesh
              </div>
              <div
                class="text-success"
                *ngIf="
                  duplicateEmail == 'NO' &&
                  registerUserForm.controls['email'].dirty &&
                  !registerUserForm.controls['email'].errors
                "
              >
                <small> *This email is available for registration.</small>
              </div>
              <div class="errorMsg fs-14">
                Use only organization-level email IDs for Parivesh 2.0. The suggested format is "CompanyName_Parivesh@abc.com".
              </div>
            </div>
          </div>
          <div class="col-sm-4"></div>
          <div class="col-sm-3">
            <label for="userNAme"
            >Name of the person registered the entity<b class="text-red">*</b>
            </label>
          </div>
          <div class="col-sm-5 z-index-up">
            <div class="form-group">
              <input
                type="text"
                id="userNAme"
                class="form-control inputfield"
                id="usr"
                placeholder="Enter name"
                formControlName="name_of_Contact_Person"
                [OnlyAlphabetAndSpace]="true"
                maxlength="50"
              />
              <span
                class="errorMsg"
                *ngIf="
                  registerUserForm.controls['name_of_Contact_Person'].errors &&
                  validationCheck
                "
              >
                {{ "generic.required" | translate }}
              </span>
            </div>
          </div>
          <div class="col-sm-4"></div>
          <div class="col-sm-3">
            <label for="userNAme"
            >Designation<b class="text-red">*</b>
            </label>
          </div>
          <div class="col-sm-5 z-index-up">
            <div class="form-group">
              <input
                type="text"
                id="designation"
                class="form-control inputfield"
                placeholder="Enter Designation"
                formControlName="designation"
                [OnlyAlphabetAndSpace]="true"
                maxlength="50"
              />
              <span
                class="errorMsg"
                *ngIf="
                  registerUserForm.controls['designation'].errors &&
                  validationCheck
                "
              >
                {{ "generic.required" | translate }}
              </span>
            </div>
          </div>
          <div class="col-sm-4"></div>

          <div class="col-sm-3">
            <label for="txtcontactmobile"
              >Mobile No.<b class="text-red">*</b>
            </label>
          </div>

          <div class="col-sm-5 z-index-up form-group">
            <div style="margin-bottom: 25px; padding: 0 22px">
              <input
                class="form-check-input"
                (change)="checkMobile($event)"
                value="1"
                type="checkbox"
                id="check"
              />
              <span class="text-danger" style="font-size: 14px">
                Tick to confirm if your mobile no. is linked with Aadhar
              </span>
            </div>
            <div style="margin-bottom: 25px">
              <input
                name="txtcontactmobile"
                type="text"
                maxlength="10"
                id="txtcontactmobile"
                class="form-control inputfield"
                autocomplete="Off"
                placeholder="Mobile No."
                [appOnlynumber]="true"
                formControlName="mobile"
                [readonly]="!check"
                appBlockCopyPaste
              />
              <div
                class="errorMsg"
                *ngIf="registerUserForm.controls['mobile'].errors?.['required']&&validationCheck"
              >
                {{ "generic.required" | translate }}
              </div>
              <div
                *ngIf="registerUserForm.controls['mobile'].errors?.['pattern']"
                class="errorMsg"
              >
                Please enter valid mobile number
              </div>
            </div>
          </div>
          <div class="col-sm-4"></div>

          <div class="col-sm-3">
            <label for="txtstdcountry">Landline Number </label>
          </div>

          <div class="col-sm-2 z-index-up">
            <div>
              <input
                name="txtstdcountry"
                type="text"
                maxlength="5"
                minlength="2"
                id="txtstdcountry"
                class="form-control"
                appBlockCopyPaste
                autocomplete="Off"
                placeholder="STD Code"
                (keyup)="checkStdCode($event)"
                [appOnlynumber]="true"
              />
            </div>
          </div>
          <div class="col-sm-3 z-index-up">
            <div>
              <input
                name="txt_regphoneno"
                type="text"
                maxlength="8"
                id="txt_regphoneno"
                class="form-control"
                appBlockCopyPaste
                autocomplete="Off"
                placeholder="Landline No."
                placeholder="Landline number"
                (keyup)="LandLineValidation()"
                [appOnlynumber]="true"
                formControlName="landline_No"
              />
            </div>
          </div>
          <div class="col-sm-4"></div>
          <div class="offset-sm-3 col-sm-5 form-group">
            <div class="errorMsg">
              Total length of Landline No. must not be greater than 13 digits.
            </div>
            <div class="errorMsg">
              {{ errorMsgStdCode }}
            </div>
            <div class="errorMsg">
              {{ errorMsgLandLineNumber }}
            </div>
          </div>
          <div class="col-sm-4"></div>
          <div class="col-sm-3">
            <label for="txt_regwebsite">Role </label>
          </div>
          <div class="col-sm-5 z-index-up">
            <div class="form-group">
              <input
                name="role"
                type="text"
                id="role"
                class="form-control inputfield"
                formControlName="role"
                value="Super User"
                [readOnly]="true"
              />
            </div>
          </div>
          <div class="col-sm-4"></div>

          <!-- <div class="col-sm-3">
            <label for="fileUpload">Upload Authority Letter<b class="text-red">*</b>
            </label>
          </div>
          <div class="col-sm-5 z-index-up">
            <div class="form-group">
              <input #file name="fileUpload inputfield" appBlockCopyPaste type="file" id="fileUpload"
                class="form-control upload" accept=".pdf" (change)="checkFileupload($event)" />
              <div class="errorMsg" *ngIf="!isFilePresent && validationCheck && FileErrorMsg == ''">
                {{ "generic.required" | translate }}
              </div>
              <div class="errorMsg" *ngIf="!isFilePresent && FileErrorMsg != ''">
                {{ FileErrorMsg }}
              </div>
              <div class="errorMsg">
                (Authority Letter must be Only in pdf format and max. allowed
                size will be upto 2 MB only)
              </div>
            </div>
          </div> -->

          <div class="row mb-3 upload-wrap">
            <label class="col-form-label col-5 field-required"
              >Upload Authority Letter</label
            >
            <div class="col-7">
              <app-upload-document
                [refId]="registerUserForm.controls['email'].value"
                [refType]="DOCUMENT_REF_TYPES.USER_REGISTRATION"
                [documentMapping]="
                  getDocumentTypeMapping(DOCUMENT_TYPE.USER_AUTHORITY_LETTER)
                "
                name="authority_letter"
                [control]="registerUserForm.get('authority_letter')"
                [formGroup]="registerUserForm"
                (shareData)="receivedMessageHandler($event)"
                [isReadOnly]="isReadonly"
              ></app-upload-document>
              <div class="errorMsg mt-3">
                Authorisation Letter from the Departmental head of the company authorising the person to do the registration on company's behalf
              </div>
            </div>
          </div>


          <!-- <div class="col-sm-4"></div> -->

          <div class="col-sm-3">
            <label for="email">Captcha Code<b class="text-red">*</b> </label>
          </div>
          <div class="col-sm-2 z-index-up">
            <canvas
              style="border-radius: 4px"
              width="126px"
              height="52px"
              #myCanvas
            ></canvas>
          </div>
          <div class="col-sm-2 z-index-up">
            <button type="button" class="btnCaptcha" (click)="getCaptcha(6)">
              <span class="btnCaptchatext" (click)="getCaptcha(6)"
                >&#10226; Refresh</span
              >
            </button>
          </div>
          <div class="col-sm-3 z-index-up">
            <input
              class="captcha-input form-control inputfield"
              appBlockCopyPaste
              type="text"
              aria-describedby="error-captcha"
              placeholder="Enter Captcha"
              (blur)="captchaCaptured($event)"
              maxlength="6"
              #captcha
            />
          </div>
          <div class="col-sm-2"></div>
          <div class="col-sm-3 offset-sm-3">
            <p *ngIf="isCaptcha" class="text-success">&#10004;</p>
            <span
              *ngIf="captchaErrorMsg"
              id="error-captcha"
              class="p-error text-danger"
              >Captcha mismatch .</span
            >
          </div>
        </div>
        <div class="text-center offset-sm-1 row">
          <span class="pt-4" style="font-size: 14px">
            <input
              class="form-check-input"
              (change)="checkDeclare($event)"
              value="1"
              type="checkbox"
              id="check"
            />&nbsp; <b>Declaration:</b> I agree to the correctness of
            information provided by me for Registration purpose
          </span>
        </div>
      </div>
      <div class="card-body" *ngIf="cEntityType == 'Others'">
        <div class="row">
          <div class="col-sm-12 heading3">Enter your details</div>
          <div class="col-sm-3">
            <label for="userNAme"
              >Name of Project Proponent Entity<b class="text-red">*</b>
            </label>
          </div>
          <div class="col-sm-5 z-index-up">
            <div class="form-group">
              <input
                type="text"
                id="userNAme"
                class="form-control inputfield"
                id="usr"
                placeholder="Enter name"
                appBlockCopyPaste
                formControlName="name_of_Entity"
                maxlength="250"
                [readonly]="registerUserForm.controls['pan_no'].value != null"
                [OnlyAlphabetAndSpace]="true"
              />
            </div>
          </div>
          <div class="col-sm-4"></div>

          <div class="col-sm-3">
            <label for="txt_regaddress">
              Correspondence Address<b class="text-red">*</b>
            </label>
          </div>
          <div class="col-sm-5 z-index-up">
            <div class="form-group">
              <textarea
                name="txt_regaddress"
                id="txt_regaddress"
                class="form-control"
                rows="3"
                style="height: 120px"
                placeholder="Max 250 characters only"
                autocomplete="Off"
                maxlength="250"
                formControlName="address"
                appBlockCopyPaste
              ></textarea>
              <span
                class="errorMsg"
                *ngIf="
                  registerUserForm.controls['address'].errors && validationCheck
                "
              >
                {{ "generic.required" | translate }}
              </span>
            </div>
          </div>
          <div class="col-sm-4"></div>
          <div class="col-sm-3">
            <label for="ddl_regstate">State/UT<b class="text-red">*</b> </label>
          </div>
          <div class="col-sm-4 z-index-up">
            <div class="form-group">
              <select
                name="ddl_regstate"
                id="ddl_regstate"
                class="form-select form-control"
                formControlName="state_ut"
                (change)="getSelectedDist($event)"
              >
                <option value="">{{ "generic.select" | translate }}</option>
                <option value="{{ st.code }}" *ngFor="let st of stateList">
                  {{ st.name.toString() | titlecase }}
                </option>
              </select>
              <span
                class="errorMsg"
                *ngIf="
                  registerUserForm.controls['state_ut'].value == '' &&
                  validationCheck
                "
                >{{ "generic.required" | translate }}
              </span>
            </div>
          </div>
          <div class="col-sm-5"></div>
          <div class="col-sm-3">
            <label for="ddl_regdistrict"
              >District<b class="text-red">*</b>
            </label>
          </div>
          <div class="col-sm-4 z-index-up">
            <div class="form-group">
              <select
                name="ddl_regdistrict"
                id="ddl_regdistrict"
                class="form-select form-control"
                formControlName="district"
              >
                <option value="">{{ "generic.select" | translate }}</option>
                <option
                  value="{{ dist.code }}"
                  *ngFor="let dist of selectedDistrictList"
                >
                  {{ dist.name.toString() | titlecase }}
                </option>
              </select>
              <span
                class="errorMsg"
                *ngIf="
                  registerUserForm.controls['district'].value == '' &&
                  validationCheck
                "
                >{{ "generic.required" | translate }}
              </span>
            </div>
          </div>
          <div class="col-sm-5"></div>
          <div class="col-sm-3">
            <label for="txt_regpincode"
              >Pincode<b class="text-red">*</b>
            </label>
          </div>
          <div class="col-sm-5 z-index-up">
            <div class="form-group">
              <input
                name="txt_regpincode"
                type="text"
                maxlength="6"
                id="txt_regpincode"
                class="form-control inputfield"
                pattern="[0-9]{6}"
                formControlName="pincode"
                [appOnlynumber]="true"
                placeholder="Enter PIN code"
                appBlockCopyPaste
              />
              <div
                class="errorMsg"
                *ngIf="(registerUserForm.controls['pincode'].value=='')&& registerUserForm.controls['pincode'].errors?.['required'] &&validationCheck"
              >
                {{ "generic.required" | translate }}
              </div>
              <div
                class="errorMsg"
                *ngIf="(registerUserForm.controls['pincode'].value!='')&& registerUserForm.controls['pincode'].errors?.['pattern']"
              >
                Please enter valid Pincode
              </div>
            </div>
          </div>
          <div class="col-sm-4"></div>
          <div class="col-sm-3">
            <label for="txtcontactemail">
              Email Id
              <b class="text-red">*</b>
            </label>
          </div>
          <div class="col-sm-5 z-index-up">
            <div class="form-group">
              <input
                name="txtcontactemail"
                type="email"
                id="txtcontactemail"
                class="form-control inputfield"
                autocomplete="Off"
                appBlockCopyPaste
                formControlName="email"
                (change)="checkEmail($event)"
                placeholder="Enter Email ID"
                maxlength="319"
              />
              <div
                class="errorMsg"
                *ngIf="registerUserForm.controls['email'].errors?.['required']&&validationCheck"
              >
                {{ "generic.required" | translate }}
              </div>
              <div
                class="errorMsg"
                *ngIf="registerUserForm.controls['email'].errors?.['pattern']"
              >
                Please enter valid Email Id
              </div>
              <div class="errorMsg" *ngIf="duplicateEmail == 'YES'">
                *Mail Address is already registered with Parivesh
              </div>
              <div
                class="text-success"
                *ngIf="
                  duplicateEmail == 'NO' &&
                  registerUserForm.controls['email'].dirty &&
                  !registerUserForm.controls['email'].errors
                "
              >
                <small> *This email is available for registration.</small>
              </div>
            </div>
          </div>
          <div class="col-sm-4"></div>

          <div class="col-sm-3">
            <label for="txtcontactmobile"
              >Mobile No.<b class="text-red">*</b>
            </label>
          </div>

          <div class="col-sm-5 z-index-up form-group">
            <div style="margin-bottom: 25px; padding: 0 22px">
              <input
                class="form-check-input"
                (change)="checkMobile($event)"
                value="1"
                type="checkbox"
                id="check"
              />
              <span class="text-danger" style="font-size: 14px">
                Tick to confirm if your mobile no. is linked with Aadhar</span
              >
            </div>
            <div style="margin-bottom: 25px">
              <input
                name="txtcontactmobile"
                type="text"
                maxlength="10"
                id="txtcontactmobile"
                class="form-control inputfield"
                autocomplete="Off"
                placeholder="Mobile No."
                [appOnlynumber]="true"
                formControlName="mobile"
                [readonly]="!check"
                appBlockCopyPaste
              />
              <div
                class="errorMsg"
                *ngIf="registerUserForm.controls['mobile'].errors?.['required']&&validationCheck"
              >
                {{ "generic.required" | translate }}
              </div>
              <div
                *ngIf="registerUserForm.controls['mobile'].errors?.['pattern']"
                class="errorMsg"
              >
                Please enter valid mobile number
              </div>
            </div>
          </div>
          <div class="col-sm-4"></div>

          <div class="col-sm-3">
            <label for="txtstdcountry">Landline Number </label>
          </div>

          <div class="col-sm-2 z-index-up">
            <div>
              <input
                name="txtstdcountry"
                type="text"
                maxlength="5"
                id="txtstdcountry"
                class="form-control inputfield"
                (keyup)="checkStdCode($event)"
                appBlockCopyPaste
                autocomplete="Off"
                placeholder="STD Code"
                [appOnlynumber]="true"
              />
            </div>
          </div>
          <div class="col-sm-3 z-index-up">
            <div>
              <input
                name="txt_regphoneno"
                type="text"
                maxlength="8"
                id="txt_regphoneno"
                formControlName="landline_No"
                class="form-control inputfield"
                appBlockCopyPaste
                autocomplete="Off"
                (keyup)="LandLineValidation()"
                placeholder="Landline Number"
                [appOnlynumber]="true"
              />
            </div>
          </div>
          <div class="col-sm-4"></div>
          <div class="offset-sm-3 col-sm-5 form-group">
            <div class="errorMsg">
              Total length of Landline No. must not be greater than 13 digits.
            </div>
            <div class="errorMsg">
              {{ errorMsgStdCode }}
            </div>
            <div class="errorMsg">
              {{ errorMsgLandLineNumber }}
            </div>
          </div>
          <div class="col-sm-4"></div>
          <div class="col-sm-3">
            <label for="txt_regwebsite">Website </label>
          </div>
          <div class="col-sm-5 z-index-up">
            <div class="form-group">
              <input
                name="txt_regwebsite"
                appBlockCopyPaste
                type="text"
                id="txt_regwebsite"
                class="form-control inputfield"
                autocomplete="Off"
                formControlName="website"
                placeholder="www.example.com"
              />
            </div>
          </div>
          <div class="col-sm-4"></div>

          <!-- <div class="col-sm-3">
            <label for="fileUpload" *ngIf="registerUserForm.controls['pan_no'].value == null">Upload Voter Id Card<b
                class="text-red">*</b>
            </label>
          </div> -->

          <!-- <div class="col-sm-5 z-index-up">
            <div class="form-group" *ngIf="registerUserForm.controls['pan_no'].value == null">
              <input #file name="fileUpload" appBlockCopyPaste type="file" id="fileUpload"
                class="form-control upload inputfield" accept=".pdf" (change)="checkFileupload($event)" />
              <div class="errorMsg" *ngIf="!isFilePresent && validationCheck && FileErrorMsg == ''">
                {{ "generic.required" | translate }}
              </div>
              <div class="errorMsg" *ngIf="!isFilePresent && FileErrorMsg != ''">

                {{ FileErrorMsg }}
              </div>
              <div class="errorMsg">
                (Voter Id Card must be Only in pdf/JPEG format and max. allowed
                size will be upto 1 MB only)
              </div>
            </div>
          </div> -->

          <div class="row mb-3 upload-wrap">
            <label class="col-form-label col-5 field-required"
              >Upload Voter ID or Authority Letter or PAN</label
            >
            <div class="col-7">
              <app-upload-document
                [refId]="registerUserForm.controls['email'].value"
                [refType]="DOCUMENT_REF_TYPES.USER_REGISTRATION"
                [documentMapping]="
                  getDocumentTypeMapping(
                    DOCUMENT_TYPE.USER_REGISTRATION_VOTER_CARD
                  )
                "
                name="voter_id"
                [control]="registerUserForm.get('voter_id')"
                [formGroup]="registerUserForm"
                (shareData)="receivedMessageHandler($event)"
                [isReadOnly]="isReadonly"
              ></app-upload-document>
            </div>
            <p class="text-danger text-end mt-3">NOTE : Aadhaar is NOT accepted as a verification document.</p>
          </div>

          <!-- <div class="col-sm-4"></div> -->

          <div class="col-sm-3">
            <label for="email">Captcha Code<b class="text-red">*</b> </label>
          </div>
          <div class="col-sm-2 z-index-up">
            <canvas
              style="border-radius: 4px"
              width="126px"
              height="52px"
              #myCanvas
            ></canvas>
          </div>
          <div class="col-sm-2 z-index-up">
            <button (click)="getCaptcha(6)" type="button" class="btnCaptcha">
              <span class="btnCaptchatext">&#10226; Refresh</span>
            </button>
          </div>
          <div class="col-sm-3 z-index-up">
            <input
              class="captcha-input form-control inputfield"
              appBlockCopyPaste
              type="text"
              #captcha
              aria-describedby="error-captcha"
              placeholder="Enter Captcha"
              (blur)="captchaCaptured($event)"
              maxlength="6"
            />
          </div>
          <div class="col-sm-2"></div>
          <div class="col-sm-3 offset-sm-3">
            <p *ngIf="isCaptcha" class="text-success">&#10004;</p>
            <span
              *ngIf="captchaErrorMsg"
              id="error-captcha"
              class="p-error text-danger"
              >Captcha mismatch .</span
            >
          </div>
        </div>
        <div class="text-center offset-sm-1 row">
          <span class="pt-4" style="font-size: 14px">
            <input
              class="form-check-input"
              (change)="checkDeclare($event)"
              value="1"
              type="checkbox"
              id="check"
            />&nbsp; <b>Declaration:</b> I agree to the correctness of
            information provided by me for Registration purpose
          </span>
        </div>
      </div>
      <div *ngIf="cEntityType == 'Individual'">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 heading3">Enter your details</div>
            <div class="col-sm-3">
              <label for="userNAme"
                >Name of Project Proponent Entity<b class="text-red">*</b>
              </label>
            </div>
            <div class="col-sm-5 z-index-up">
              <div class="form-group">
                <input
                  type="text"
                  id="userNAme"
                  class="form-control inputfield"
                  id="usr"
                  placeholder="Enter name"
                  appBlockCopyPaste
                  formControlName="name_of_Entity"
                  maxlength="250"
                  [readonly]="
                    registerUserForm.controls['name_of_Entity'].value != null
                  "
                  [OnlyAlphabetAndSpace]="true"
                />
              </div>
            </div>
            <div class="col-sm-4"></div>
            <div class="col-sm-12 heading3">Enter your address details</div>
            <div class="col-sm-3">
              <label for="txt_regaddress">
                Correspondence Address
                <b class="text-red">*</b>
              </label>
            </div>
            <div class="col-sm-5 z-index-up">
              <div class="form-group">
                <textarea
                  name="txt_regaddress"
                  id="txt_regaddress"
                  class="form-control"
                  rows="3"
                  style="height: 120px"
                  placeholder="Max 250 characters only"
                  autocomplete="Off"
                  maxlength="250"
                  formControlName="address"
                  appBlockCopyPaste
                ></textarea>
                <span
                  class="errorMsg"
                  *ngIf="
                    registerUserForm.controls['address'].errors &&
                    validationCheck
                  "
                >
                  {{ "generic.required" | translate }}
                </span>
              </div>
            </div>
            <div class="col-sm-4"></div>
            <div class="col-sm-3">
              <label for="ddl_regstate"
                >State/UT<b class="text-red">*</b>
              </label>
            </div>
            <div class="col-sm-4 z-index-up">
              <div class="form-group">
                <select
                  name="ddl_regstate"
                  id="ddl_regstate"
                  class="form-select form-control"
                  formControlName="state_ut"
                  (change)="getSelectedDist($event)"
                >
                  <option value="">{{ "generic.select" | translate }}</option>
                  <option value="{{ st.code }}" *ngFor="let st of stateList">
                    {{ st.name.toString() | titlecase }}
                  </option>
                </select>
                <span
                  class="errorMsg"
                  *ngIf="
                    registerUserForm.controls['state_ut'].value == '' &&
                    validationCheck
                  "
                  >{{ "generic.required" | translate }}
                </span>
              </div>
            </div>
            <div class="col-sm-5"></div>

            <div class="col-sm-3">
              <label for="ddl_regdistrict"
                >District<b class="text-red">*</b>
              </label>
            </div>
            <div class="col-sm-4 z-index-up">
              <div class="form-group">
                <select
                  name="ddl_regdistrict"
                  id="ddl_regdistrict"
                  class="form-select form-control"
                  formControlName="district"
                >
                  <option value="">{{ "generic.select" | translate }}</option>
                  <option
                    value="{{ dist.code }}"
                    *ngFor="let dist of selectedDistrictList"
                  >
                    {{ dist.name.toString() | titlecase }}
                  </option>
                </select>
                <span
                  class="errorMsg"
                  *ngIf="
                    registerUserForm.controls['district'].value == '' &&
                    validationCheck
                  "
                  >{{ "generic.required" | translate }}
                </span>
              </div>
            </div>
            <div class="col-sm-5"></div>

            <div class="col-sm-3">
              <label for="txt_regpincode"
                >Pincode<b class="text-red">*</b>
              </label>
            </div>
            <div class="col-sm-5 z-index-up">
              <div class="form-group">
                <input
                  name="txt_regpincode"
                  type="text"
                  maxlength="6"
                  id="txt_regpincode"
                  class="form-control inputfield"
                  pattern="[0-9]{6}"
                  formControlName="pincode"
                  [appOnlynumber]="true"
                  placeholder="Enter PIN code"
                  appBlockCopyPaste
                  [appOnlynumber]="true"
                />
                <div
                  class="errorMsg"
                  *ngIf="(registerUserForm.controls['pincode'].value=='')&& registerUserForm.controls['pincode'].errors?.['required'] &&validationCheck"
                >
                  {{ "generic.required" | translate }}
                </div>
                <div
                  class="errorMsg"
                  *ngIf="(registerUserForm.controls['pincode'].value!='')&& registerUserForm.controls['pincode'].errors?.['pattern']"
                >
                  Please enter valid Pincode
                </div>
              </div>
            </div>
            <div class="col-sm-4"></div>
            <div class="col-sm-3">
              <label for="txt_regpincode"
                >Do you have Registered Office Address?<b class="text-red">*</b>
              </label>
            </div>
            <div class="col-sm-5 z-index-up">
              <div class="form-group">
                <select
                  name="is_organisation"
                  id="is_organisation"
                  class="form-select form-control"
                  formControlName="is_organisation"
                  (change)="onIsOrganisationChange($event)"
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
                <span
                  class="errorMsg"
                  *ngIf="
                    registerUserForm.controls['is_organisation'].value == '' &&
                    validationCheck
                  "
                  >{{ "generic.required" | translate }}
                </span>
              </div>
            </div>
            <ng-container
              *ngIf="
                registerUserForm.controls['is_organisation'].value == 'true'
              "
            >
              <div class="col-sm-12 mb-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="is_reg_address_same"
                    (change)="addressSame($event)"
                  />
                  <label
                    class="form-check-label text-danger"
                    for="is_reg_address_same"
                  >
                    Check here if Registered Office address is same as
                    Correspondence Address
                  </label>
                </div>
              </div>
              <div class="col-sm-3">
                <label for="org_address">
                  Registered Office Address
                  <b class="text-red">*</b>
                </label>
              </div>
              <div class="col-sm-5 z-index-up">
                <div class="form-group">
                  <textarea
                    name="org_address"
                    id="org_address"
                    class="form-control"
                    rows="3"
                    style="height: 120px"
                    placeholder="Max 250 characters only"
                    autocomplete="Off"
                    maxlength="250"
                    formControlName="org_address"
                    appBlockCopyPaste
                  ></textarea>
                  <span
                    class="errorMsg"
                    *ngIf="
                      registerUserForm.controls['org_address'].value == '' &&
                      validationCheck
                    "
                  >
                    {{ "generic.required" | translate }}
                  </span>
                </div>
              </div>
              <div class="col-sm-4"></div>
              <div class="col-sm-3">
                <label for="org_state_ut"
                  >State/UT<b class="text-red">*</b>
                </label>
              </div>
              <div class="col-sm-4 z-index-up">
                <div class="form-group">
                  <select
                    name="org_state_ut"
                    id="org_state_ut"
                    class="form-select form-control"
                    formControlName="org_state_ut"
                    (change)="getSelectedDist($event)"
                  >
                    <option value="">{{ "generic.select" | translate }}</option>
                    <option value="{{ st.code }}" *ngFor="let st of stateList">
                      {{ st.name.toString() | titlecase }}
                    </option>
                  </select>
                  <span
                    class="errorMsg"
                    *ngIf="
                      registerUserForm.controls['org_state_ut'].value == '' &&
                      validationCheck
                    "
                    >{{ "generic.required" | translate }}
                  </span>
                </div>
              </div>
              <div class="col-sm-5"></div>

              <div class="col-sm-3">
                <label for="org_district"
                  >District<b class="text-red">*</b>
                </label>
              </div>
              <div class="col-sm-4 z-index-up">
                <div class="form-group">
                  <select
                    name="org_district"
                    id="org_district"
                    class="form-select form-control"
                    formControlName="org_district"
                  >
                    <option value="">{{ "generic.select" | translate }}</option>
                    <option
                      value="{{ dist.code }}"
                      *ngFor="let dist of selectedDistrictList"
                    >
                      {{ dist.name.toString() | titlecase }}
                    </option>
                  </select>
                  <span
                    class="errorMsg"
                    *ngIf="
                      registerUserForm.controls['org_district'].value == '' &&
                      validationCheck
                    "
                    >{{ "generic.required" | translate }}
                  </span>
                </div>
              </div>
              <div class="col-sm-5"></div>

              <div class="col-sm-3">
                <label for="org_pincode"
                  >Pincode<b class="text-red">*</b>
                </label>
              </div>
              <div class="col-sm-5 z-index-up">
                <div class="form-group">
                  <input
                    name="org_pincode"
                    type="text"
                    maxlength="6"
                    id="org_pincode"
                    class="form-control inputfield"
                    pattern="[0-9]{6}"
                    formControlName="org_pincode"
                    [appOnlynumber]="true"
                    placeholder="Enter PIN code"
                    appBlockCopyPaste
                  />
                  <div
                    class="errorMsg"
                    *ngIf="
                      registerUserForm.controls['org_pincode'].value == '' &&
                      validationCheck
                    "
                  >
                    {{ "generic.required" | translate }}
                  </div>
                  <div
                    class="errorMsg"
                    *ngIf="(registerUserForm.controls['org_pincode'].value!='') && registerUserForm.controls['org_pincode'].errors?.['pattern']"
                  >
                    Please enter valid Pincode
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 heading3">Enter your personal details</div>
            <div class="col-sm-3">
              <label for="txtcontactemail">
                Email Id
                <b class="text-red">*</b>
              </label>
            </div>
            <div class="col-sm-5 z-index-up">
              <div class="form-group">
                <input
                  name="txtcontactemail"
                  type="email"
                  id="txtcontactemail"
                  class="form-control inputfield"
                  autocomplete="Off"
                  appBlockCopyPaste
                  formControlName="email"
                  (change)="checkEmail($event)"
                  placeholder="Enter Email ID"
                  maxlength="319"
                />
                <div
                  class="errorMsg"
                  *ngIf="registerUserForm.controls['email'].errors?.['required']&&validationCheck"
                >
                  {{ "generic.required" | translate }}
                </div>
                <div
                  class="errorMsg"
                  *ngIf="registerUserForm.controls['email'].errors?.['pattern']"
                >
                  Please enter valid Email Id
                </div>
                <div class="errorMsg" *ngIf="duplicateEmail == 'YES'">
                  *Mail Address is already registered with Parivesh
                </div>
                <div
                  class="text-success"
                  *ngIf="
                    duplicateEmail == 'NO' &&
                    registerUserForm.controls['email'].dirty &&
                    !registerUserForm.controls['email'].errors
                  "
                >
                  <small> *This email is available for registration.</small>
                </div>
              </div>
            </div>
            <div class="col-sm-4"></div>
            <div class="col-sm-3">
              <label for="txtcontactmobile "
                >Mobile No.<b class="text-red">*</b>
              </label>
            </div>

            <div class="col-sm-5 z-index-up">
              <div style="margin-bottom: 25px; padding: 0 22px">
                <input
                  class="form-check-input"
                  (change)="checkMobile($event)"
                  value="1"
                  type="checkbox"
                  id="check"
                />
                <span class="text-danger" style="font-size: 14px">
                  Tick to confirm if your mobile no. is linked with Aadhar</span
                >
              </div>
              <div style="margin-bottom: 25px">
                <input
                  name="txtcontactmobile"
                  type="text"
                  maxlength="10"
                  id="txtcontactmobile"
                  class="form-control inputfield"
                  autocomplete="Off"
                  placeholder="Mobile No."
                  [appOnlynumber]="true"
                  formControlName="mobile"
                  [readonly]="!check"
                  appBlockCopyPaste
                />
                <div
                  class="errorMsg"
                  *ngIf="registerUserForm.controls['mobile'].errors?.['required']&&validationCheck"
                >
                  {{ "generic.required" | translate }}
                </div>
                <div
                  *ngIf="registerUserForm.controls['mobile'].errors?.['pattern']"
                  class="errorMsg"
                >
                  Please enter valid mobile number
                </div>
              </div>
            </div>
            <div class="col-sm-4"></div>

            <div class="col-sm-3">
              <label for="txtstdcountry">Landline Number </label>
            </div>

            <div class="col-sm-2 z-index-up">
              <div class="">
                <input
                  name="txtstdcountry"
                  type="text"
                  maxlength="5"
                  minlength="2"
                  id="txtstdcountry"
                  class="form-control inputfield"
                  appBlockCopyPaste
                  autocomplete="Off"
                  placeholder="STD Code"
                  (keyup)="checkStdCode($event)"
                  [appOnlynumber]="true"
                />
              </div>
            </div>
            <div class="col-sm-3 z-index-up">
              <div>
                <input
                  name="txt_regphoneno"
                  type="tel"
                  maxlength="8"
                  id="txt_regphoneno"
                  formControlName="landline_No"
                  class="form-control inputfield"
                  appBlockCopyPaste
                  autocomplete="Off"
                  (keyup)="LandLineValidation()"
                  placeholder="Landline number"
                  [appOnlynumber]="true"
                />
              </div>
            </div>
            <div class="col-sm-4"></div>
            <div class="offset-sm-3 col-sm-5 form-group">
              <div class="errorMsg">
                Total length of Landline No. must not be greater than 13 digits.
              </div>
              <div class="errorMsg">
                {{ errorMsgStdCode }}
              </div>
              <div class="errorMsg">
                {{ errorMsgLandLineNumber }}
              </div>
            </div>
            <div class="col-sm-4"></div>
            <div class="col-sm-3">
              <label for="txt_regwebsite">Website</label>
            </div>
            <div class="col-sm-5 z-index-up">
              <div class="form-group">
                <input
                  name="txt_regwebsite"
                  appBlockCopyPaste
                  type="text"
                  id="txt_regwebsite"
                  class="form-control inputfield"
                  autocomplete="Off"
                  formControlName="website"
                  placeholder="Enter your website link here"
                />
              </div>
            </div>
            <div class="col-sm-4"></div>
            <div class="col-sm-3">
              <label for="email">Captcha Code<b class="text-red">*</b> </label>
            </div>
            <div class="col-sm-2 z-index-up">
              <canvas
                style="border-radius: 4px"
                width="126px"
                height="52px"
                #myCanvas
              ></canvas>
            </div>
            <div class="col-sm-2 z-index-up">
              <button (click)="getCaptcha(6)" type="button" class="btnCaptcha">
                <span class="btnCaptchatext">&#10226; Refresh</span>
              </button>
            </div>
            <div class="col-sm-3 z-index-up">
              <input
                class="captcha-input form-control"
                appBlockCopyPaste
                type="text"
                aria-describedby="error-captcha"
                #captcha
                placeholder="Enter Captcha"
                (blur)="captchaCaptured($event)"
                maxlength="6"
              />
            </div>
            <div class="col-sm-2"></div>
            <div class="col-sm-3 offset-sm-3">
              <p *ngIf="isCaptcha" class="text-success">&#10004;</p>
              <span
                *ngIf="captchaErrorMsg"
                id="error-captcha"
                class="p-error text-danger"
                >Captcha mismatch .</span
              >
            </div>
          </div>
          <div class="text-center offset-sm-1 row">
            <span class="pt-4" style="font-size: 14px">
              <input
                class="form-check-input"
                (change)="checkDeclare($event)"
                value="1"
                type="checkbox"
                id="check"
              />&nbsp; <b>Declaration:</b> I agree to the correctness of
              information provided and accept the Terms of Use and Privacy
              Policy applicable to avail the services of this platform.
            </span>
          </div>
          <div class="col-sm-5"></div>
        </div>
      </div>

      <div class="text-center offset-sm-6 row">
        <button
          matStepperPrevious
          class="text-success btncustom z-index-up"
          style="
            position: relative;
            background-color: #f8f9fa;
            border-color: green;
          "
        >
          Back
        </button>
        <button
          type="submit"
          [disabled]="duplicateEmail == 'YES' || checkDeclaration == false"
          class="btncustom z-index-up text-white"
          style="position: relative"
        >
          <span class="text-white">Save & Submit</span>
        </button>
      </div>
      <br />
      <div class="alert alert-warning form-note" role="alert">
        <i class="bi bi-exclamation-triangle" style="font-size: 18px"></i>
        <b>&nbsp;&nbsp;&nbsp;Note:</b>
        Once Registration application is submitted the activation mail will be
        sent to the registered Email Id, Click on the activation link within 24*
        hours to activate your login.
      </div>
    </form>
  </div>
</div>
