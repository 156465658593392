
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs'
import { API_DSE } from 'src/app/shared/app-constant';

@Injectable({
  providedIn: 'root'
})

export class DigitalSignatureService {

  baseUrl: string = environment.baseURLDigitalSignature;
  digitalSignatureXML: any = '';
  signatureResponse: any;

  constructor(private http: HttpClient) {

  }

  checkDigitalSignatureIsLive(): Observable<string> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    let URL = this.baseUrl + API_DSE.CHECK_LIVE;
    return this.http.get<string>(URL, { headers, responseType: 'text' as 'json' })
  }

  checkCertificateList(): Observable<string> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8').set('version', '7.0');
    let URL = this.baseUrl + API_DSE.DSC_GET_CERTIFICATE_LIST;
    return this.http.get<string>(URL, { headers, responseType: 'text' as 'json' })
  }

  getDigitalSignerCertificateList(data: any): Observable<any> {
    let URL = environment.baseURLDigitalSigner + API_DSE.DSC_GET_CERTIFICATE_LIST_V2;
    return this.http.post<any>(URL, data)
  }

  registerDigitalSignature(data: any): Observable<any> {
    let URL = environment.baseURLDigitalSigner + API_DSE.DSC_REGISTRATION;
    return this.http.post<any>(URL, data)
  }

  signerService(data: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8').set('version', '7.0');
    let URL = this.baseUrl + API_DSE.DSC_SIGNER_SERVICE;
    return this.http.post<any>(URL, data, { headers, responseType: 'text' as 'json' })
  }


  verifyRegister(data: any): Observable<any> {
    let URL = environment.baseURLDigitalSigner + API_DSE.DSC_VERIFIY_REGISTRATION;
    return this.http.post<any>(URL, data)
  }

  signPdf(data: any): Observable<any> {
    let URL = environment.baseURLDigitalSigner + API_DSE.DSC_SIGNED_PDF;
    return this.http.post<any>(URL, data)
  }

  putSignature(data: any): Observable<any> {
    let URL = environment.baseURLDigitalSigner + API_DSE.DSC_PUT_SIGNATURE;
    return this.http.post<any>(URL, data)
  }

  getUserCertificateList(): Observable<any> {
    // const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8').set('version', '7.0');
    let URL = environment.baseURLDigitalSigner + API_DSE.DSC_GET_USER_CERTIFICATE;
    return this.http.post<any>(URL, {})
  }


  methodForDSCESign(filePath: string) {
    return new Promise<any>((resolve, reject) => {
      this.checkCertificateList().subscribe({
        next: (res) => {
          let data: any = res;
          const BODY = {
            transactionId: null,
            serialNumber: null,
            filePath: filePath,
            xmlResponse: data
          }
          this.signPdf(BODY).subscribe({
            next: (res) => {
              let obj: any = res;
              if (obj?.status == '1') {
                this.signerService(obj?.xmlResponse).subscribe((response) => {
                  let value = response;
                  this.putDSCSignature(obj, value, resolve, reject);
                })
              } else {
                const message = obj?.msg
                reject({ status: false, msg: message })
              }
            }, error: (err) => {
              reject(false);
            }
          })
        }, error: (err) => {
          reject(false);
        }
      })
    })

  }

  putDSCSignature(data: any, value: any, cb1: any, cb2: any) {
    const BODY = {
      transactionId: data?.transactionId,
      serialNumber: null,
      filePath: null,
      xmlResponse: value
    }
    this.putSignature(BODY).subscribe({
      next: (res) => {
        this.signatureResponse = res;
        if (this.signatureResponse?.status == '1') {
          cb1(this.signatureResponse);
        } else {
          const message = this.signatureResponse?.msg;
          cb2({ status: false, msg: message })
        }
      }, error: (err) => {
        cb2(false);
      }
    })
  }

}
